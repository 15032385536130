import { Box, Container, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import React, { useState, useEffect } from 'react';

const UnauthorizedPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    const goBack = () => navigate(-1);

    const linkStyle = {
        color: colors.blueAccent[700],
        transition: 'color 0.3s ease, textDecoration 0.3s ease',
        textDecoration: 'none',
        fontWeight: 'bold',
    };

    const handleHover = (event) => {
        event.target.style.color = colors.blueAccent[400];
        event.target.style.textDecoration = 'underline';
    };

    const handleLeave = (event) => {
        event.target.style.color = colors.blueAccent[700];
        event.target.style.textDecoration = 'none';
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Box
                sx={{
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    padding: theme.spacing(4),
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    textAlign: 'center',
                    width: isMobile ? '100%' : '80%', // Adjust width for mobile responsiveness
                }}
            >
                <Typography
                    component="h1"
                    variant={isMobile ? 'h4' : 'h3'}
                    color={colors.grey[100]}
                    fontWeight="bold"
                >
                    Unauthorized Access
                </Typography>
                <Typography
                    variant={isMobile ? 'body1' : 'h6'}
                    color={colors.grey[100]}
                    sx={{ mt: 2 }}
                >
                    You are not authorized to view this page.
                </Typography>
                <Typography
                    variant={isMobile ? 'body1' : 'h6'}
                    color={colors.grey[100]}
                    sx={{ mt: 2 }}
                >
                    Please contact the administrator or go back to{' '}
                    <Link
                        to="#"
                        style={linkStyle}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleLeave}
                        onClick={goBack}
                    >
                        previous page
                    </Link>
                    .
                </Typography>
            </Box>
        </Container>
    );
};

export default UnauthorizedPage;
