// useWarrantyData.js
import { useEffect, useState, useCallback, useRef } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const useUserProfile = () => {
    const axiosPrivate = useAxiosPrivate();
    const [profileData, setProfileData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const controllerRef = useRef(new AbortController());
    const [loading, setLoading] = useState(true);

    const fetchProfile = useCallback(async () => {
        try {
            const controller = new AbortController();
            controllerRef.current = controller;
            const response = await axiosPrivate.get('/person/person/profile', {
                signal: controller.signal,
            });
            setProfileData(response.data);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setLoading(false);
            }
        }
    }, [axiosPrivate, location, navigate]);

    useEffect(() => {
        fetchProfile();
    }, [fetchProfile]);

    return { loading, profileData, fetchProfile };
};

export default useUserProfile;
