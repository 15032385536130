import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    Checkbox,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import IconButton from '@mui/material/IconButton';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const DeleteInvoiceModal = ({
    isDeleteInvoiceModalOpen,
    setDeleteInvoiceModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
    selectedRowsInvoice,
    setSelectedRowsInvoice,
    selectedWarranty,
    setSelectedWarranty,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isDocDelChecked, setIsDocDelChecked] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDeleteInvoices = async () => {
        const controller = new AbortController();
        try {
            const payload = {
                delete_invoice: isChecked,
                delete_documents: isDocDelChecked,
            };
            for (const invoiceIndex of selectedRowsInvoice[selectedWarranty.id] || []) {
                await axiosPrivate.delete(
                    `/warranty/warranty/${selectedWarranty.id}/invoice/${invoiceIndex}`,
                    {
                        signal: controller.signal,
                        data: payload,
                    }
                );
            }

            setSnackbarMessage(
                isChecked
                    ? `Deleted ${selectedRowsInvoice[selectedWarranty.id].length} invoice(s) successfully`
                    : `Detached ${selectedRowsInvoice[selectedWarranty.id].length} invoice(s) successfully`
            );
            setSnackbarType('success');
            setSnackbarOpen(true);
            setSelectedRowsInvoice({
                ...selectedRowsInvoice,
                [selectedWarranty.id]: [],
            });
            setSelectedWarranty(null);
            resetState();
            setDeleteInvoiceModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setSnackbarMessage(error.response?.data.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Yes':
                handleDeleteInvoices();
                break;
            case 'No':
                setDeleteInvoiceModalOpen(false);
                resetState();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const resetState = () => {
        setIsChecked(false);
        setIsDocDelChecked(false);
    };

    return (
        <Modal
            open={isDeleteInvoiceModalOpen}
            onClose={() => {
                setDeleteInvoiceModalOpen(false);
                resetState();
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '25%',
                    height: isMobile ? '40%' : '25%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '15%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setDeleteInvoiceModalOpen(false);
                            resetState();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        {`Are you sure you want to detach ${selectedRowsInvoice[selectedWarranty?.id]?.length} invoice(s)?`}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2,
                        }}
                    >
                        <Checkbox
                            color="primary"
                            checked={isChecked}
                            onChange={() => {
                                setIsChecked(!isChecked);
                                if (!isChecked && isDocDelChecked) {
                                    setIsDocDelChecked(false);
                                }
                            }}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            Detach and Delete
                        </Typography>
                    </Box>
                    {isChecked && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                            <Checkbox
                                color="primary"
                                disabled={!isChecked}
                                checked={isDocDelChecked && isChecked}
                                onChange={() =>
                                    setIsDocDelChecked(!isDocDelChecked)
                                }
                            />
                            <Typography
                                variant="h6"
                                color={colors.grey[100]}
                                fontWeight="bold"
                            >
                                Delete attached documents
                            </Typography>
                        </Box>
                    )}
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                mt: 2,
                                alignSelf: 'center',
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) => handleSelectAction(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select action' }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                onClick={handleDeleteInvoices}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => {
                                    setDeleteInvoiceModalOpen(false);
                                    resetState();
                                }}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[600],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                No
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteInvoiceModal;
