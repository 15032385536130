import React, { useState } from 'react';
import {
    TableCell,
    TableSortLabel,
    Menu,
    MenuItem,
    IconButton,
    TextField,
    Select,
    InputLabel,
    FormControl,
    Button
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TableHeaderCell = ({ columns, column, columnHeaderName, order, orderBy, handleRequestSort, handleFilter, sortable, center }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [filterOptions, setFilterOptions] = useState({
        column: column,
        operator: 'contains',
        value: ''
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClick = (event) => {
        setFilterAnchorEl(anchorEl); // Set filter anchor to current target
        setAnchorEl(null); // Close the main menu
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleFilterMenuClose = () => {
        setFilterAnchorEl(null);
    };

    const handleSortClick = () => {
        if (orderBy === column) {
            // If the same column is clicked, toggle sorting direction or reset sort
            if (order === 'desc') {
                // Reset to first column with ascending order
                handleRequestSort('asc', columns[0].column);
            } else {
                // Toggle sorting direction
                handleRequestSort('desc', column);
            }
        } else {
            // Set new sort column and default direction to 'asc'
            handleRequestSort('asc', column);
        }
        handleMenuClose();
    };

    const applyFilter = () => {
        handleFilter(filterOptions);
        setFilterAnchorEl(null); // Close the filter menu
    };

    const clearFilter = () => {
        setFilterOptions({
            column: column,
            operator: 'contains',
            value: ''
        });
        handleFilter({
            column: column,
            operator: 'contains',
            value: ''
        });
        setFilterAnchorEl(null); // Close the filter menu
    };

    const handleOperatorChange = (event) => {
        const operator = event.target.value;
        setFilterOptions(prev => ({
            ...prev,
            operator: operator,
            value: operator === 'isEmpty' || operator === 'isNotEmpty' ? '' : prev.value
        }));
    };

    return (
        <TableCell
            sx={{
                position: 'relative',
                textAlign: center ? 'center' : 'left',
                '&:hover': {
                    '& .sort-label .MuiTableSortLabel-icon': {
                        visibility: sortable ? 'visible' : 'hidden',
                    },
                    '& .icon-button': {
                        visibility: sortable ? 'visible' : 'hidden',
                    },
                },
            }}
        >
            {sortable ? (
                <>
                    <TableSortLabel
                        className="sort-label"
                        active={orderBy === column}
                        direction={orderBy === column ? order : 'asc'}
                        onClick={handleSortClick}
                        sx={{
                            // Always visible
                            visibility: 'visible',
                            '& .MuiTableSortLabel-icon': {
                                visibility: 'hidden', // Initially hidden
                            },
                        }}
                    >
                        {columnHeaderName}
                    </TableSortLabel>
                    <IconButton
                        className="icon-button"
                        onClick={handleClick}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            visibility: 'hidden', // Initially hidden
                            transition: 'visibility 0.2s ease-in-out',
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </>
            ) : (
                columnHeaderName
            )}
            {sortable && (
                <>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{ width: '20vw' }} // Fixed width for the menu
                    >
                        <MenuItem onClick={() => handleSortClick('asc')}>Sort Asc</MenuItem>
                        <MenuItem onClick={() => handleSortClick('desc')}>Sort Desc</MenuItem>
                        <MenuItem onClick={handleFilterClick}>Filter</MenuItem>
                    </Menu>
                    <Menu
                        anchorEl={filterAnchorEl}
                        open={Boolean(filterAnchorEl)}
                        onClose={handleFilterMenuClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{ width: '40vw' }} // Fixed width for the filter menu
                    >
                        <MenuItem>
                            <FormControl fullWidth>
                                <InputLabel>Column</InputLabel>
                                <Select
                                    value={filterOptions.column}
                                    onChange={(e) => setFilterOptions({ ...filterOptions, column: e.target.value })}
                                >
                                    {columns
                                        .filter(col => col.sortable) // Only show sortable columns
                                        .map((col) => (
                                        <MenuItem key={col.column} value={col.column}>
                                            {col.columnHeaderName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </MenuItem>
                        <MenuItem>
                            <FormControl fullWidth>
                                <InputLabel>Operator</InputLabel>
                                <Select
                                    value={filterOptions.operator}
                                    onChange={handleOperatorChange}
                                >
                                    <MenuItem value="contains">Contains</MenuItem>
                                    <MenuItem value="equals">Equals</MenuItem>
                                    <MenuItem value="startsWith">Starts With</MenuItem>
                                    <MenuItem value="endsWith">Ends With</MenuItem>
                                    <MenuItem value="isEmpty">Is Empty</MenuItem>
                                    <MenuItem value="isNotEmpty">Is Not Empty</MenuItem>
                                    <MenuItem value="isAnyOf">Is Any Of</MenuItem>
                                </Select>
                            </FormControl>
                        </MenuItem>
                        {filterOptions.operator !== 'isEmpty' && filterOptions.operator !== 'isNotEmpty' && (
                            <MenuItem>
                                <TextField
                                    fullWidth
                                    label="Value"
                                    value={filterOptions.value}
                                    onChange={(e) => setFilterOptions({ ...filterOptions, value: e.target.value })}
                                />
                            </MenuItem>
                        )}
                        <MenuItem>
                            <Button onClick={clearFilter} sx={{ color: 'white', width: '100%' }}>Clear</Button>
                        </MenuItem>
                        <MenuItem>
                            <Button onClick={applyFilter} sx={{ color: 'white', width: '100%' }}>Apply Filter</Button>
                        </MenuItem>
                    </Menu>
                </>
            )}
        </TableCell>
    );
};

export default TableHeaderCell;
