import {
    Box,
    IconButton,
    useTheme,
    Menu,
    MenuItem,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import { ColorModeContext, tokens } from '../../theme';
import InputBase from '@mui/material/InputBase';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import useLogout from '../../hooks/useLogout';
import { useNavigate } from 'react-router-dom';
import ChangePasswordModal from './ChangePasswordModal';

const Topbar = ({ toggleSidebar, isMobile }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const open = Boolean(anchorEl);
    const logout = useLogout();
    const navigate = useNavigate();

    const signOut = async () => {
        await logout();
        navigate('/login');
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        const closeSnackbarOnOutsideClick = (event) => {
            if (snackbarOpen && !event.target.closest('.MuiAlert-root')) {
                setSnackbarOpen(false);
            }
        };

        document.addEventListener('click', closeSnackbarOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeSnackbarOnOutsideClick);
        };
    }, [snackbarOpen]);

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2} className="topbar">
            <Box display="flex" alignItems="center">
                {isMobile && (
                    <IconButton onClick={toggleSidebar}>
                        <MenuOutlinedIcon />
                    </IconButton>
                )}
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarType}
                    sx={{
                        width: '100%',
                        backgroundColor:
                            snackbarType === 'success'
                                ? colors.greenAccent[600]
                                : colors.redAccent[600],
                        color: 'white',
                    }}
                >
                    <Typography variant="h6" color={colors.grey[100]}>
                        {snackbarMessage}
                    </Typography>
                </Alert>
            </Snackbar>

            {/* SEARCH BAR AND ICONS */}
            <Box display="flex" alignItems="center" ml="auto">
                {/* <Box
                    display="flex"
                    alignItems="center"
                    backgroundColor={colors.primary[400]}
                    borderRadius="3px"
                    mr={2} // Add margin to create space between search bar and icons
                >
                    <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box> */}
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                {/* <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton> */}
                {/* <IconButton>
                    <SettingsOutlinedIcon />
                </IconButton> */}
                <IconButton onClick={handleMenu}>
                    <PersonOutlinedIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate('/profile');
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            User Profile
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            setChangePasswordModalOpen(true);
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Change Password
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            signOut();
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Log out
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
            <ChangePasswordModal
                isChangePasswordModalOpen={isChangePasswordModalOpen}
                setChangePasswordModalOpen={setChangePasswordModalOpen}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarType={setSnackbarType}
                setSnackbarOpen={setSnackbarOpen}
            />
        </Box>
    );
};

export default Topbar;
