import React, { useRef, useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    useTheme,
} from '@mui/material';
import { Field, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import IconButton from '@mui/material/IconButton';
import * as yup from 'yup';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useTeamData from '../../hooks/useTeamData';
import { useNavigate, useLocation } from 'react-router-dom';

const TeamModal = ({
    isModalOpen,
    setModalOpen,
    setSelectedOption,
    selectedOption,
    checkedUsernames,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { dropdownData, fetchTeams } = useTeamData();
    const controllerRef = useRef(new AbortController());
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1050);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1050);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleToggleChange = (event, newOption) => {
        setSelectedOption(newOption === null ? selectedOption : newOption);
    };

    const handleButtonClick = async (values, { resetForm }) => {
        const controller = new AbortController();
        controllerRef.current = controller;
        if (selectedOption === 'newTeam') {
            try {
                const response = await axiosPrivate.post(
                    '/team/teams/persons',
                    {
                        name: values.teamName,
                        persons: checkedUsernames,
                    },
                    {
                        signal: controller.signal,
                    }
                );
                fetchTeams();
                const { name, persons } = response.data;
                const count = persons.length;

                setSnackbarMessage(
                    `Added ${name} successfully with ${count} user(s)`
                );
                setSnackbarType('success');
                resetForm();
                setModalOpen(false);
                setSnackbarOpen(true);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login', {
                        state: { from: location },
                        replace: true,
                    });
                } else {
                    setSnackbarMessage(
                        error.response?.data.message || 'Error occurred'
                    );
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                }
            }
        } else if (selectedOption === 'addToTeam') {
            try {
                const response = await axiosPrivate.patch(
                    `/team/team/${values.selectedTeamId}/persons`,
                    {
                        persons: checkedUsernames,
                    },
                    {
                        signal: controller.signal,
                    }
                );
                const { name } = response.data;
                const count = checkedUsernames.length;
                setSnackbarMessage(
                    `Updated ${name} successfully with ${count} user(s)`
                );
                setSnackbarType('success');
                resetForm();
                setModalOpen(false);
                setSnackbarOpen(true);
                setSelectedOption('newTeam');
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login', {
                        state: { from: location },
                        replace: true,
                    });
                } else {
                    setSnackbarMessage(
                        error.response?.data.message || 'Error occurred'
                    );
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                }
            }
        }
    };

    const getValidationSchema = (selectedOption) => {
        if (selectedOption === 'newTeam') {
            return yup.object().shape({
                teamName: yup.string().required('Team Name is required'),
            });
        } else if (selectedOption === 'addToTeam') {
            return yup.object().shape({
                selectedTeamId: yup.string().required('Please select a team'),
            });
        } else {
            return yup.object().shape({});
        }
    };

    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                document.querySelector('form').dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true })
                );
                break;
            case 'Close':
                setModalOpen(false);
                setSelectedOption('newTeam');
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const renderModalContent = () => {
        return (
            <Formik
                initialValues={{ teamName: '', selectedTeamId: '' }}
                validationSchema={() => getValidationSchema(selectedOption)}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    handleButtonClick(values, { setSubmitting, resetForm });
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {selectedOption === 'newTeam' ? (
                            <Box
                                sx={{
                                    mt: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box sx={{ width: '80%' }}>
                                    <Typography variant="h6" sx={{ mb: 1 }}>
                                        Enter Team Name:
                                    </Typography>
                                    <Field
                                        type="text"
                                        name="teamName"
                                        label="Enter Team Name"
                                        autoFocus
                                        as={TextField}
                                        sx={{
                                            width: '80%',
                                            boxShadow: `0px 2px 4px ${colors.grey[200]}`,
                                        }}
                                        variant="filled"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.teamName}
                                        error={
                                            !!touched.teamName &&
                                            !!errors.teamName
                                        }
                                        helperText={
                                            touched.teamName && errors.teamName
                                        }
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    mt: '30px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '20px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '80%',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{ mr: '8px', flex: '0 0 auto' }}
                                    >
                                        Select Team:
                                    </Typography>
                                    <FormControl
                                        sx={{
                                            flex: '1',
                                            minWidth: '200px',
                                            width: '100%',
                                        }}
                                    >
                                        <InputLabel id="team-select-label">
                                            Select a team
                                        </InputLabel>
                                        <Field
                                            as={Select}
                                            id="selectedTeamId"
                                            name="selectedTeamId"
                                            labelId="team-select-label"
                                            value={values.selectedTeamId}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            sx={{
                                                flex: '1',
                                                textAlign: 'left',
                                                boxShadow: `0px 2px 4px ${colors.grey[200]}`,
                                                width: '100%',
                                            }}
                                            inputProps={{
                                                style: { textAlign: 'left' },
                                            }}
                                            label="Select Team"
                                            error={
                                                !!touched.selectedTeamId &&
                                                !!errors.selectedTeamId
                                            }
                                            helperText={
                                                touched.selectedTeamId &&
                                                errors.selectedTeamId
                                            }
                                        >
                                            {dropdownData.map((team) => (
                                                <MenuItem
                                                    key={team.id}
                                                    value={team.id}
                                                >
                                                    {team.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Box>
                                {touched.selectedTeamId &&
                                    errors.selectedTeamId && (
                                        <Box
                                            sx={{
                                                color: colors.redAccent[500],
                                                marginTop: '5px',
                                                textAlign: 'center',
                                                width: '80%',
                                            }}
                                        >
                                            {errors.selectedTeamId}
                                        </Box>
                                    )}
                            </Box>
                        )}
                        <Box
                            sx={{
                                height: '70%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: theme.spacing(2),
                            }}
                        >
                            {isMobile ? (
                                <FormControl sx={{ width: '90%' }}>
                                    <Select
                                        value={selectedAction}
                                        onChange={handleSelectAction}
                                        displayEmpty
                                        sx={{
                                            color: colors.grey[100],
                                            backgroundColor:
                                                colors.primary[400],
                                            borderRadius: '8px',
                                            '.MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderColor:
                                                        colors.grey[300],
                                                },
                                            '&:hover .MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderColor:
                                                        colors.grey[100],
                                                },
                                            '& .MuiSvgIcon-root': {
                                                color: colors.grey[100],
                                            },
                                            boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Select an action
                                        </MenuItem>
                                        <MenuItem value="Submit">
                                            Submit
                                        </MenuItem>
                                        <MenuItem value="Close">Close</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : (
                                <>
                                    <Button
                                        type="submit"
                                        sx={{
                                            color: 'white',
                                            backgroundColor:
                                                colors.greenAccent[500],
                                            '&:hover': {
                                                backgroundColor:
                                                    colors.greenAccent[700],
                                            },
                                            whiteSpace: 'nowrap',
                                        }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            setModalOpen(false);
                                            setSelectedOption('newTeam');
                                        }}
                                        sx={{
                                            color: 'white',
                                            backgroundColor:
                                                colors.redAccent[500],
                                            '&:hover': {
                                                backgroundColor:
                                                    colors.redAccent[700],
                                            },
                                            whiteSpace: 'nowrap',
                                        }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Close
                                    </Button>
                                </>
                            )}
                        </Box>
                    </form>
                )}
            </Formik>
        );
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                setModalOpen(false);
                setSelectedOption('newTeam');
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '45%' : '40%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '10%',
                        width: '97%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setModalOpen(false);
                            setSelectedOption('newTeam');
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        height: '10%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ToggleButtonGroup
                        value={selectedOption}
                        exclusive
                        onChange={handleToggleChange}
                        sx={{
                            boxShadow: `0px 2px 4px ${colors.grey[200]}`,
                        }}
                    >
                        <ToggleButton value="newTeam">New Team</ToggleButton>
                        <ToggleButton value="addToTeam">
                            Add to Team
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        padding: isMobile ? '8px' : '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between', // Adjusts the space to place buttons at the bottom
                    }}
                >
                    {renderModalContent()}
                </Box>
            </Box>
        </Modal>
    );
};

export default TeamModal;
