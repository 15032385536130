import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    TextField,
    useMediaQuery,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditDocumentModal = ({
    isEditDocumentModalOpen,
    setEditDocumentModalOpen,
    selectedProject,
    setSelectedProject,
    selectedDocument,
    setSelectedDocument,
    selectedAddress,
    setSelectedAddress,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => {
        setSelectedDocument(null);
        setSelectedProject(null);
        setSelectedAddress(null);
        setSelectedFile(null);
        setEditDocumentModalOpen(false);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleCancelFile = () => {
        setSelectedFile(null);
    };

    const handleSubmit = async (values) => {
        const controller = new AbortController();
        try {
            await axiosPrivate.patch(
                `/project/project/${selectedProject.id}/address/${selectedAddress.id}/document/${selectedDocument.id}`,
                {
                    title: values.title,
                    description: values.description,
                    doc_type: values.doc_type,
                },
                {
                    signal: controller.signal,
                }
            );

            if (selectedFile) {
                const formData = new FormData();
                formData.append('document', selectedFile);
                await axiosPrivate.put(
                    `/project/project/${selectedProject.id}/address/${selectedAddress.id}/document/${selectedDocument.id}/document`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        signal: controller.signal,
                    }
                );
            }
            setSnackbarMessage(`Updated document for the address successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            setSelectedProject(null);
            setSelectedDocument(null);
            setSelectedAddress(null);
            setSelectedFile(null);
            setEditDocumentModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        doc_type: Yup.string().notRequired(),
    });

    useEffect(() => {
        formik.setValues({
            title: selectedDocument?.title || '',
            description: selectedDocument?.description || '',
            doc_type: selectedDocument?.doc_type || '',
        });
    }, [isEditDocumentModalOpen, selectedDocument]);

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            doc_type: '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                formik.handleSubmit();
                break;
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal open={isEditDocumentModalOpen} onClose={handleClose}>
            <form onSubmit={formik.handleSubmit}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        width: isMobile ? '80%' : '30%',
                        height: isMobile ? '60%' : '80%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            height: '7%',
                            width: '98%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h6' : 'h5'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                            textAlign="center"
                            sx={{ marginBottom: 2 }}
                        >
                            {`${selectedDocument?.title}`}
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                            sx={{
                                width: '70%',
                                '& > div': {
                                    gridColumn: isNonMobile ? undefined : 'span 1',
                                },
                            }}
                        >
                            <FormControl fullWidth variant="filled">
                                <Select
                                    name="doc_type"
                                    value={formik.values.doc_type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.doc_type && Boolean(formik.errors.doc_type)}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        Select Document Type
                                    </MenuItem>
                                    <MenuItem value="picture">Picture</MenuItem>
                                    <MenuItem value="invoice">Invoice</MenuItem>
                                    <MenuItem value="blueprint">Blueprint</MenuItem>
                                    <MenuItem value="misc">Misc</MenuItem>
                                </Select>
                                {formik.touched.doc_type && formik.errors.doc_type && (
                                    <Typography variant="caption" color="error">
                                        {formik.errors.doc_type}
                                    </Typography>
                                )}
                            </FormControl>
                            <TextField
                                fullWidth
                                variant="filled"
                                value={formik.values.title}
                                name="title"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                            />
                            <TextField
                                fullWidth
                                multiline
                                rows={10}
                                variant="filled"
                                value={formik.values.description}
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                            <input
                                id="contained-button-file"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <label htmlFor="contained-button-file">
                                    <Button
                                        variant="outlined"
                                        component="span"
                                        sx={{
                                            color: colors.grey[100],
                                            borderColor: colors.grey[100],
                                            marginRight: theme.spacing(1),
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        Update Document
                                    </Button>
                                </label>
                                {selectedFile && (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: colors.greenAccent[500],
                                        }}
                                    >
                                        &#10003; Selected
                                    </Typography>
                                )}
                                {selectedFile && (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            marginLeft: theme.spacing(1),
                                            color: colors.redAccent[500],
                                            cursor: 'pointer',
                                            transition: 'color 0.3s',
                                            '&:hover': {
                                                color: colors.redAccent[700],
                                                textDecoration: 'underline',
                                            },
                                        }}
                                        onClick={handleCancelFile}
                                    >
                                        Cancel
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        {isMobile ? (
                            <FormControl
                                sx={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    mt: 2,
                                    alignSelf: 'center',
                                }}
                            >
                                <Select
                                    value={selectedAction}
                                    onChange={(e) =>
                                        handleSelectAction(e.target.value)
                                    }
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select action' }}
                                    sx={{
                                        color: colors.grey[100],
                                        backgroundColor: colors.primary[400],
                                        borderRadius: '8px',
                                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: colors.grey[300],
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: colors.grey[100],
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: colors.grey[100],
                                        },
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select an action
                                    </MenuItem>
                                    <MenuItem value="Submit">Submit</MenuItem>
                                    <MenuItem value="Close">Close</MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 2,
                                    mt: 2,
                                }}
                            >
                                <Button
                                    type="submit"
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        whiteSpace: 'nowrap',
                                    }}
                                    variant="contained"
                                >
                                    Submit
                                </Button>
                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.redAccent[700],
                                        },
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Close
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </form>
        </Modal>
    );
};

export default EditDocumentModal;
