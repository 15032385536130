import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, useTheme, IconButton, Select, MenuItem, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import AddDocumentModal from './document/AddDocumentModal';
import AttachDocumentModal from './document/AttachDocumentModal';

const AddAttachDocumentModal = ({
    isAddAttachDocumentModalOpen,
    setAddAttachDocumentModalOpen,
    selectedWarranty,
    setSelectedWarranty,
    selectedInvoice,
    setSelectedInvoice,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isAddDocumentModalOpen, setAddDocumentModalOpen] = useState(false);
    const [isAttachDocumentModalOpen, setAttachDocumentModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Add Document':
                setAddDocumentModalOpen(true);
                break;
            case 'Attach Document':
                setAttachDocumentModalOpen(true);
                break;
            case 'Close':
                setAddAttachDocumentModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal
            open={isAddAttachDocumentModalOpen}
            onClose={() => {
                setAddAttachDocumentModalOpen(false);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '35%',
                    height: isMobile ? '20%' : '25%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between', // Ensures content is spaced properly
                }}
            >
                {/* Close Icon */}
                <Box
                    sx={{
                        height: '15%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setAddAttachDocumentModalOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Title */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h4' : 'h2'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        Select an option:
                    </Typography>
                </Box>

                {/* Action Buttons or Select Menu */}
                <Box
                    sx={{
                        p: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: theme.spacing(2),
                        alignSelf: 'center',
                        width: '100%',
                    }}
                >
                    {isMobile ? (
                        <FormControl sx={{ borderRadius: '8px' }}>
                            <Select
                                value={selectedAction}
                                onChange={handleSelectAction}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select action' }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Add Document">Add Document</MenuItem>
                                <MenuItem value="Attach Document">Attach Document</MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                onClick={() => setAddDocumentModalOpen(true)}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Add Document
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => setAttachDocumentModalOpen(true)}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Attach Document
                            </Button>
                            <Button
                                onClick={() => setAddAttachDocumentModalOpen(false)}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                            >
                                Close
                            </Button>
                        </>
                    )}
                </Box>

                <AddDocumentModal
                    isAddDocumentModalOpen={isAddDocumentModalOpen}
                    setAddDocumentModalOpen={setAddDocumentModalOpen}
                    setAddAttachDocumentModalOpen={setAddAttachDocumentModalOpen}
                    selectedWarranty={selectedWarranty}
                    setSelectedWarranty={setSelectedWarranty}
                    selectedInvoice={selectedInvoice}
                    setSelectedInvoice={setSelectedInvoice}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarType={setSnackbarType}
                    setSnackbarOpen={setSnackbarOpen}
                />
                <AttachDocumentModal
                    isAttachDocumentModalOpen={isAttachDocumentModalOpen}
                    setAttachDocumentModalOpen={setAttachDocumentModalOpen}
                    setAddAttachDocumentModalOpen={setAddAttachDocumentModalOpen}
                    selectedWarranty={selectedWarranty}
                    setSelectedWarranty={setSelectedWarranty}
                    selectedInvoice={selectedInvoice}
                    setSelectedInvoice={setSelectedInvoice}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarType={setSnackbarType}
                    setSnackbarOpen={setSnackbarOpen}
                />
            </Box>
        </Modal>
    );
};

export default AddAttachDocumentModal;
