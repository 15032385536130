import useAxiosPrivate from './useAxiosPrivate';
import useAuth from './useAuth';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

const useAvatarUrl = () => {
    const { updateAvatarUrl, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const fetchAvatarUrl = async (userId) => {
        try {
            const response = await axiosPrivate.get(`person/person/${userId}/avatar`);
            const { url } = response.data;

            if (url) {
                updateAvatarUrl(url);
            } else {
                // No avatar available, generate a default one
                const initialsAvatarUrl = generateInitialsAvatar(auth.username);
                updateAvatarUrl(initialsAvatarUrl);
            }

        } catch (error) {
            // Fallback to default avatar on error
            const initialsAvatarUrl = generateInitialsAvatar(auth.username);
            updateAvatarUrl(initialsAvatarUrl);
        }
    };

    const generateInitialsAvatar = (name) => {
        const initials = name ? name.slice(0, 2).toUpperCase() : 'U'; // Default to 'U' if name is not available
        const bgColor = generateRandomColor(initials);

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = 100;
        canvas.height = 100;
        context.fillStyle = bgColor;
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font = '50px Arial';
        context.fillStyle = colors.primary[500];
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(initials, canvas.width / 2, canvas.height / 2);

        return canvas.toDataURL(); // Returns a base64-encoded image
    };

    const generateRandomColor = (initials) => {
        const hash = initials.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
        const hue = (hash % 25) / 25;
        const rgb = hsvToRgb(hue, 0.8, 0.8);
        return rgbToHex(rgb[0], rgb[1], rgb[2]);
    };

    const rgbToHex = (r, g, b) => {
        const toHex = (c) => {
            const hex = c.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    const hsvToRgb = (h, s, v) => {
        let r, g, b;
        const i = Math.floor(h * 6);
        const f = h * 6 - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);

        switch (i % 6) {
            case 0: r = v; g = t; b = p; break;
            case 1: r = q; g = v; b = p; break;
            case 2: r = p; g = v; b = t; break;
            case 3: r = p; g = q; b = v; break;
            case 4: r = t; g = p; b = v; break;
            case 5: r = v; g = p; b = q; break;
            default: break;
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    };

    return fetchAvatarUrl;
};

export default useAvatarUrl;
