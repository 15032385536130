// useAvatarData.js
import { useEffect, useState } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const useAvatarData = (personId) => {
    const axiosPrivate = useAxiosPrivate();
    const [avatarPath, setAvatarPath] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchAvatar = async () => {
            try {
                const response = await axiosPrivate.get(
                    `person/person/${personId}/avatar`,
                );
                if (isMounted) {
                    setAvatarPath(response.data.url);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login', {
                        state: { from: location },
                        replace: true,
                    });
                }
            }
        };
        fetchAvatar();
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate, personId, location, navigate]);

    return avatarPath;
};

export default useAvatarData;
