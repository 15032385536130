import React from 'react';
import { Modal, Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { tokens } from '../../theme';

const CircularLoading = ({ isOpen }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Modal open={isOpen}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: '30%', // Adjust width as needed
                    height: '20%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress color="secondary" />
                    <Typography
                        variant="h4"
                        color={colors.grey[100]}
                        sx={{ ml: 1 }}
                    >
                        Loading...
                    </Typography>
                </Box>
            </Box>
        </Modal>
    );
};

export default CircularLoading;
