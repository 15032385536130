// src/components/VerifyCode/index.jsx
import React, { useContext, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    useTheme,
    Snackbar,
    Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../api/axios';
import { tokens } from '../../../../theme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PasswordResetContext } from '../../../../context/PasswordResetContext';

const PASSWORD_RESET_VERIFY_URL = '/auth/password-reset/verify';
const PASSWORD_RESET_REQUEST_URL = '/auth/password-reset/request'; // Endpoint to request a new code
const COOLDOWN_SECONDS = 180; // Cooldown period in seconds

const VerifyCode = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const {
        username,
        resetMessage,
        setResetMessage,
        resetMessageType,
        setResetMessageType,
        setTempToken,
    } = useContext(PasswordResetContext);
    const [snackbarOpen, setSnackbarOpen] = useState(Boolean(resetMessage));

    // State variables for cooldown timer and request status
    const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0);
    const [isRequestingCode, setIsRequestingCode] = useState(false);

    // On component mount, check for existing cooldown
    useEffect(() => {
        const storedCooldownStart = localStorage.getItem('cooldownStartTime');
        if (storedCooldownStart) {
            const cooldownStartTime = parseInt(storedCooldownStart, 10);
            const timeElapsed = Math.floor(Date.now() / 1000) - cooldownStartTime;
            const timeLeft = COOLDOWN_SECONDS - timeElapsed;
            if (timeLeft > 0) {
                setCooldownTimeLeft(timeLeft);
            } else {
                // Cooldown has expired, remove from localStorage
                localStorage.removeItem('cooldownStartTime');
            }
        }
    }, []);

    // Effect to handle the cooldown timer countdown
    useEffect(() => {
        let timer;
        if (cooldownTimeLeft > 0) {
            timer = setInterval(() => {
                setCooldownTimeLeft((prev) => {
                    if (prev <= 1) {
                        // Cooldown has ended
                        localStorage.removeItem('cooldownStartTime');
                        clearInterval(timer);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [cooldownTimeLeft]);

    // Function to handle requesting a new code
    const handleRequestNewCode = async () => {
        if (cooldownTimeLeft > 0) {
            return;
        }

        setIsRequestingCode(true);
        try {
            await axios.post(
                PASSWORD_RESET_REQUEST_URL,
                JSON.stringify({ username }),
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            // Set success message
            setResetMessage('A new code has been sent to your email.');
            setResetMessageType('success');
            setSnackbarOpen(true);

            // Start the cooldown timer
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            localStorage.setItem('cooldownStartTime', currentTime.toString());
            setCooldownTimeLeft(COOLDOWN_SECONDS);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                // Cooldown period is active
                const retryAfter = error.response.headers['retry-after'];
                const timeLeft = parseInt(retryAfter, 10) || COOLDOWN_SECONDS;
                setCooldownTimeLeft(timeLeft);
                setResetMessage('Please wait before requesting a new code.');
                setResetMessageType('error');
                setSnackbarOpen(true);
            } else {
                setResetMessage('An error occurred while requesting a new code.');
                setResetMessageType('error');
                setSnackbarOpen(true);
            }
        } finally {
            setIsRequestingCode(false);
        }
    };

    // Formik setup for form handling
    const formik = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: Yup.object({
            code: Yup.string().required('Verification code is required'),
        }),
        onSubmit: async (values) => {
            try {
                const response = await axios.post(
                    PASSWORD_RESET_VERIFY_URL,
                    JSON.stringify({ username, code: values.code }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                );
                // Store the temporary token
                setTempToken(response.data.temp_token);

                // Set success message
                setResetMessage('Code verified. You can now reset your password.');
                setResetMessageType('success');

                // Navigate to reset password page
                navigate('/password-reset/new-password');
            } catch (error) {
                setResetMessage('Invalid or expired code.');
                setResetMessageType('error');
                setSnackbarOpen(true);
            }
        },
    });

    const handleSnackbarClose = (event, reason) => {
        setSnackbarOpen(false);
        if (resetMessageType !== 'success') {
            setResetMessage('');
            setResetMessageType('');
        }
    };


    // Redirect if username is not set
    if (!username) {
        navigate('/password-reset');
        return null;
    }

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: theme.spacing(8),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {/* Snackbar for messages */}
                {resetMessage && (
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={resetMessageType}
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    resetMessageType === 'success'
                                        ? colors.greenAccent[600]
                                        : colors.redAccent[600],
                                color: 'white',
                            }}
                        >
                            <Typography variant="h6">{resetMessage}</Typography>
                        </Alert>
                    </Snackbar>
                )}
                <Box
                    sx={{
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        padding: theme.spacing(4),
                        width: '100%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4">
                        Verify Code
                    </Typography>
                    <Typography variant="body1" sx={{ mt: theme.spacing(1) }}>
                        Please enter the verification code sent to your email.
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        sx={{ mt: theme.spacing(2), width: '100%' }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="code"
                            label="Verification Code"
                            name="code"
                            autoFocus
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                            helperText={formik.touched.code && formik.errors.code}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: theme.spacing(2),
                            }}
                        >
                            <Typography
                                variant="body2"
                                color={cooldownTimeLeft > 0 ? 'textSecondary' : 'primary'}
                                onClick={cooldownTimeLeft > 0 ? null : handleRequestNewCode}
                                sx={{
                                    cursor: cooldownTimeLeft > 0 ? 'default' : 'pointer',
                                    textDecoration: 'underline',
                                }}
                            >
                                Request New Code
                            </Typography>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    bgcolor: colors.blueAccent[700],
                                    '&:hover': {
                                        bgcolor: colors.blueAccent[400],
                                    },
                                }}
                            >
                                Verify
                            </Button>
                        </Box>
                        {/* Countdown Timer */}
                        {cooldownTimeLeft > 0 && (
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ mt: theme.spacing(1) }}
                            >
                                You can request a new code in {cooldownTimeLeft} seconds
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default VerifyCode;
