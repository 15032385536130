// Avatar.js

import React from 'react';
import Avatar from '@mui/material/Avatar';
import useAvatarData from '../../hooks/useAvatarData';

const AvatarManager = ({ personId, username }) => {
    const avatarPath = useAvatarData(personId);

    const rgbToHex = (r, g, b) => {
        const toHex = (c) => {
            const hex = c.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };

        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    const generateRandomColor = (initials) => {
        const hash = initials
            .split('')
            .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
        const hue = (hash % 25) / 25;

        const rgb = hsvToRgb(hue, 0.8, 0.8);
        const hexColor = rgbToHex(rgb[0], rgb[1], rgb[2]);

        return `${hexColor}`;
    };

    const hsvToRgb = (h, s, v) => {
        let r, g, b;

        const i = Math.floor(h * 6);
        const f = h * 6 - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);

        switch (i % 6) {
            case 0:
                r = v;
                g = t;
                b = p;
                break;
            case 1:
                r = q;
                g = v;
                b = p;
                break;
            case 2:
                r = p;
                g = v;
                b = t;
                break;
            case 3:
                r = p;
                g = q;
                b = v;
                break;
            case 4:
                r = t;
                g = p;
                b = v;
                break;
            case 5:
                r = v;
                g = p;
                b = q;
                break;
            default:
                break;
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    };

    const initials = username.slice(0, 2).toUpperCase();
    const randomColor = generateRandomColor(initials);

    return (
        <Avatar
            alt={initials}
            src={avatarPath}
            sx={{
                bgcolor: randomColor,
            }}
        >
            {initials}
        </Avatar>
    );
};

export default AvatarManager;
