import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    TextField,
    useMediaQuery,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import PassEditModal from './PassEditModal';
import { calculateShowAddToTeamButton } from '../people/RoleManager';
import useAuth from '../../hooks/useAuth';

const ViewInspectionModal = ({
    isViewModalOpen,
    setViewModalOpen,
    selectedInspection,
    setSelectedInspection,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [isPassEditModalOpen, setPassEditModalOpen] = useState(false);
    const { auth } = useAuth();
    const showAddToTeamButton = calculateShowAddToTeamButton(auth.roles);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Edit Pass':
                setPassEditModalOpen(true);
                break;
            case 'Close':
                setViewModalOpen(false);
                setSelectedInspection(null);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal
            open={isViewModalOpen}
            onClose={() => {
                setViewModalOpen(false);
                setSelectedInspection(null);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '70%' : '30%', // Adjust width based on isMobile
                    height: isMobile ? '40%' : '60%', // Adjust height based on isMobile
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between', // Ensure content is spaced correctly
                }}
            >
                <Box
                    sx={{
                        height: '10%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        px: 2,
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setViewModalOpen(false);
                            setSelectedInspection(null);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        {`${selectedInspection?.address.title}`}
                    </Typography>
                    <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        color={colors.grey[100]}
                    >
                        {`Type: ${selectedInspection?.inspection_type}`}
                    </Typography>
                    <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        color={colors.grey[100]}
                    >
                        Status: {selectedInspection?.pass_fail === true ? 'Pass' : selectedInspection?.pass_fail === false ? 'Fail' : 'Not Available'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        padding: isMobile ? '8px' : '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between', // Ensures the select menu/buttons are at the bottom
                    }}
                >
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        variant="filled"
                        value={selectedInspection?.reason || ''}
                        name="reason"
                        InputProps={{ readOnly: true }}
                        sx={{ marginBottom: theme.spacing(2) }}
                    />
                    {isMobile ? (
                        <FormControl sx={{ width: '100%', marginTop: 'auto' }}>
                            <Select
                                value={selectedAction}
                                onChange={handleSelectAction}
                                displayEmpty
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                {showAddToTeamButton && (
                                    <MenuItem value="Edit Pass">Edit Pass</MenuItem>
                                )}
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: theme.spacing(2),
                                mt: 'auto',
                            }}
                        >
                            {showAddToTeamButton && (
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setPassEditModalOpen(true);
                                    }}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        whiteSpace: 'nowrap',
                                    }}
                                    variant="contained"
                                >
                                    Edit Pass
                                </Button>
                            )}
                            <Button
                                type="button"
                                onClick={() => {
                                    setViewModalOpen(false);
                                    setSelectedInspection(null);
                                }}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>

                <PassEditModal
                    isPassEditModalOpen={isPassEditModalOpen}
                    setPassEditModalOpen={setPassEditModalOpen}
                    selectedInspection={selectedInspection}
                    setSelectedInspection={setSelectedInspection}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarType={setSnackbarType}
                    setSnackbarOpen={setSnackbarOpen}
                />
            </Box>
        </Modal>
    );
};

export default ViewInspectionModal;
