import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    useMediaQuery,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularLoading from '../../global/CircularLoading';

const ViewAnyPictureModal = ({
    isViewAnyPictureModalOpen,
    setViewAnyPictureModalOpen,
    selectedPicture,
    setSelectedPicture,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [documentUrl, setDocumentUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const response = await axiosPrivate.get(
                    `/document/document/${selectedPicture.id}/document`
                );
                const documentUrl = response.data.url;
                setDocumentUrl(documentUrl);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login', {
                        state: { from: location },
                        replace: true,
                    });
                } else {
                    console.error('Error fetching document:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        if (isViewAnyPictureModalOpen) {
            fetchDocument();
        }

        return () => {
            // Clean up the document URL
            if (documentUrl) {
                URL.revokeObjectURL(documentUrl);
            }
        };
    }, [
        isViewAnyPictureModalOpen,
        selectedPicture,
        axiosPrivate,
        navigate,
        location,
        documentUrl,
    ]);

    const handleClose = () => {
        setViewAnyPictureModalOpen(false);
        setSelectedPicture(null);
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        if (action === 'Close') {
            handleClose();
        }

        setSelectedAction('');
    };

    return (
        <Modal open={isViewAnyPictureModalOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '60%',
                    height: isMobile ? '70%' : '80%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        {`${selectedPicture?.title}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {loading ? (
                        <CircularLoading isOpen={loading} />
                    ) : (
                        documentUrl && (
                            <iframe
                                src={documentUrl}
                                title="Document"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'white',
                                }}
                                allowFullScreen={true}
                            />
                        )
                    )}
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                mt: 2,
                                alignSelf: 'center',
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) =>
                                    handleSelectAction(e.target.value)
                                }
                                displayEmpty
                                inputProps={{
                                    'aria-label': 'Select action',
                                }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default ViewAnyPictureModal;
