import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    TextField,
    useMediaQuery,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const PassEditModal = ({
    isPassEditModalOpen,
    setPassEditModalOpen,
    selectedInspection,
    setSelectedInspection,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [status, setStatus] = useState(selectedInspection?.pass_fail || null);
    const [reason, setReason] = useState(selectedInspection?.reason || '');
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleStatusChange = (event) => {
        setStatus(event.target.value !== null ? event.target.value : null);
    };

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const handleClose = () => {
        setPassEditModalOpen(false);
        setStatus(null);
        setReason('');
    };

    useEffect(() => {
        setStatus(
            selectedInspection?.pass_fail === undefined
                ? null
                : selectedInspection?.pass_fail
        );
        setReason(selectedInspection?.reason || '');
    }, [selectedInspection]);

    const handleSubmit = async () => {
        const controller = new AbortController();
        try {
            const updatedInspection = {
                ...selectedInspection,
                reason: reason,
                pass_fail: status,
            };
            await axiosPrivate.patch(
                `/inspection/inspection/${selectedInspection.id}`,
                {
                    reason: reason,
                    pass_fail: status,
                },
                {
                    signal: controller.signal,
                }
            );
            setSnackbarMessage(
                `Updated follow-up date for the inspection successfully`
            );

            setSnackbarType('success');
            setSnackbarOpen(true);
            setSelectedInspection(updatedInspection);

            setPassEditModalOpen(false);
            setStatus(null);
            setReason('');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                handleSubmit();
                break;
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal open={isPassEditModalOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '70%' : '30%', // Adjust width based on isMobile
                    height: isMobile ? '50%' : '60%', // Adjust height based on isMobile
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Close Icon Box */}
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton edge="end" color="inherit" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Text Box (Address Title & Inspection Type) */}
                <Box
                    sx={{
                        height: '13%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="h5"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        fontSize={isMobile ? '20px' : '24px'}
                    >
                        {`${selectedInspection?.address.title}`}
                    </Typography>
                    <Typography
                        variant="body1"
                        color={colors.grey[100]}
                        fontSize={isMobile ? '18px' : '20px'}
                    >
                        {`Type: ${selectedInspection?.inspection_type}`}
                    </Typography>
                </Box>

                {/* Scrollable Box (Select, TextField, Buttons) */}
                <Box
                    sx={{
                        height: '80%', // Adjust height based on available space
                        width: '100%',
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: theme.spacing(2),
                    }}
                >
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={status}
                            onChange={handleStatusChange}
                            label="Status"
                        >
                            <MenuItem value={null}>Not Available</MenuItem>
                            <MenuItem value={true}>Pass</MenuItem>
                            <MenuItem value={false}>Fail</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        multiline
                        rows={10} // This defines how many rows before scroll appears
                        variant="filled"
                        value={reason}
                        name="reason"
                        onChange={handleReasonChange}
                        sx={{ marginTop: theme.spacing(2) }}
                    />
                    {isMobile && (
                        <FormControl
                            sx={{ width: '100%', marginTop: 'auto', mt: 2 }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={handleSelectAction}
                                displayEmpty
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Submit">Submit</MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {!isMobile && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: theme.spacing(1),
                                width: '100%',
                                marginTop: theme.spacing(2),
                            }}
                        >
                            <Button
                                type="button"
                                onClick={handleSubmit}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default PassEditModal;
