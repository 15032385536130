import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Chip,
    OutlinedInput,
    Checkbox,
    useTheme,
    IconButton,
} from '@mui/material';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import * as yup from 'yup';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import usePeopleData from '../../hooks/usePeopleData';

const AddTeamModal = ({
    isAddModalOpen,
    setAddModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const { personData } = usePeopleData();
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleFormSubmit = async (values, { resetForm }) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.post(
                '/team/teams/persons',
                {
                    name: values.teamName,
                    persons: values.persons,
                },
                {
                    signal: controller.signal,
                }
            );
            const { name, persons } = response.data;
            const count = persons.length;

            setSnackbarMessage(
                `Added ${name} successfully with ${count} user(s)`
            );
            setSnackbarType('success');
            resetForm();
            setAddModalOpen(false);
            setSnackbarOpen(true);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = (event, formikSubmit) => {
        const action = event.target.value;
        setSelectedAction(action);

        if (action === 'create') {
            formikSubmit(); // Use Formik's handleSubmit method
        } else if (action === 'close') {
            setAddModalOpen(false);
        }

        setSelectedAction('');
    };

    const getValidationSchema = yup.object().shape({
        teamName: yup.string().required('Team Name is required'),
    });

    return (
        <Modal
            open={isAddModalOpen}
            onClose={() => {
                setAddModalOpen(false);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '35%' : '40%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Close Icon */}
                <Box
                    sx={{
                        height: '10%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setAddModalOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Title */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h4' : 'h2'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        Add Team
                    </Typography>
                </Box>

                {/* Form Content */}
                <Box
                    sx={{
                        width: '100%',
                        paddingX: 3,
                        overflowY: 'auto',
                    }}
                >
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={{
                            teamName: '',
                            persons: [],
                        }}
                        validationSchema={getValidationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={isMobile ? '16px' : '24px'}
                                    mb={2}
                                >
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Team Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.teamName}
                                        name="teamName"
                                        error={
                                            !!touched.teamName &&
                                            !!errors.teamName
                                        }
                                        helperText={
                                            touched.teamName &&
                                            errors.teamName
                                        }
                                    />
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel id="user-chip-label">
                                            Users
                                        </InputLabel>
                                        <Select
                                            labelId="user-chip-label"
                                            id="user-select-chips"
                                            multiple
                                            value={values.persons}
                                            onChange={(event) => {
                                                const {
                                                    target: { value },
                                                } = event;
                                                setFieldValue(
                                                    'persons',
                                                    typeof value === 'string'
                                                        ? value.split(',')
                                                        : value
                                                );
                                            }}
                                            onBlur={handleBlur}
                                            input={
                                                <OutlinedInput
                                                    id="user-select-chips"
                                                    label="Chip"
                                                />
                                            }
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {personData.map((person) => (
                                                <MenuItem
                                                    key={person.username}
                                                    value={person.username}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            values.persons.indexOf(
                                                                person.username
                                                            ) > -1
                                                        }
                                                    />
                                                    {person.username}:{' '}
                                                    {person.first_name}{' '}
                                                    {person.last_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {/* Buttons / Select Action */}
                                {isMobile ? (
                                    <FormControl
                                        sx={{
                                            width: '100%',
                                            borderRadius: '8px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Select
                                            value={selectedAction}
                                            onChange={(e) =>
                                                handleSelectAction(e, handleSubmit)
                                            }
                                            displayEmpty
                                            sx={{
                                                color: colors.grey[100],
                                                backgroundColor:
                                                    colors.primary[400],
                                                borderRadius: '8px',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor:
                                                        colors.grey[300],
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor:
                                                        colors.grey[100],
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: colors.grey[100],
                                                },
                                                boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                Select an action
                                            </MenuItem>
                                            <MenuItem value="create">
                                                Create Team
                                            </MenuItem>
                                            <MenuItem value="close">
                                                Close
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        gap={2}
                                        mt={2}
                                        mb={3}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                color: 'white',
                                                backgroundColor:
                                                    colors.greenAccent[500],
                                                '&:hover': {
                                                    backgroundColor:
                                                        colors.greenAccent[700],
                                                },
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            Create Team
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() =>
                                                setAddModalOpen(false)
                                            }
                                            sx={{
                                                color: 'white',
                                                backgroundColor:
                                                    colors.redAccent[500],
                                                '&:hover': {
                                                    backgroundColor:
                                                        colors.redAccent[700],
                                                },
                                                whiteSpace: 'nowrap',
                                            }}
                                            variant="contained"
                                        >
                                            Close
                                        </Button>
                                    </Box>
                                )}
                            </form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddTeamModal;
