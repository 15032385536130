// useInspectionData.js
import { useEffect, useState, useCallback, useRef } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const useInspectionData = () => {
    const axiosPrivate = useAxiosPrivate();
    const [dropdownData, setDropdownData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const controllerRef = useRef(new AbortController());
    const [loading, setLoading] = useState(true);

    const fetchInspections = useCallback(async () => {
        try {
            const controller = new AbortController();
            controllerRef.current = controller;
            const response = await axiosPrivate.get('/inspection/inspections', {
                signal: controller.signal,
            });

            const sortedData = response.data.sort((a, b) => a.id - b.id);

            setDropdownData(sortedData);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setLoading(false);
            }
        }
    }, [axiosPrivate, location, navigate]);

    useEffect(() => {
        fetchInspections();
    }, [fetchInspections]);

    return { loading, dropdownData, fetchInspections };
};

export default useInspectionData;
