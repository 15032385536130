import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    TextField,
    useMediaQuery,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AddProjectModal = ({
    isAddModalOpen,
    setAddModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const handleSubmit = async (values) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.post(
                `/project/projects`,
                {
                    title: values.title,
                    description: values.description,
                },
                {
                    signal: controller.signal,
                }
            );

            setSnackbarMessage(`Created a new project successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            setAddModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                formik.handleSubmit();
                break;
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <Modal open={isAddModalOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '40%',
                    height: isMobile ? '55%' : '55%',
                    maxHeight: '90%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: isMobile ? 2 : 3,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                        mb={2}
                    >
                        {`Create a New Project`}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="filled"
                        label="Title"
                        value={formik.values.title}
                        name="title"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        multiline
                        rows={8}
                        variant="filled"
                        label="Description"
                        value={formik.values.description}
                        name="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                        sx={{ mb: 2 }}
                    />
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                mt: 2,
                                alignSelf: 'center',
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) => handleSelectAction(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select action' }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Submit">Submit</MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                onClick={formik.handleSubmit} // Call formik handleSubmit on click
                                type="submit"
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default AddProjectModal;
