import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    Select,
    MenuItem,
    IconButton,
    FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import useMediaQuery from '@mui/material/useMediaQuery';

const PaidModal = ({
    isPaidModalOpen,
    setPaidModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
    selectedInvoicesPerWarranty,
    setSelectedInvoicesPerWarranty,
    selectedInvoice,
    setSelectedInvoice,
    selectedWarranty,
    setSelectedWarranty,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlePaidInvoices = async (values) => {
        const controller = new AbortController();
        try {
            const paidValue = values.paid === 'Paid';
            if (selectedInvoice) {
                await axiosPrivate.patch(
                    `/warranty/warranty/${selectedWarranty.id}/invoice/${selectedInvoice.id}`,
                    {
                        paid: paidValue,
                    },
                    {
                        signal: controller.signal,
                    }
                );
                setSnackbarMessage(`Updated paid status for the invoice successfully`);
            } else {
                for (const invoiceIndex of selectedInvoicesPerWarranty[selectedWarranty.id]) {
                    await axiosPrivate.patch(
                        `/warranty/warranty/${selectedWarranty.id}/invoice/${invoiceIndex}`,
                        {
                            paid: paidValue,
                        },
                        {
                            signal: controller.signal,
                        }
                    );
                }
                setSnackbarMessage(
                    `Updated paid status for ${selectedInvoicesPerWarranty[selectedWarranty.id].length} invoice(s) successfully`
                );
            }

            setSnackbarType('success');
            setSnackbarOpen(true);

            setSelectedInvoicesPerWarranty({
                ...selectedInvoicesPerWarranty,
                [selectedWarranty.id]: [],
            });
            setSelectedWarranty(null);
            setSelectedInvoice(null);
            setPaidModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setSnackbarMessage(error.response?.data.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const getValidationSchema = yup.object().shape({
        paid: yup.string().required('Paid Status Required'),
    });

    const handleSelectAction = (action, handleSubmit) => {
        setSelectedAction(action);

        switch (action) {
            case 'Set Status':
                handleSubmit();
                break;
            case 'Close':
                setPaidModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal
            open={isPaidModalOpen}
            onClose={() => {
                setPaidModalOpen(false);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '40%' : '35%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '10%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setPaidModalOpen(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        height: '10%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        Set Paid Status
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Formik
                        onSubmit={handlePaidInvoices}
                        initialValues={{
                            paid: 'Paid',
                        }}
                        validationSchema={getValidationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form
                                id="submit-form"
                                style={{ height: '100%', width: '100%' }}
                                onSubmit={handleSubmit}
                            >
                                <Box
                                    display="grid"
                                    gap="10px"
                                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                    sx={{
                                        margin: '10% 15% 10% 15%',
                                        '& > div': {
                                            gridColumn: isNonMobile ? undefined : 'span 1',
                                        },
                                    }}
                                >
                                    <FormControl fullWidth variant="filled">
                                        <Select
                                            label="Paid"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.paid}
                                            name="paid"
                                            error={!!touched.paid && !!errors.paid}
                                            sx={{ gridColumn: 'span 1' }}
                                        >
                                            <MenuItem value="Paid">Paid</MenuItem>
                                            <MenuItem value="Not Paid">Not Paid</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                {isMobile ? (
                                    <FormControl
                                        sx={{
                                            width: '100%',
                                            borderRadius: '8px',
                                            mt: 2,
                                            alignSelf: 'center',
                                        }}
                                    >
                                        <Select
                                            value={selectedAction}
                                            onChange={(e) => handleSelectAction(e.target.value, handleSubmit)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Select action' }}
                                            sx={{
                                                color: colors.grey[100],
                                                backgroundColor: colors.primary[400],
                                                borderRadius: '8px',
                                                boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: colors.grey[300],
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: colors.grey[100],
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: colors.grey[100],
                                                },
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                Select an action
                                            </MenuItem>
                                            <MenuItem value="Set Status">Set Status</MenuItem>
                                            <MenuItem value="Close">Close</MenuItem>
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <Box
                                        sx={{
                                            bottom: '15%',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gap: theme.spacing(1),
                                            margin: 'auto',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                color: 'white',
                                                backgroundColor: colors.greenAccent[500],
                                                '&:hover': {
                                                    backgroundColor: colors.greenAccent[700],
                                                },
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            Set Status
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                setPaidModalOpen(false);
                                            }}
                                            sx={{
                                                color: 'white',
                                                backgroundColor: colors.redAccent[500],
                                                '&:hover': {
                                                    backgroundColor: colors.redAccent[700],
                                                },
                                                whiteSpace: 'nowrap',
                                            }}
                                            variant="contained"
                                        >
                                            Close
                                        </Button>
                                    </Box>
                                )}
                            </form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
};

export default PaidModal;
