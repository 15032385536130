import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    useMediaQuery,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularLoading from '../../global/CircularLoading';

const ViewDocumentModal = ({
    isViewDocumentModalOpen,
    setViewDocumentModalOpen,
    selectedDocument,
    setSelectedDocument,
    selectedAddress,
    setSelectedAddress,
    selectedProject,
    setSelectedProject,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [documentUrl, setDocumentUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const response = await axiosPrivate.get(
                    `/project/project/${selectedProject.id}/address/${selectedAddress.id}/document/${selectedDocument.id}/document`,
                );
                const documentUrl = response.data.url;
                setDocumentUrl(documentUrl);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login', {
                        state: { from: location },
                        replace: true,
                    });
                } else {
                    console.error('Error fetching document:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        if (isViewDocumentModalOpen) {
            fetchDocument();
        }

        return () => {
            // Clean up the document URL
            if (documentUrl) {
                URL.revokeObjectURL(documentUrl);
            }
        };
    }, [
        isViewDocumentModalOpen,
        selectedDocument,
        axiosPrivate,
        navigate,
        location,
        documentUrl,
        selectedAddress,
        selectedProject,
    ]);

    const handleClose = () => {
        setViewDocumentModalOpen(false);
        setSelectedDocument(null);
        setSelectedAddress(null);
        setSelectedProject(null);
    };

    return (
        <Modal open={isViewDocumentModalOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '60%',
                    height: isMobile ? '70%' : '80%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        textAlign: 'center',
                        px: 3,
                        py: 1,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        {`${selectedDocument?.title}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        width: '90%',
                        mx: 'auto',
                        mb: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: colors.primary[300],
                        borderRadius: '8px',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    }}
                >
                    {loading ? (
                        <CircularLoading isOpen={loading} />
                    ) : (
                        documentUrl && (
                            <iframe
                                src={documentUrl}
                                title="Document"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'white',
                                    borderRadius: '8px',
                                }}
                                allowFullScreen={true}
                            />
                        )
                    )}
                </Box>
                <Box
                    sx={{
                        height: '7%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2, // Added margin-bottom for spacing
                    }}
                >
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{
                            color: 'white',
                            backgroundColor: colors.redAccent[500],
                            '&:hover': {
                                backgroundColor: colors.redAccent[700],
                            },
                            whiteSpace: 'nowrap',
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ViewDocumentModal;
