// src/components/SetNewPassword/index.jsx
import React, { useContext, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    useTheme,
    Snackbar,
    Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../api/axios';
import { tokens } from '../../../../theme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PasswordResetContext } from '../../../../context/PasswordResetContext';

const PASSWORD_RESET_URL = '/auth/password-reset/reset';

const SetNewPassword = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const {
        resetContext,
        tempToken,
        resetMessage,
        resetMessageType,
        setResetMessage,
        setResetMessageType,
    } = useContext(PasswordResetContext);
    const [snackbarOpen, setSnackbarOpen] = useState(Boolean(resetMessage));

    useEffect(() => {
        if (resetMessage) {
            setSnackbarOpen(true);
        }
    }, [resetMessage]);

    const formik = useFormik({
        initialValues: {
            new_password: '',
            confirm_password: '',
        },
        validationSchema: Yup.object({
            new_password: Yup.string()
                .required('New password is required')
                .min(8, 'Password must be at least 8 characters long'),
            confirm_password: Yup.string()
                .required('Please confirm your password')
                .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
        }),
        onSubmit: async (values) => {
            try {
                await axios.post(
                    PASSWORD_RESET_URL,
                    JSON.stringify({
                        new_password: values.new_password,
                        confirm_password: values.confirm_password,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${tempToken}`,
                        },
                    }
                );
                // Set success message
                setResetMessage('Password has been reset successfully. You can now log in.');
                setResetMessageType('success');

                resetContext({ preserveMessage: true });

                // Navigate to login page
                navigate('/login');
            } catch (error) {
                setResetMessage('An error occurred while resetting your password.');
                setResetMessageType('error');
                setSnackbarOpen(true);
            }
        },
    });

    const handleSnackbarClose = (event, reason) => {
        setSnackbarOpen(false);
        setResetMessage('');
        setResetMessageType('');
    };

    useEffect(() => {
        if (tempToken === null) {
            navigate('/password-reset');
        }
    }, [tempToken, navigate]);

    // Handle the loading state
    if (tempToken === undefined) {
        // You can return a loading indicator here if you like
        return null;
    }

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: theme.spacing(8),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {resetMessage && (
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={resetMessageType}
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    resetMessageType === 'success'
                                        ? colors.greenAccent[600]
                                        : colors.redAccent[600],
                                color: 'white',
                            }}
                        >
                            <Typography variant="h6">{resetMessage}</Typography>
                        </Alert>
                    </Snackbar>
                )}
                <Box
                    sx={{
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        padding: theme.spacing(4),
                        width: '100%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4">
                        Reset Password
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        sx={{ mt: theme.spacing(2), width: '100%' }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="new_password"
                            label="New Password"
                            name="new_password"
                            type="password"
                            value={formik.values.new_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                            helperText={formik.touched.new_password && formik.errors.new_password}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="confirm_password"
                            label="Confirm Password"
                            name="confirm_password"
                            type="password"
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                            helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: theme.spacing(4),
                            }}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    bgcolor: colors.blueAccent[700],
                                    '&:hover': {
                                        bgcolor: colors.blueAccent[400],
                                    },
                                }}
                            >
                                Reset Password
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default SetNewPassword;
