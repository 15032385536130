import React, { useState, useEffect } from 'react';
import {
    Snackbar,
    Alert,
    Box,
    Typography,
    useTheme,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { tokens } from '../../theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Header from '../../components/Header';
import useAuth from '../../hooks/useAuth';
import useProjectEventData from '../../hooks/useProjectEventData';
import CircularLoading from '../global/CircularLoading';
import CustomFooter from './CustomFooter'; // Import CustomFooter component
import { styled } from '@mui/material/styles';
import { calculateShowAddToTeamButton } from '../people/RoleManager';
import TableHeaderCell from '../../components/TableHeaderCell';
import ViewProjectDescModal from './ViewProjectDescModal';
import ViewAddressDescriptionModal from './address/ViewAddressDescriptionModal';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';

import EditStatusModal from './event/EditStatusModal';
import AddEventModal from './event/AddEventModal';
import ViewEventDescriptionModal from './event/ViewEventDescriptionModal';
import DeleteEventModal from './event/DeleteEventModal';
import EditEventModal from './event/EditEventModal';
import ViewPeopleModal from './event/ViewPeopleModal';
import ViewTeamsModal from './event/ViewTeamsModal';

const Work = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { auth } = useAuth();
    const showAddToTeamButton = calculateShowAddToTeamButton(auth.roles);
    const { loading, dropdownData, fetchProjectsEvents } = useProjectEventData();
    const [selectedRows, setSelectedRows] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [page, setPage] = useState(0);
    const [pageAddress, setPageAddress] = useState({});
    const [pageEvent, setPageEvent] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowsPerPageAddress, setRowsPerPageAddress] = useState({});
    const defaultRowsPerPageAddress = 10;
    const [rowsPerPageEvent, setRowsPerPageEvent] = useState({});
    const defaultRowsPerPageEvent = 10;
    const [expandedProjectRows, setExpandedProjectRows] = useState({});
    const [expandedAddressRows, setExpandedAddressRows] = useState({});
    const [projectAddressMap, setProjectAddressMap] = useState({});
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedAddressesPerProject, setSelectedAddressesPerProject] =
        useState({});
    const [selectedEventsPerProject, setSelectedEventsPerProject] =
        useState({});


    // MODAL CONTROLS
    const [isDeleteEventModalOpen, setDeleteEventModalOpen] =
        useState(false);
    const [isViewEventDescModalOpen, setViewEventDescModalOpen] = useState(false);
    const [isEditEventModalOpen, setEditEventModalOpen] = useState(false);
    const [isAddEventModalOpen, setAddEventModalOpen] =
        useState(false);
    const [isEventPeopleModalOpen, setEventPeopleModalOpen] = useState(false);
    const [isEventTeamsModalOpen, setEventTeamsModalOpen] = useState(false);

    const [isViewDescriptionModalOpen, setViewDescriptionModalOpen] = useState(false);
    const [isViewAddDescModalOpen, setViewAddDescModalOpen] = useState(false);
    const [isEditEventStatusModalOpen, setEditEventStatusModalOpen] = useState(false);


    ////////////////////////////FILTER DATA///////////////////
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [filterOptions, setFilterOptions] = useState({
        column: '',     // Column to filter on
        value: '',      // Filter value
        operator: 'contains' // Default filter operator (e.g., 'contains', 'equals', etc.)
    });

    const [addOrder, setAddOrder] = useState('asc');
    const [addOrderBy, setAddOrderBy] = useState('id');
    const [addFilterOptions, setAddFilterOptions] = useState({
        column: '',     // Column to filter on
        value: '',      // Filter value
        operator: 'contains' // Default filter operator (e.g., 'contains', 'equals', etc.)
    });

    const [eventOrder, setEventOrder] = useState('asc');
    const [eventOrderBy, setEventOrderBy] = useState('id');
    const [eventFilterOptions, setEventFilterOptions] = useState({
        column: '',     // Column to filter on
        value: '',      // Filter value
        operator: 'contains' // Default filter operator (e.g., 'contains', 'equals', etc.)
    });

    const handleSelectActionEvent = (action, row, address) => {
        setSelectedProject(row); // Set the selected project
        setSelectedAddress(address); // Set the selected address
        switch (action) {
            case 'Add':
                setAddEventModalOpen(true);
                break;
            case 'Delete':
                setDeleteEventModalOpen(true);
                break;
            default:
                break;
        }
    };

    const handleFilter = (filterOptions) => {
        // Implement filter logic based on filterOptions
        setFilterOptions(filterOptions);
    };

    // Inside your table component
    const handleRequestSort = (direction, column) => {
        setOrder(direction);
        setOrderBy(column);
    };

    const handleAddFilter = (filterOptions) => {
        // Implement filter logic based on filterOptions
        setAddFilterOptions(filterOptions);
    };

    // Inside your table component
    const handleAddRequestSort = (direction, column) => {
        setAddOrder(direction);
        setAddOrderBy(column);
    };

    const handleEventFilter = (filterOptions) => {
        // Implement filter logic based on filterOptions
        setEventFilterOptions(filterOptions);
    };

    // Inside your table component
    const handleEventRequestSort = (direction, column) => {
        setEventOrder(direction);
        setEventOrderBy(column);
    };

    const filteredData = () => {
        let data = dropdownData;

        // Apply filter based on filterOptions
        if (filterOptions.column) {
            data = data.filter(row => {
                const value = getNestedValue(row, filterOptions.column);
                const filterValue = filterOptions.value ? filterOptions.value.toLowerCase() : '';

                switch (filterOptions.operator) {
                    case 'contains':
                        return value != null && value.toString().toLowerCase().includes(filterValue);
                    case 'equals':
                        return value != null && value.toString().toLowerCase() === filterValue;
                    case 'startsWith':
                        return value != null && value.toString().toLowerCase().startsWith(filterValue);
                    case 'endsWith':
                        return value != null && value.toString().toLowerCase().endsWith(filterValue);
                    case 'isEmpty':
                        return value === '' || value == null;
                    case 'isNotEmpty':
                        return value !== '' && value != null;
                    case 'isAnyOf':
                        return value != null && filterValue.split(',').map(v => v.trim()).includes(value.toString());
                    default:
                        return true;
                }
            });
        }

        // Sorting
        return data.sort(getComparator(order, orderBy));
    };

    const filteredRow = (rowData, filterOptions, orderData, orderDataBy) => {
        let data = rowData;

        // Apply filter based on filterOptions
        if (filterOptions.column) {
            data = data.filter(row => {
                const value = getNestedValue(row, filterOptions.column);
                const filterValue = filterOptions.value ? filterOptions.value.toLowerCase() : '';

                switch (filterOptions.operator) {
                    case 'contains':
                        return value != null && value.toString().toLowerCase().includes(filterValue);
                    case 'equals':
                        return value != null && value.toString().toLowerCase() === filterValue;
                    case 'startsWith':
                        return value != null && value.toString().toLowerCase().startsWith(filterValue);
                    case 'endsWith':
                        return value != null && value.toString().toLowerCase().endsWith(filterValue);
                    case 'isEmpty':
                        return value === '' || value == null;
                    case 'isNotEmpty':
                        return value !== '' && value != null;
                    case 'isAnyOf':
                        return value != null && filterValue.split(',').map(v => v.trim()).includes(value.toString());
                    default:
                        return true;
                }
            });
        }

        // Sorting
        return data.sort(getComparator(orderData, orderDataBy));
    };


    // Utility function to access nested properties
    function getNestedValue(obj, path) {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    function descendingComparator(a, b, orderBy) {
        const aValue = getNestedValue(a, orderBy);
        const bValue = getNestedValue(b, orderBy);

        // Handle null or undefined values
        if (aValue === null || aValue === undefined) {
            return (bValue === null || bValue === undefined) ? 0 : -1;
        }
        if (bValue === null || bValue === undefined) {
            return 1;
        }

        if (bValue < aValue) {
            return -1;
        }
        if (bValue > aValue) {
            return 1;
        }
        return 0;
    }


    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    //////////////////////////////////////////////////////////

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (snackbarOpen) {
            fetchProjectsEvents();
        }

        const closeSnackbarOnOutsideClick = (event) => {
            if (snackbarOpen && !event.target.closest('.MuiAlert-root')) {
                setSnackbarOpen(false);
            }
        };

        document.addEventListener('click', closeSnackbarOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeSnackbarOnOutsideClick);
        };
    }, [snackbarOpen, fetchProjectsEvents]);

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    ////////////////////////////////////////////////////////////////////////////////////
    // Modify checkbox handling functions

    const handleCheckboxChangeEvent = (
        event,
        eventId,
        addressId,
        projectId
    ) => {
        // Get the selected events for the specified project and address or initialize it as an empty array
        const selectedEventsForProject =
            selectedEventsPerProject[projectId] || {};
        const selectedEventsForAddress =
            selectedEventsForProject[addressId] || [];

        // Check if the eventId exists in the selected events for the address
        const index = selectedEventsForAddress.indexOf(eventId);

        // If the eventId doesn't exist, add it; otherwise, remove it
        const updatedEventsForAddress =
            index === -1
                ? [...selectedEventsForAddress, eventId]
                : selectedEventsForAddress.filter((id) => id !== eventId);

        // Update the selected events for the specified project and address
        setSelectedEventsPerProject({
            ...selectedEventsPerProject,
            [projectId]: {
                ...selectedEventsForProject,
                [addressId]: updatedEventsForAddress,
            },
        });
    };

    const handleSelectAllClickEvent = (event, row, address) => {
        if (event.target.checked) {
            const eventIds = address.events.map(
                (event) => event.id
            );
            setSelectedEventsPerProject({
                ...selectedEventsPerProject,
                [row.id]: {
                    ...selectedEventsPerProject[row.id],
                    [address.id]: eventIds,
                },
            });
        } else {
            setSelectedEventsPerProject({
                ...selectedEventsPerProject,
                [row.id]: {
                    ...selectedEventsPerProject[row.id],
                    [address.id]: [],
                },
            });
        }
    };

    const isAddressSelected = (id, projectId) => {
        const selectedAddressesForProject =
            selectedAddressesPerProject[projectId] || [];
        return selectedAddressesForProject.indexOf(id) !== -1;
    };

    const isEventSelected = (id, projectId, addressId) => {
        const selectedEventsForProject =
            selectedEventsPerProject[projectId]?.[addressId] || [];
        return selectedEventsForProject.indexOf(id) !== -1;
    };

    ////////////////////////////////////////////////////////////////////////////////////
    // Function to toggle expansion of project rows
    const toggleProjectRowExpansion = (projectId) => {
        setExpandedProjectRows((prevExpandedProjectRows) => {
            const newState = !prevExpandedProjectRows[projectId];
            const updatedProjectRows = {
                ...prevExpandedProjectRows,
                [projectId]: newState,
            };

            // If collapsing, collapse associated address rows
            if (!newState) {
                const addressesToCollapse = projectAddressMap[projectId] || [];
                addressesToCollapse.forEach((addressId) => {
                    setExpandedAddressRows((prevExpandedAddressRows) => ({
                        ...prevExpandedAddressRows,
                        [addressId]: false,
                    }));
                });
                // Remove collapsed addresses from projectAddressMap
                setProjectAddressMap((prevMap) => {
                    const updatedMap = { ...prevMap };
                    delete updatedMap[projectId];
                    return updatedMap;
                });
            }
            return updatedProjectRows;
        });
    };

    // Function to toggle expansion of address rows
    const toggleAddressRowExpansion = (projectId, addressId) => {
        setExpandedAddressRows((prevExpandedAddressRows) => {
            const newState = !prevExpandedAddressRows[addressId];
            const updatedAddressRows = {
                ...prevExpandedAddressRows,
                [addressId]: newState,
            };

            // If expanding, add address to projectAddressMap
            if (newState) {
                setProjectAddressMap((prevMap) => ({
                    ...prevMap,
                    [projectId]: [...(prevMap[projectId] || []), addressId],
                }));
            }

            return updatedAddressRows;
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangePageAddress = (projectId) => (event, newPage) => {
        setPageAddress((prev) => ({ ...prev, [projectId]: newPage }));
    };

    const handleChangePageEvent = (projectId, addressId) => (event, newPage) => {
        setPageEvent((prev) => ({
          ...prev,
          [projectId]: {
            ...(prev[projectId] || {}),
            [addressId]: newPage,
          },
        }));
      };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeRowsPerPageAddress = (projectId) => (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPageAddress((prev) => ({ ...prev, [projectId]: newRowsPerPage }));
        setPageAddress((prev) => ({ ...prev, [projectId]: 0 }));
    };

    const handleChangeRowsPerPageEvent = (projectId, addressId) => (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPageEvent((prev) => ({
          ...prev,
          [projectId]: {
            ...(prev[projectId] || {}),
            [addressId]: newRowsPerPage,
          },
        }));
        setPageEvent((prev) => ({
          ...prev,
          [projectId]: {
            ...(prev[projectId] || {}),
            [addressId]: 0,
          },
        }));
    };

    const handleViewProjectDescClick = (id) => {
        const selectedReportProject = dropdownData.find(
            (project) => project.id === id
        );
        setSelectedProject(selectedReportProject);
        setViewDescriptionModalOpen(true);
    };

    // Then use StyledTableCell in your component

    const handleEditEventClick = () => {
        setEditEventModalOpen(true);
    };

    const handleEditEventStatusClick = () => {
        setEditEventStatusModalOpen(true);
    };

    const handleViewTeamClick = () => {
        setEventTeamsModalOpen(true);
    };

    const handleViewPeopleClick = () => {
        setEventPeopleModalOpen(true);
    };

    const handleViewEventDescriptionClick = () => {
        setViewEventDescModalOpen(true);
    };

    const handleViewAddDescClick = (id) => {
        setViewAddDescModalOpen(true);
    };

    const resetValues = (event) => {
        setExpandedProjectRows({});
        setExpandedAddressRows({});
        setProjectAddressMap({});
        setPage(0);
        setPageAddress({});
        setPageEvent({});
        setSelectedRows([]);
        setSelectedAddressesPerProject({});
        setSelectedEventsPerProject({});
    };

    const someEventsSelectedInCurrentProject = (row, address) => {
        const selectedEventsForProject =
            selectedEventsPerProject[row.id]?.[address.id] || [];
        return selectedEventsForProject.length > 0;
    };

    const allEventsSelectedInCurrentProject = (row, address) => {
        const selectedEventsForProject =
            selectedEventsPerProject[row.id]?.[address.id] || [];
        return selectedEventsForProject.length === address.events.length;
    };

    const columns = [
        { columnHeaderName: 'ID', column: 'id', sortable: true, center: false },
        { columnHeaderName: 'Title', column: 'title', sortable: true, center: false },
        { columnHeaderName: 'Description', column: 'description', sortable: true, center: false },
    ];

    const addColumns = [
        { columnHeaderName: 'ID', column: 'id', sortable: true, center: false },
        { columnHeaderName: 'Title', column: 'title', sortable: true, center: false },
        { columnHeaderName: 'Description', column: 'description', sortable: true, center: false },
    ];

    const eventColumns = [
        { columnHeaderName: 'ID', column: 'id', sortable: true, center: false },
        { columnHeaderName: 'Title', column: 'title', sortable: true, center: false },
        { columnHeaderName: 'Description', column: 'description', sortable: true, center: false },
        { columnHeaderName: 'Start Date', column: 'start_date', sortable: true, center: false },
        { columnHeaderName: 'End Date', column: 'end_date', sortable: true, center: true },
        { columnHeaderName: 'Type', column: 'type', sortable: true, center: true },
        { columnHeaderName: 'Status', column: 'status', sortable: true, center: true },
        { columnHeaderName: 'People', column: 'people', sortable: false, center: true },
        { columnHeaderName: 'Teams', column: 'teams', sortable: false, center: true },
    ];

    return (
        <Box m="20px">
            <Header title="EVENT" subtitle="Managing the Events" />
            {loading ? (
                <CircularLoading isOpen={loading} />
            ) : (
                <>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{
                            vertical: 'top', // Set the vertical position to 'top'
                            horizontal: 'center', // Center horizontally
                        }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={
                                snackbarType
                                    ? snackbarType === 'success'
                                        ? 'success'
                                        : snackbarType === 'warning'
                                          ? 'warning'
                                          : 'error'
                                    : 'info'
                            }
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    snackbarType === 'success'
                                        ? colors.greenAccent[600]
                                        : snackbarType === 'warning'
                                          ? '#FFFF8F'
                                          : colors.redAccent[600],
                                color:
                                    snackbarType === 'warning'
                                        ? colors.primary[300]
                                        : colors.grey[100],
                            }}
                        >
                            <Typography
                                variant="h6"
                                color={
                                    snackbarType === 'warning'
                                        ? colors.primary[300]
                                        : colors.grey[100]
                                }
                            >
                                {snackbarMessage}
                            </Typography>
                        </Alert>
                    </Snackbar>
                    <Box
                        m="40px 0 0 0"
                        height="65vh"
                        sx={{
                            '& .MuiTableHead-root': {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: 'none',
                            },
                            '& .MuiTablePagination-root': {
                                borderTop: 'none',
                                backgroundColor: colors.blueAccent[700],
                            },
                            '& .MuiCheckbox-root': {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        <TableContainer
                            sx={{
                                height: '100%',
                                backgroundColor: colors.primary[400],
                                borderRadius: '5px',
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>{' '}
                                        {/* New cell for the arrow icon */}
                                        {columns.map((column) => (
                                            <TableHeaderCell
                                                key={column.column}
                                                columns={columns}
                                                column={column.column}
                                                columnHeaderName={column.columnHeaderName}
                                                order={order}
                                                orderBy={orderBy}
                                                handleRequestSort={handleRequestSort}
                                                handleFilter={handleFilter}
                                                sortable={column.sortable}
                                                center={column.center}
                                            />
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData()
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => {
                                            const isItemSelected = isSelected(
                                                row.id
                                            );
                                            const isExpandedProject =
                                                expandedProjectRows[row.id]; // Check project expansion
                                            return (
                                                <React.Fragment key={row.id}>
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={
                                                            isItemSelected
                                                        }
                                                        tabIndex={-1}
                                                        selected={
                                                            isItemSelected
                                                        }
                                                        sx={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <TableCell
                                                            onClick={() =>
                                                                toggleProjectRowExpansion(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {isExpandedProject ? (
                                                                <ExpandLessIcon />
                                                            ) : (
                                                                <ExpandMoreIcon />
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                        >
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.title}
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={() =>
                                                                handleViewProjectDescClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {row
                                                                .description
                                                                .length >
                                                            30
                                                                ? `${row.description.substring(0, 30)}...`
                                                                : row.description}
                                                        </TableCell>
                                                    </TableRow>
                                                    {isExpandedProject && (
                                                        <>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                        paddingTop: 0,
                                                                    }}
                                                                    colSpan={10}
                                                                >
                                                                    <Accordion>
                                                                        <AccordionSummary
                                                                            expandIcon={
                                                                                <ExpandMoreIcon />
                                                                            }
                                                                            aria-controls="panel2a-content"
                                                                            id="panel2a-header"
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    colors
                                                                                        .primary[400],
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                Addresses
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    colors
                                                                                        .primary[400],
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                // height="70vh"
                                                                                sx={{
                                                                                    '& .MuiTableHead-root':
                                                                                        {
                                                                                            backgroundColor:
                                                                                                colors
                                                                                                    .blueAccent[500],
                                                                                            borderBottom:
                                                                                                'none',
                                                                                        },
                                                                                    '& .MuiTablePagination-root':
                                                                                        {
                                                                                            borderTop:
                                                                                                'none',
                                                                                            backgroundColor:
                                                                                                colors
                                                                                                    .blueAccent[500],
                                                                                        },
                                                                                    '& .MuiCheckbox-root':
                                                                                        {
                                                                                            color: `${colors.greenAccent[200]} !important`,
                                                                                        },
                                                                                }}
                                                                            >
                                                                                <TableContainer
                                                                                    sx={{
                                                                                        height: '100%',
                                                                                        backgroundColor:
                                                                                            colors
                                                                                                .primary[400],
                                                                                        borderRadius:
                                                                                            '5px',
                                                                                    }}
                                                                                >
                                                                                    <Table>
                                                                                    <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell></TableCell>{' '}
                                                                                                {/* New cell for the arrow icon */}
                                                                                                {addColumns.map((column) => (
                                                                                                    <TableHeaderCell
                                                                                                        key={column.column}
                                                                                                        columns={addColumns}
                                                                                                        column={column.column}
                                                                                                        columnHeaderName={column.columnHeaderName}
                                                                                                        order={addOrder}
                                                                                                        orderBy={addOrderBy}
                                                                                                        handleRequestSort={handleAddRequestSort}
                                                                                                        handleFilter={handleAddFilter}
                                                                                                        sortable={column.sortable}
                                                                                                        center={column.center}
                                                                                                    />
                                                                                                ))}
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {filteredRow(row.addresses, addFilterOptions, addOrder, addOrderBy)
                                                                                                .slice(
                                                                                                    (pageAddress[row.id] || 0) * (rowsPerPageAddress[row.id] || defaultRowsPerPageAddress),
                                                                                                    ((pageAddress[row.id] || 0) + 1) * (rowsPerPageAddress[row.id] || defaultRowsPerPageAddress)
                                                                                                  )
                                                                                                .map(
                                                                                                    (
                                                                                                        address
                                                                                                    ) => {
                                                                                                        const isItemSelected =
                                                                                                            isAddressSelected(
                                                                                                                address.id,
                                                                                                                row.id
                                                                                                            );
                                                                                                        const isExpandedAddress =
                                                                                                            expandedAddressRows[
                                                                                                                address
                                                                                                                    .id
                                                                                                            ]; // Check address expansion
                                                                                                        return (
                                                                                                            <React.Fragment
                                                                                                                key={
                                                                                                                    address.id
                                                                                                                }
                                                                                                            >
                                                                                                                <TableRow
                                                                                                                    hover
                                                                                                                    role="checkbox"
                                                                                                                    aria-checked={
                                                                                                                        isItemSelected
                                                                                                                    }
                                                                                                                    tabIndex={
                                                                                                                        -1
                                                                                                                    }
                                                                                                                    selected={
                                                                                                                        isItemSelected
                                                                                                                    }
                                                                                                                    sx={{
                                                                                                                        cursor: 'pointer',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <TableCell
                                                                                                                        onClick={() =>
                                                                                                                            toggleAddressRowExpansion(
                                                                                                                                row.id,
                                                                                                                                address.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {isExpandedAddress ? (
                                                                                                                            <ExpandLessIcon />
                                                                                                                        ) : (
                                                                                                                            <ExpandMoreIcon />
                                                                                                                        )}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {
                                                                                                                            address.id
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {
                                                                                                                            address.title
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        onClick={() => {
                                                                                                                            setSelectedAddress(
                                                                                                                                address
                                                                                                                            );
                                                                                                                            handleViewAddDescClick(row.id);
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {address
                                                                                                                            .description
                                                                                                                            .length >
                                                                                                                        30
                                                                                                                            ? `${address.description.substring(0, 30)}...`
                                                                                                                            : address.description}
                                                                                                                    </TableCell>
                                                                                                                </TableRow>
                                                                                                                {isExpandedAddress && (
                                                                                                                    <>
                                                                                                                        <TableRow>
                                                                                                                            <TableCell
                                                                                                                                style={{
                                                                                                                                    paddingBottom: 0,
                                                                                                                                    paddingTop: 0,
                                                                                                                                }}
                                                                                                                                colSpan={
                                                                                                                                    10
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Accordion>
                                                                                                                                    <AccordionSummary
                                                                                                                                        expandIcon={
                                                                                                                                            <ExpandMoreIcon />
                                                                                                                                        }
                                                                                                                                        aria-controls="panel1a-content"
                                                                                                                                        id="panel1a-header"
                                                                                                                                        sx={{
                                                                                                                                            backgroundColor:
                                                                                                                                                colors
                                                                                                                                                    .primary[400],
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Typography>
                                                                                                                                            Events
                                                                                                                                        </Typography>
                                                                                                                                    </AccordionSummary>
                                                                                                                                    <AccordionDetails
                                                                                                                                        sx={{
                                                                                                                                            backgroundColor:
                                                                                                                                                colors
                                                                                                                                                    .primary[400],
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Box
                                                                                                                                            // height="70vh"
                                                                                                                                            sx={{
                                                                                                                                                '& .MuiTableHead-root':
                                                                                                                                                    {
                                                                                                                                                        backgroundColor:
                                                                                                                                                            colors
                                                                                                                                                                .blueAccent[400],
                                                                                                                                                        borderBottom:
                                                                                                                                                            'none',
                                                                                                                                                    },
                                                                                                                                                '& .MuiTablePagination-root':
                                                                                                                                                    {
                                                                                                                                                        borderTop:
                                                                                                                                                            'none',
                                                                                                                                                        backgroundColor:
                                                                                                                                                            colors
                                                                                                                                                                .blueAccent[400],
                                                                                                                                                    },
                                                                                                                                                '& .MuiCheckbox-root':
                                                                                                                                                    {
                                                                                                                                                        color: `${colors.greenAccent[200]} !important`,
                                                                                                                                                    },
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <TableContainer
                                                                                                                                                sx={{
                                                                                                                                                    height: '100%',
                                                                                                                                                    backgroundColor:
                                                                                                                                                        colors
                                                                                                                                                            .primary[400],
                                                                                                                                                    borderRadius:
                                                                                                                                                        '5px',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Table>
                                                                                                                                                    <TableHead>
                                                                                                                                                        <TableRow>
                                                                                                                                                            {/* <TableCell></TableCell> New cell for the arrow icon */}
                                                                                                                                                            <TableCell padding="checkbox">
                                                                                                                                                                <Checkbox
                                                                                                                                                                    color="primary"
                                                                                                                                                                    indeterminate={
                                                                                                                                                                        someEventsSelectedInCurrentProject(
                                                                                                                                                                            row,
                                                                                                                                                                            address
                                                                                                                                                                        ) &&
                                                                                                                                                                        !allEventsSelectedInCurrentProject(
                                                                                                                                                                            row,
                                                                                                                                                                            address
                                                                                                                                                                        )
                                                                                                                                                                    }
                                                                                                                                                                    checked={allEventsSelectedInCurrentProject(
                                                                                                                                                                        row,
                                                                                                                                                                        address
                                                                                                                                                                    )}
                                                                                                                                                                    onChange={(
                                                                                                                                                                        event
                                                                                                                                                                    ) =>
                                                                                                                                                                        handleSelectAllClickEvent(
                                                                                                                                                                            event,
                                                                                                                                                                            row,
                                                                                                                                                                            address
                                                                                                                                                                        )
                                                                                                                                                                    }
                                                                                                                                                                    inputProps={{
                                                                                                                                                                        'aria-label':
                                                                                                                                                                            'select all events',
                                                                                                                                                                    }}
                                                                                                                                                                />
                                                                                                                                                            </TableCell>
                                                                                                                                                            {eventColumns.map((column) => (
                                                                                                                                                                <TableHeaderCell
                                                                                                                                                                    key={column.column}
                                                                                                                                                                    columns={eventColumns}
                                                                                                                                                                    column={column.column}
                                                                                                                                                                    columnHeaderName={column.columnHeaderName}
                                                                                                                                                                    order={eventOrder}
                                                                                                                                                                    orderBy={eventOrderBy}
                                                                                                                                                                    handleRequestSort={handleEventRequestSort}
                                                                                                                                                                    handleFilter={handleEventFilter}
                                                                                                                                                                    sortable={column.sortable}
                                                                                                                                                                    center={column.center}
                                                                                                                                                                />
                                                                                                                                                            ))}
                                                                                                                                                        </TableRow>
                                                                                                                                                    </TableHead>
                                                                                                                                                    <TableBody>
                                                                                                                                                        {filteredRow(address.events, eventFilterOptions, eventOrder, eventOrderBy)
                                                                                                                                                            .slice(
                                                                                                                                                                (pageEvent[row.id]?.[address.id] || 0) * (rowsPerPageEvent[row.id]?.[address.id] || defaultRowsPerPageEvent),
                                                                                                                                                                ((pageEvent[row.id]?.[address.id] || 0) + 1) * (rowsPerPageEvent[row.id]?.[address.id] || defaultRowsPerPageEvent)
                                                                                                                                                            )
                                                                                                                                                            .map(
                                                                                                                                                                (
                                                                                                                                                                    eventrow
                                                                                                                                                                ) => {
                                                                                                                                                                    const isItemSelected =
                                                                                                                                                                        isEventSelected(
                                                                                                                                                                            eventrow.id,
                                                                                                                                                                            row.id,
                                                                                                                                                                            address.id
                                                                                                                                                                        );
                                                                                                                                                                    // const isExpanded = expandedRows[address.id];
                                                                                                                                                                    return (
                                                                                                                                                                        <React.Fragment
                                                                                                                                                                            key={
                                                                                                                                                                                eventrow.id
                                                                                                                                                                            }
                                                                                                                                                                        >
                                                                                                                                                                            <TableRow
                                                                                                                                                                                hover
                                                                                                                                                                                role="checkbox"
                                                                                                                                                                                aria-checked={
                                                                                                                                                                                    isItemSelected
                                                                                                                                                                                }
                                                                                                                                                                                tabIndex={
                                                                                                                                                                                    -1
                                                                                                                                                                                }
                                                                                                                                                                                selected={
                                                                                                                                                                                    isItemSelected
                                                                                                                                                                                }
                                                                                                                                                                                sx={{
                                                                                                                                                                                    cursor: 'pointer',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                {/* <TableCell onClick={() => toggleRowExpansion(address.id)}>
                                                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                      </TableCell> */}
                                                                                                                                                                                <TableCell padding="checkbox">
                                                                                                                                                                                    <Checkbox
                                                                                                                                                                                        color="primary"
                                                                                                                                                                                        checked={
                                                                                                                                                                                            isItemSelected
                                                                                                                                                                                        }
                                                                                                                                                                                        onChange={(
                                                                                                                                                                                            event
                                                                                                                                                                                        ) =>
                                                                                                                                                                                            handleCheckboxChangeEvent(
                                                                                                                                                                                                event,
                                                                                                                                                                                                eventrow.id,
                                                                                                                                                                                                address.id,
                                                                                                                                                                                                row.id
                                                                                                                                                                                            )
                                                                                                                                                                                        }
                                                                                                                                                                                        inputProps={{
                                                                                                                                                                                            'aria-labelledby': `checkbox-${eventrow.id}`,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell
                                                                                                                                                                                    onClick={() => {
                                                                                                                                                                                        showAddToTeamButton && setSelectedProject(
                                                                                                                                                                                            row
                                                                                                                                                                                        );
                                                                                                                                                                                        showAddToTeamButton && setSelectedEvent(
                                                                                                                                                                                            eventrow
                                                                                                                                                                                        );
                                                                                                                                                                                        showAddToTeamButton && setSelectedAddress(
                                                                                                                                                                                            address
                                                                                                                                                                                        );
                                                                                                                                                                                        showAddToTeamButton && handleEditEventClick();
                                                                                                                                                                                    }}>
                                                                                                                                                                                    {
                                                                                                                                                                                        eventrow.id
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell
                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                        showAddToTeamButton && setSelectedProject(
                                                                                                                                                                                            row
                                                                                                                                                                                        );
                                                                                                                                                                                        showAddToTeamButton && setSelectedEvent(
                                                                                                                                                                                            eventrow
                                                                                                                                                                                        );
                                                                                                                                                                                        showAddToTeamButton && setSelectedAddress(
                                                                                                                                                                                            address
                                                                                                                                                                                        );
                                                                                                                                                                                        showAddToTeamButton && handleEditEventClick();
                                                                                                                                                                                    }}>
                                                                                                                                                                                    {
                                                                                                                                                                                        eventrow.title
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell
                                                                                                                                                                                    onClick={() => {
                                                                                                                                                                                        setSelectedEvent(
                                                                                                                                                                                            eventrow
                                                                                                                                                                                        );
                                                                                                                                                                                        handleViewEventDescriptionClick();
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    {eventrow
                                                                                                                                                                                        .description
                                                                                                                                                                                        .length >
                                                                                                                                                                                    30
                                                                                                                                                                                        ? `${eventrow.description.substring(0, 30)}...`
                                                                                                                                                                                        : eventrow.description}
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell
                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                    showAddToTeamButton && setSelectedProject(
                                                                                                                                                                                        row
                                                                                                                                                                                    );
                                                                                                                                                                                    showAddToTeamButton && setSelectedEvent(
                                                                                                                                                                                        eventrow
                                                                                                                                                                                    );
                                                                                                                                                                                    showAddToTeamButton && setSelectedAddress(
                                                                                                                                                                                        address
                                                                                                                                                                                    );
                                                                                                                                                                                    showAddToTeamButton && handleEditEventClick();
                                                                                                                                                                                }}>
                                                                                                                                                                                    {
                                                                                                                                                                                        eventrow.start_date
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell
                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                    showAddToTeamButton && setSelectedProject(
                                                                                                                                                                                        row
                                                                                                                                                                                    );
                                                                                                                                                                                    showAddToTeamButton && setSelectedEvent(
                                                                                                                                                                                        eventrow
                                                                                                                                                                                    );
                                                                                                                                                                                    showAddToTeamButton && setSelectedAddress(
                                                                                                                                                                                        address
                                                                                                                                                                                    );
                                                                                                                                                                                    showAddToTeamButton && handleEditEventClick();
                                                                                                                                                                                }}>
                                                                                                                                                                                    {
                                                                                                                                                                                        eventrow.end_date
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell
                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                    showAddToTeamButton && setSelectedProject(
                                                                                                                                                                                        row
                                                                                                                                                                                    );
                                                                                                                                                                                    showAddToTeamButton && setSelectedEvent(
                                                                                                                                                                                        eventrow
                                                                                                                                                                                    );
                                                                                                                                                                                    showAddToTeamButton && setSelectedAddress(
                                                                                                                                                                                        address
                                                                                                                                                                                    );
                                                                                                                                                                                    showAddToTeamButton && handleEditEventClick();
                                                                                                                                                                                }}>
                                                                                                                                                                                    {
                                                                                                                                                                                        eventrow.work_type
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell
                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                    setSelectedProject(
                                                                                                                                                                                        row
                                                                                                                                                                                    );
                                                                                                                                                                                    setSelectedEvent(
                                                                                                                                                                                        eventrow
                                                                                                                                                                                    );
                                                                                                                                                                                    setSelectedAddress(
                                                                                                                                                                                        address
                                                                                                                                                                                    );
                                                                                                                                                                                    handleEditEventStatusClick();
                                                                                                                                                                                }}>
                                                                                                                                                                                    {
                                                                                                                                                                                        eventrow.status
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell align="center">
                                                                                                                                                                                    {
                                                                                                                                                                                        <Button
                                                                                                                                                                                            variant="contained"
                                                                                                                                                                                            sx={{
                                                                                                                                                                                                color: 'white',
                                                                                                                                                                                                backgroundColor:
                                                                                                                                                                                                    colors
                                                                                                                                                                                                        .greenAccent[500],
                                                                                                                                                                                                '&:hover':
                                                                                                                                                                                                    {
                                                                                                                                                                                                        backgroundColor:
                                                                                                                                                                                                            colors
                                                                                                                                                                                                                .greenAccent[700],
                                                                                                                                                                                                    },
                                                                                                                                                                                            }}
                                                                                                                                                                                            startIcon={
                                                                                                                                                                                                <PersonIcon />
                                                                                                                                                                                            }
                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                setSelectedEvent(
                                                                                                                                                                                                    eventrow
                                                                                                                                                                                                );
                                                                                                                                                                                                handleViewPeopleClick();
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            People
                                                                                                                                                                                        </Button>
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell align="center">
                                                                                                                                                                                    {
                                                                                                                                                                                        <Button
                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                            sx={{
                                                                                                                                                                                                color: colors
                                                                                                                                                                                                    .grey[100],
                                                                                                                                                                                                borderColor:
                                                                                                                                                                                                    colors
                                                                                                                                                                                                        .grey[100],
                                                                                                                                                                                            }}
                                                                                                                                                                                            startIcon={
                                                                                                                                                                                                <PeopleIcon />
                                                                                                                                                                                            }
                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                setSelectedEvent(
                                                                                                                                                                                                    eventrow
                                                                                                                                                                                                );
                                                                                                                                                                                                handleViewTeamClick();
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            Teams
                                                                                                                                                                                        </Button>
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                            </TableRow>
                                                                                                                                                                        </React.Fragment>
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                            )}
                                                                                                                                                    </TableBody>
                                                                                                                                                </Table>
                                                                                                                                            </TableContainer>
                                                                                                                                            <CustomFooter
                                                                                                                                                rowCount={
                                                                                                                                                    address
                                                                                                                                                        .events
                                                                                                                                                        .length
                                                                                                                                                }
                                                                                                                                                page={pageEvent[row.id]?.[address.id] || 0}
                                                                                                                                                rowsPerPage={rowsPerPageEvent[row.id]?.[address.id] || defaultRowsPerPageEvent}
                                                                                                                                                onPageChange={handleChangePageEvent(row.id, address.id)}
                                                                                                                                                onRowsPerPageChange={handleChangeRowsPerPageEvent(row.id, address.id)}
                                                                                                                                                backgroundColor={
                                                                                                                                                    colors
                                                                                                                                                        .blueAccent[400]
                                                                                                                                                }
                                                                                                                                                onActionSelect={(action) => showAddToTeamButton && handleSelectActionEvent(action, row, address)}
                                                                                                                                                row={row} // Pass row as prop if available
                                                                                                                                                address={address} // Pass address as prop if available
                                                                                                                                                isOuterTable={false}
                                                                                                                                                buttons={showAddToTeamButton && [
                                                                                                                                                    {
                                                                                                                                                        label: 'Add',
                                                                                                                                                        onClick: () => {
                                                                                                                                                            setSelectedProject(row);  // Ensure selected project is set
                                                                                                                                                            setSelectedAddress(address);  // Ensure selected address is set
                                                                                                                                                            setAddEventModalOpen(true);  // Open AddAttachEventModal
                                                                                                                                                        },
                                                                                                                                                        styles: {
                                                                                                                                                            color: 'white',
                                                                                                                                                            backgroundColor:
                                                                                                                                                                colors.greenAccent[500],
                                                                                                                                                            '&:hover': {
                                                                                                                                                                backgroundColor:
                                                                                                                                                                    colors.greenAccent[700],
                                                                                                                                                            },
                                                                                                                                                            mr: 1,
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        label: 'Delete',
                                                                                                                                                        onClick: () => {
                                                                                                                                                            setSelectedProject(row);  // Ensure selected project is set
                                                                                                                                                            setSelectedAddress(address);  // Ensure selected address is set
                                                                                                                                                            setDeleteEventModalOpen(true);  // Open DeleteEventModal
                                                                                                                                                        },
                                                                                                                                                        styles: {
                                                                                                                                                            color: 'white',
                                                                                                                                                            backgroundColor: colors.redAccent[500],
                                                                                                                                                            '&:hover': {
                                                                                                                                                                backgroundColor:
                                                                                                                                                                    colors.redAccent[700],
                                                                                                                                                            },
                                                                                                                                                            mr: 1,
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        label: 'Reset',
                                                                                                                                                        onClick: () => resetValues(),
                                                                                                                                                        styles: {
                                                                                                                                                            color: 'white',
                                                                                                                                                            backgroundColor: colors.redAccent[500],
                                                                                                                                                            '&:hover': {
                                                                                                                                                                backgroundColor:
                                                                                                                                                                    colors.redAccent[700],
                                                                                                                                                            },
                                                                                                                                                            mr: 1,
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                ]}
                                                                                                                                            />
                                                                                                                                        </Box>
                                                                                                                                    </AccordionDetails>
                                                                                                                                </Accordion>
                                                                                                                            </TableCell>
                                                                                                                        </TableRow>
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </React.Fragment>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                                <CustomFooter
                                                                                    rowCount={
                                                                                        row
                                                                                            .addresses
                                                                                            .length
                                                                                    }
                                                                                    page={pageAddress[row.id] || 0}
                                                                                    rowsPerPage={rowsPerPageAddress[row.id] || defaultRowsPerPageAddress}
                                                                                    onPageChange={handleChangePageAddress(row.id)}
                                                                                    onRowsPerPageChange={handleChangeRowsPerPageAddress(row.id)}
                                                                                    backgroundColor={
                                                                                        colors
                                                                                            .blueAccent[500]
                                                                                    }
                                                                                    row={row} // Pass row as prop if available
                                                                                    isOuterTable={false}
                                                                                />
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomFooter
                            rowCount={dropdownData.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            backgroundColor={colors.blueAccent[700]}
                            isOuterTable={true}
                        />
                        <ViewEventDescriptionModal
                            isViewEventDescModalOpen={
                                isViewEventDescModalOpen
                            }
                            setViewEventDescModalOpen={
                                setViewEventDescModalOpen
                            }
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <DeleteEventModal
                            isDeleteEventModalOpen={
                                isDeleteEventModalOpen
                            }
                            setDeleteEventModalOpen={
                                setDeleteEventModalOpen
                            }
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            selectedAddress={selectedAddress}
                            setSelectedAddress={setSelectedAddress}
                            selectedRowsEvent={selectedEventsPerProject}
                            setSelectedRowsEvent={
                                setSelectedEventsPerProject
                            }
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <EditEventModal
                            isEditEventModalOpen={isEditEventModalOpen}
                            setEditEventModalOpen={setEditEventModalOpen}
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                            selectedAddress={selectedAddress}
                            setSelectedAddress={setSelectedAddress}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <AddEventModal
                            isAddEventModalOpen={
                                isAddEventModalOpen
                            }
                            setAddEventModalOpen={
                                setAddEventModalOpen
                            }
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            selectedAddress={selectedAddress}
                            setSelectedAddress={setSelectedAddress}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <ViewProjectDescModal
                            isViewDescriptionModalOpen={
                                isViewDescriptionModalOpen
                            }
                            setViewDescriptionModalOpen={
                                setViewDescriptionModalOpen
                            }
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                        />
                        <ViewAddressDescriptionModal
                            isViewAddDescModalOpen={isViewAddDescModalOpen}
                            setViewAddDescModalOpen={setViewAddDescModalOpen}
                            selectedAddress={selectedAddress}
                            setSelectedAddress={setSelectedAddress}
                        />
                        <ViewPeopleModal
                            isEventPeopleModalOpen={
                                isEventPeopleModalOpen
                            }
                            setEventPeopleModalOpen={
                                setEventPeopleModalOpen
                            }
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                        <ViewTeamsModal
                            isEventTeamsModalOpen={
                                isEventTeamsModalOpen
                            }
                            setEventTeamsModalOpen={
                                setEventTeamsModalOpen
                            }
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                        <EditStatusModal
                            isEditEventStatusModalOpen={isEditEventStatusModalOpen}
                            setEditEventStatusModalOpen={setEditEventStatusModalOpen}
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                            selectedAddress={selectedAddress}
                            setSelectedAddress={setSelectedAddress}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Work;
