// src/context/PasswordResetContext.js
import React, { createContext, useState, useEffect  } from 'react';

export const PasswordResetContext = createContext();

export const PasswordResetProvider = ({ children }) => {
    const [username, setUsernameState] = useState('');
    const [resetMessage, setResetMessage] = useState('');
    const [resetMessageType, setResetMessageType] = useState('success');
    const [tempToken, setTempTokenState] = useState(undefined);

    // Load username and tempToken from localStorage when context initializes
    useEffect(() => {
        const storedUsername = localStorage.getItem('resetUsername');
        if (storedUsername) {
            setUsernameState(storedUsername);
        }
        const storedTempToken = localStorage.getItem('resetTempToken');
        if (storedTempToken) {
            setTempTokenState(storedTempToken);
        } else {
            setTempTokenState(null);
        }
    }, []);

    // Update localStorage whenever username changes
    const setUsername = (newUsername) => {
        setUsernameState(newUsername);
        if (newUsername) {
            localStorage.setItem('resetUsername', newUsername);
        } else {
            localStorage.removeItem('resetUsername');
        }
    };

        // Custom setter for tempToken to sync with localStorage
    const setTempToken = (newTempToken) => {
        setTempTokenState(newTempToken);
        if (newTempToken) {
            localStorage.setItem('resetTempToken', newTempToken);
        } else {
            localStorage.removeItem('resetTempToken');
        }
    };

    // Function to reset the context values
    const resetContext = ({ preserveMessage = false } = {}) => {
        setUsername('');
        setTempToken('');
        localStorage.removeItem('cooldownStartTime');
        localStorage.removeItem('resetUsername');
        if (!preserveMessage) {
            setResetMessage('');
            setResetMessageType('');
        }
    };

    return (
        <PasswordResetContext.Provider
            value={{
                username,
                setUsername,
                resetMessage,
                setResetMessage,
                resetMessageType,
                setResetMessageType,
                tempToken,
                setTempToken,
                resetContext,
            }}
        >
            {children}
        </PasswordResetContext.Provider>
    );
};
