import React, { useState, useRef, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Slider,
    useTheme,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';

const ImageUploadModal = ({ isAvatarModalOpen, onClose, onImageSelect, setImageSelected }) => {
    const [image, setImage] = useState(null);
    const [scale, setScale] = useState(10);
    const [rotation, setRotation] = useState(0);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const editorRef = useRef();
    const inputRef = useRef();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1100);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleScaleChange = (event, newValue) => {
        setScale(newValue);
    };

    const handleRotChange = (event, newValue) => {
        setRotation(newValue);
    };

    const handleCropSubmit = () => {
        if (editorRef.current) {
            const croppedImage = editorRef.current.getImageScaledToCanvas();
            onImageSelect(croppedImage);
            setImageSelected(true);
            onClose();
        }
    };

    const handleCancel = () => {
        setImage(null);
        onClose();
    };

    const handleInputClick = () => {
        inputRef.current.click();
    };

    const handleInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Select Photo':
                handleInputClick();
                break;
            case 'Submit':
                handleCropSubmit();
                break;
            case 'Cancel':
                handleCancel();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal open={isAvatarModalOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '70%' : '60%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Close Icon */}
                <Box
                    sx={{
                        height: '7%',
                        width: '97%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton edge="end" color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Title */}
                <Box
                    sx={{
                        height: '5%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        Upload Profile Image
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto', // Make this box scrollable
                        px: 3,
                        pb: 2,
                    }}
                >
                     {/* Image Editor */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Box
                            style={{
                                width: isMobile ? '60%' : '45%',
                                height: isMobile ? '40%' : '50%',
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={handleInputClick}
                        >
                            {image ? (
                                <AvatarEditor
                                    ref={editorRef}
                                    width={200}
                                    height={200}
                                    image={image}
                                    scale={scale / 10}
                                    rotate={rotation}
                                    border={50}
                                    borderRadius={100}
                                    color={[0, 0, 0, 0.72]} // RGBA
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: 300, // Ensure the box maintains the same size as the editor
                                        height: 200,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="h6" color={colors.grey[500]}>
                                        Select a photo
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <input
                            type="file"
                            accept="image/*"
                            ref={inputRef}
                            style={{ display: 'none' }}
                            onChange={handleInputChange}
                        />
                    </Box>


                    {/* Sliders */}
                    <Box
                        sx={{
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                mb: 2,
                            }}
                        >
                            <Typography
                                variant="h6"
                                color={colors.grey[500]}
                                sx={{ width: '20%' }}
                            >
                                Scale:
                            </Typography>
                            <Slider
                                value={scale}
                                onChange={handleScaleChange}
                                min={10}
                                max={50}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => `${value / 10}`}
                                disabled={!image}
                                sx={{ width: '80%', opacity: image ? 1 : 0.5 }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="h6"
                                color={colors.grey[500]}
                                sx={{ width: '20%' }}
                            >
                                Rotation:
                            </Typography>
                            <Slider
                                value={rotation}
                                onChange={handleRotChange}
                                min={0}
                                max={359}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => `${value}`}
                                disabled={!image}
                                sx={{ width: '80%', opacity: image ? 1 : 0.5 }}
                            />
                        </Box>
                    </Box>

                    {/* Buttons / Select Action */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: isMobile ? 1 : 2,
                            flexDirection: isMobile ? 'column' : 'row',
                            mt: 2,
                        }}
                    >
                        {isMobile ? (
                            <FormControl sx={{ width: '100%', borderRadius: '8px', textAlign: 'center' }}>
                                <Select
                                    value={selectedAction}
                                    onChange={handleSelectAction}
                                    displayEmpty
                                    sx={{
                                        color: colors.grey[100],
                                        backgroundColor: colors.primary[400],
                                        borderRadius: '8px',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: colors.grey[300],
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: colors.grey[100],
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: colors.grey[100],
                                        },
                                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select an action
                                    </MenuItem>
                                    <MenuItem value="Select Photo">
                                        Select Photo
                                    </MenuItem>
                                    <MenuItem value="Submit">Submit</MenuItem>
                                    <MenuItem value="Cancel">Cancel</MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{
                                        color: colors.grey[100],
                                        borderColor: colors.grey[100],
                                        whiteSpace: 'nowrap',
                                    }}
                                    onClick={handleInputClick}
                                >
                                    Select Photo
                                </Button>
                                <Button
                                    type="button"
                                    onClick={handleCropSubmit}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        ml: 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Submit
                                </Button>
                                <Button
                                    type="button"
                                    onClick={handleCancel}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.redAccent[700],
                                        },
                                        ml: 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ImageUploadModal;
