import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    TextField,
    useTheme,
    useMediaQuery,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const EditProjectDescModal = ({
    isDescriptionEditModalOpen,
    setDescriptionEditModalOpen,
    selectedProject,
    setSelectedProject,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [description, setDescription] = useState(
        selectedProject?.description || ''
    );
    const [selectedAction, setSelectedAction] = useState('');

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleClose = () => {
        setDescriptionEditModalOpen(false);
        setDescription('');
    };

    const handleSubmit = async () => {
        const controller = new AbortController();
        try {
            const updatedProject = {
                ...selectedProject,
                description: description,
            };
            await axiosPrivate.patch(
                `/project/project/${selectedProject.id}`,
                { description: description },
                { signal: controller.signal }
            );
            setSnackbarMessage(
                `Updated description for the project successfully`
            );
            setSnackbarType('success');
            setSnackbarOpen(true);
            setSelectedProject(updatedProject);
            handleClose();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = async (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                await handleSubmit();
                break;
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setDescription(selectedProject?.description || '');
    }, [selectedProject]);

    return (
        <Modal
            open={isDescriptionEditModalOpen}
            onClose={handleClose}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '50%' : '50%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        {`${selectedProject?.title}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: isMobile ? 2 : 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6" color={colors.grey[100]} mb={1}>
                        Description
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={8}
                        variant="filled"
                        value={description}
                        onChange={handleDescriptionChange}
                        sx={{
                            width: '100%',
                            mb: 2,
                        }}
                    />
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                alignSelf: 'center',
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) => handleSelectAction(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select action' }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Submit">Submit</MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                type="button"
                                onClick={handleSubmit}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default EditProjectDescModal;
