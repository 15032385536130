import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Modal,
    TextField,
    IconButton,
    useTheme,
    Select,
    MenuItem,
    FormControl,
    useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';

const DetailedViewModal = ({
    isDetailedViewOpen,
    setDetailedViewOpen,
    purpose: initialPurpose,
    setPurpose,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [purpose, setLocalPurpose] = useState(initialPurpose);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setLocalPurpose(initialPurpose);
    }, [initialPurpose]);

    const handleClose = () => {
        setDetailedViewOpen(false);
    };

    const handleSubmit = () => {
        setPurpose(purpose);
        setDetailedViewOpen(false);
    };

    const handlePurposeChange = (event) => {
        setLocalPurpose(event.target.value);
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                handleSubmit();
                break;
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal
            open={isDetailedViewOpen}
            onClose={handleClose}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '50%' : '50%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Typography
                            variant={isMobile ? 'h6' : 'h5'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                            textAlign="center"
                            sx={{ marginBottom: 2 }}
                        >
                            Purpose for Invoice
                        </Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={8}
                            variant="filled"
                            value={purpose}
                            name="purpose"
                            onChange={handlePurposeChange}
                            sx={{
                                width: '100%',
                                mb: 2,
                            }}
                        />
                    </Box>
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                mt: 2,
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) =>
                                    handleSelectAction(e.target.value)
                                }
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select action' }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Submit">Submit</MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                type="button"
                                onClick={handleSubmit}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor:
                                            colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default DetailedViewModal;
