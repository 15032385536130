import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    TextField,
    useTheme,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import * as yup from 'yup';

const EditNameModal = ({
    isNameModalOpen,
    setNameModalOpen,
    selectedTeamName,
    setSelectedTeamName,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleFormSubmit = async (values) => {
        setSelectedTeamName(values.teamName);
        setNameModalOpen(false);
    };

    const validationSchema = yup.object().shape({
        teamName: yup.string().required('Team Name is required'),
    });

    return (
        <Modal
            open={isNameModalOpen}
            onClose={() => setNameModalOpen(false)}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    maxHeight: '90%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Close Icon */}
                <Box
                    sx={{
                        height: '15%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setNameModalOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Title */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        Team Name
                    </Typography>
                </Box>

                {/* Form Content */}
                <Box
                    sx={{
                        px: 3,
                        flexGrow: 1,
                        overflowY: 'auto',
                    }}
                >
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={{
                            teamName: selectedTeamName,
                        }}
                        validationSchema={validationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" gap="16px">
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Team Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.teamName}
                                        name="teamName"
                                        error={!!touched.teamName && !!errors.teamName}
                                        helperText={touched.teamName && errors.teamName}
                                    />
                                </Box>

                                {/* Buttons or Select Menu */}
                                {isMobile ? (
                                    <FormControl
                                        sx={{
                                            width: '100%',
                                            mt: 4, // Adjust the margin-top to move it up from the bottom
                                            mb: 2, // Add margin-bottom for spacing from the bottom of the modal
                                        }}
                                    >
                                        <Select
                                            value=""
                                            onChange={(e) => {
                                                if (e.target.value === 'Set Name') handleSubmit();
                                                if (e.target.value === 'Close') setNameModalOpen(false);
                                            }}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Select action' }}
                                            sx={{
                                                color: colors.grey[100],
                                                backgroundColor: colors.primary[400],
                                                borderRadius: '8px',
                                                boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: colors.grey[300],
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: colors.grey[100],
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: colors.grey[100],
                                                },
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                Select an action
                                            </MenuItem>
                                            <MenuItem value="Set Name">Set Name</MenuItem>
                                            <MenuItem value="Close">Close</MenuItem>
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        gap={2}
                                        mt={3}
                                        mb={2}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                color: 'white',
                                                backgroundColor: colors.greenAccent[500],
                                                '&:hover': {
                                                    backgroundColor: colors.greenAccent[700],
                                                },
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            Set Name
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() => setNameModalOpen(false)}
                                            sx={{
                                                color: 'white',
                                                backgroundColor: colors.redAccent[500],
                                                '&:hover': {
                                                    backgroundColor: colors.redAccent[700],
                                                },
                                                whiteSpace: 'nowrap',
                                            }}
                                            variant="contained"
                                        >
                                            Close
                                        </Button>
                                    </Box>
                                )}
                            </form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditNameModal;
