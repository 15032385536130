import {
    Box,
    Button,
    TextField,
    useTheme,
    Snackbar,
    Alert,
    Typography,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Form = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const controllerRef = useRef(new AbortController());
    const navigate = useNavigate();
    const location = useLocation();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [selectedAction, setSelectedAction] = useState('');

    // Update isMobile state based on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const controller = new AbortController();
            controllerRef.current = controller;

            const response = await axiosPrivate.post('/auth/signup', values, {
                signal: controller.signal,
            });

            const { message, person_info } = response.data;
            const { username } = person_info;

            setSnackbarMessage(`${message}: ${username}`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            resetForm();
        } catch (err) {
            if (err.response && err.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(err.response?.data.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleCreateAndActivate = async (values, { resetForm }) => {
        try {
            const controller = new AbortController();
            controllerRef.current = controller;

            const response = await axiosPrivate.post('/auth/signup', values, {
                signal: controller.signal,
            });

            const { message, person_info } = response.data;
            const { id, username } = person_info;

            await axiosPrivate.patch(`/person/activate/${id}`);

            setSnackbarMessage(`${message}: ${username} activated`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            resetForm();
        } catch (err) {
            if (err.response && err.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(err.response?.data.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleReset = (resetForm) => {
        resetForm();
        setSnackbarMessage('');
    };

    const handleFieldClick = () => {
        setSnackbarMessage('');
    };

    const handleSelectAction = async (action, values, resetForm) => {
        switch (action) {
            case 'create':
                await handleFormSubmit(values, { resetForm });
                break;
            case 'createAndActivate':
                await handleCreateAndActivate(values, { resetForm });
                break;
            case 'reset':
                handleReset(resetForm);
                break;
            default:
                break;
        }
        setSelectedAction('');
    };

    return (
        <Box m="20px">
            <Header title="CREATE USER" subtitle="Create a New User Profile" />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarType}
                    sx={{
                        width: '100%',
                        backgroundColor:
                            snackbarType === 'success'
                                ? colors.greenAccent[600]
                                : colors.redAccent[600],
                        color: 'white',
                    }}
                >
                    <Typography variant="h6" color={colors.grey[100]}>
                        {snackbarMessage}
                    </Typography>
                </Alert>
            </Snackbar>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={{
                    first_name: '',
                    last_name: '',
                    job_title: '',
                    email: '',
                    contact: '',
                    address1: '',
                    address2: '',
                }}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(4, 1fr)'}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="First Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onClick={handleFieldClick}
                                value={values.first_name}
                                name="first_name"
                                error={!!touched.first_name && !!errors.first_name}
                                helperText={touched.first_name && errors.first_name}
                                sx={{ gridColumn: isMobile ? 'span 4' : 'span 2' }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Last Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onClick={handleFieldClick}
                                value={values.last_name}
                                name="last_name"
                                error={!!touched.last_name && !!errors.last_name}
                                helperText={touched.last_name && errors.last_name}
                                sx={{ gridColumn: isMobile ? 'span 4' : 'span 2' }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Job Title"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onClick={handleFieldClick}
                                value={values.job_title}
                                name="job_title"
                                error={!!touched.job_title && !!errors.job_title}
                                helperText={touched.job_title && errors.job_title}
                                sx={{ gridColumn: isMobile ? 'span 4' : 'span 2' }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onClick={handleFieldClick}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: isMobile ? 'span 4' : 'span 2' }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Contact Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onClick={handleFieldClick}
                                value={values.contact}
                                name="contact"
                                error={!!touched.contact && !!errors.contact}
                                helperText={touched.contact && errors.contact}
                                sx={{ gridColumn: 'span 4' }} // Full width for both mobile and desktop
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Address 1"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onClick={handleFieldClick}
                                value={values.address1}
                                name="address1"
                                error={!!touched.address1 && !!errors.address1}
                                helperText={touched.address1 && errors.address1}
                                sx={{ gridColumn: 'span 4' }} // Full width for both mobile and desktop
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Address 2"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onClick={handleFieldClick}
                                value={values.address2}
                                name="address2"
                                error={!!touched.address2 && !!errors.address2}
                                helperText={touched.address2 && errors.address2}
                                sx={{ gridColumn: 'span 4' }} // Full width for both mobile and desktop
                            />
                        </Box>
                        {isMobile ? (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <Select
                                    value={selectedAction}
                                    onChange={(e) =>
                                        handleSelectAction(e.target.value, values, resetForm)
                                    }
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select action' }}
                                    sx={{
                                        backgroundColor: colors.primary[400],
                                        color: 'white',
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select Action
                                    </MenuItem>
                                    <MenuItem value="create">Create New User</MenuItem>
                                    <MenuItem value="createAndActivate">
                                        Create and Activate
                                    </MenuItem>
                                    <MenuItem value="reset">Reset</MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <Box display="flex" justifyContent="flex-end" mt="20px" gap={2}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Typography variant="h6" color={colors.grey[100]}>
                                        Create New User
                                    </Typography>
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() =>
                                        handleCreateAndActivate(values, { resetForm })
                                    }
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Typography variant="h6" color={colors.grey[100]}>
                                        Create and Activate
                                    </Typography>
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => handleReset(resetForm)}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.redAccent[700],
                                        },
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Typography variant="h6" color={colors.grey[100]}>
                                        Reset
                                    </Typography>
                                </Button>
                            </Box>
                        )}
                    </form>
                )}
            </Formik>
        </Box>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    first_name: yup.string().required('required'),
    last_name: yup.string().required('required'),
    job_title: yup.string().required('required'),
    email: yup.string().email('invalid email').required('required'),
    contact: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('required'),
    address1: yup.string().nullable().notRequired(),
    address2: yup.string().nullable().notRequired(),
});

export default Form;
