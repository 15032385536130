import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, useTheme, Select, MenuItem, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import IconButton from '@mui/material/IconButton';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import * as yup from 'yup';
import { Formik } from 'formik';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const CallInModal = ({
    isCallinModalOpen,
    setCallinModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
    checkedInspections,
    setCheckedInspections,
    selectedInspection,
    setSelectedInspection,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCallInInspections = async (values) => {
        const formattedDate = dayjs(values.call_in_date).format('YYYY-MM-DD HH:mm:ss');
        const controller = new AbortController();
        try {
            if (selectedInspection) {
                await axiosPrivate.patch(
                    `/inspection/inspection/${selectedInspection.id}`,
                    {
                        call_in_date: formattedDate,
                    },
                    {
                        signal: controller.signal,
                    }
                );
                setSnackbarMessage('Updated call-in date for the inspection successfully');
            } else {
                for (const inspectionIndex of checkedInspections) {
                    await axiosPrivate.patch(
                        `/inspection/inspection/${inspectionIndex}`,
                        {
                            call_in_date: formattedDate,
                        },
                        {
                            signal: controller.signal,
                        }
                    );
                }
                setSnackbarMessage(`Updated call-in date for ${checkedInspections.length} inspection(s) successfully`);
            }

            setSnackbarType('success');
            setSnackbarOpen(true);

            setCheckedInspections([]);
            setSelectedInspection(null);
            setCallinModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(error.response?.data.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const getValidationSchema = yup.object().shape({
        call_in_date: yup.date().required('Call-in date is required'),
    });

    const handleSelectAction = (event, handleSubmit) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Set Date':
                handleSubmit();
                break;
            case 'Close':
                setCallinModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal
                open={isCallinModalOpen}
                onClose={() => {
                    setCallinModalOpen(false);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        width: isMobile ? '80%' : '30%', // Adjust width based on isMobile
                        height: isMobile ? '40%' : '35%', // Adjust height based on isMobile
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            height: '10%',
                            width: '98%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                setCallinModalOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            height: '10%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h4' : 'h2'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            Set Call-In Date:
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                            overflowY: 'auto',
                            p: isMobile ? 2 : 3, // Adjust padding based on isMobile
                        }}
                    >
                        <Formik
                            onSubmit={handleCallInInspections}
                            initialValues={{
                                call_in_date: dayjs(new Date()),
                            }}
                            validationSchema={getValidationSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        sx={{
                                            margin: '10% 15%', // Adjust margin
                                        }}
                                    >
                                        <DateTimePicker
                                            fullWidth
                                            variant="filled"
                                            label="Call-In Date"
                                            onBlur={handleBlur}
                                            onChange={(date) =>
                                                handleChange({
                                                    target: {
                                                        name: 'call_in_date',
                                                        value: date,
                                                    },
                                                })
                                            }
                                            value={values.call_in_date}
                                            name="call_in_date"
                                            error={!!touched.call_in_date && !!errors.call_in_date}
                                            helperText={touched.call_in_date && errors.call_in_date}
                                            sx={{ gridColumn: 'span 1' }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gap: 2,
                                            mt: 2,
                                            mb: 3,
                                        }}
                                    >
                                        {isMobile ? (
                                            <FormControl sx={{ width: '100%', borderRadius: '8px' }}>
                                                <Select
                                                    value={selectedAction}
                                                    onChange={(event) =>
                                                        handleSelectAction(event, handleSubmit)
                                                    }
                                                    displayEmpty
                                                    sx={{
                                                        color: colors.grey[100],
                                                        backgroundColor: colors.primary[400],
                                                        borderRadius: '8px',
                                                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor: colors.grey[300],
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: colors.grey[100],
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: colors.grey[100],
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Select an action
                                                    </MenuItem>
                                                    <MenuItem value="Set Date">Set Date</MenuItem>
                                                    <MenuItem value="Close">Close</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor: colors.greenAccent[500],
                                                        '&:hover': {
                                                            backgroundColor: colors.greenAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap', // Prevents text wrapping
                                                    }}
                                                >
                                                    Set Date
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        setCallinModalOpen(false);
                                                    }}
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor: colors.redAccent[500],
                                                        '&:hover': {
                                                            backgroundColor: colors.redAccent[700],
                                                        },
                                                    }}
                                                    variant="contained"
                                                >
                                                    Close
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </LocalizationProvider>
    );
};

export default CallInModal;
