import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    // Check if auth or auth.roles is undefined before accessing it
    if (!auth || !auth.roles) {
        // Handle the case where auth or auth.roles is undefined (e.g., user not authenticated)
        console.log('auth or auth.roles is undefined');
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return auth?.roles.find((role) => allowedRoles?.includes(role)) ? (
        <Outlet />
    ) : auth?.username ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default RequireAuth;
