import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.post('/auth/refresh', {
            withCredentials: true,
        });

        setAuth((prev) => {
            return {
                ...prev,
                username: response.data.username,
                roles: response.data.roles,
                accessToken: response.data.access_token,
                name: `${response.data.first_name} ${response.data.last_name}`,
                user_id: response.data.user_id,
                job_title: response.data.job_title,
            };
        });
        return response.data.access_token;
    };
    return refresh;
};

export default useRefreshToken;
