// src/components/LoginPage/index.jsx
import { useState, useEffect, useContext } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    TextField,
    Typography,
    useTheme,
    Snackbar,
    Alert,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from '../../api/axios';
import { tokens } from '../../theme';
import LoginIcon from '@mui/icons-material/Login';
import useAuth from '../../hooks/useAuth';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';
import useAvatarUrl from '../../hooks/useAvatarUrl';
import { PasswordResetContext } from '../../context/PasswordResetContext';

const LOGIN_URL = '/auth/login';

const LoginPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // State variables for login messages
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('success');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    // State variables for password reset messages
    const [resetSnackbarOpen, setResetSnackbarOpen] = useState(false);
    const [resetMessageLocal, setResetMessageLocal] = useState('');
    const [resetMessageTypeLocal, setResetMessageTypeLocal] = useState('success');

    const [username, resetPerson, personAttribs] = useInput('username', '');
    const [password, setPassword] = useState('');
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/dashboard';
    const [check, toggleCheck] = useToggle('persist', false);
    const fetchAvatarUrl = useAvatarUrl();

    const {
        resetMessage,
        resetMessageType,
        setResetMessage,
        setResetMessageType,
    } = useContext(PasswordResetContext);

    // Handle password reset messages
    useEffect(() => {
        if (resetMessage) {
            // Capture context values into local state
            setResetMessageLocal(resetMessage);
            setResetMessageTypeLocal(resetMessageType || 'success');
            setResetSnackbarOpen(true);

            // Clear the reset message from context after capturing
            setResetMessage('');
            setResetMessageType('');
        }
    }, [
        resetMessage,
        resetMessageType,
        setResetMessage,
        setResetMessageType,
    ]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setSnackbarOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                LOGIN_URL,
                JSON.stringify({ username, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );
            console.log('Login successful:', response);
            setMessage('Login successful!');
            setMessageType('success');
            setSnackbarOpen(true);
            setPassword('');
            const accessToken = response?.data?.access_token;
            const roles = response?.data.roles;
            const user_id = response?.data?.user_id;
            const name = `${response?.data?.first_name} ${response?.data?.last_name}`;
            const job_title = response?.data?.job_title;
            setAuth({ username, roles, accessToken, name, user_id, job_title });
            fetchAvatarUrl(user_id);
            resetPerson();
            navigate(from, { replace: true });
        } catch (error) {
            if (error.response) {
                console.error('Login failed:', error.response.status, error.response.data.message);
                setMessage(`Error ${error.response.status}: ${error.response.data.message}`);
                setMessageType('error');
                setSnackbarOpen(true);
                setPassword('');
            } else {
                console.error('An error occurred while processing your request.');
                setMessage('An error occurred while processing your request.');
                setMessageType('error');
                setSnackbarOpen(true);
                setPassword('');
            }
        }
    };

    const linkStyle = {
        color: colors.blueAccent[700],
        transition: 'color 0.3s ease, text-decoration 0.3s ease',
        textDecoration: 'none',
    };

    const handleHover = (event) => {
        event.target.style.color = colors.blueAccent[400];
        event.target.style.textDecoration = 'underline';
    };

    const handleLeave = (event) => {
        event.target.style.color = colors.blueAccent[700];
        event.target.style.textDecoration = 'none';
    };

    const handleFieldClick = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        setSnackbarOpen(false);
    };

    const handleResetSnackbarClose = (event, reason) => {
        setResetSnackbarOpen(false);
        // Optionally clear local state
        setResetMessageLocal('');
        setResetMessageTypeLocal('success');
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: theme.spacing(8),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {/* Snackbar for login messages */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={messageType}
                        sx={{
                            width: '100%',
                            backgroundColor:
                                messageType === 'success'
                                    ? colors.greenAccent[600]
                                    : colors.redAccent[600],
                            color: 'white',
                        }}
                    >
                        <Typography variant="h6">{message}</Typography>
                    </Alert>
                </Snackbar>

                {/* Snackbar for password reset messages */}
                <Snackbar
                    open={resetSnackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleResetSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handleResetSnackbarClose}
                        severity={resetMessageTypeLocal || 'success'}
                        sx={{
                            width: '100%',
                            backgroundColor:
                                resetMessageTypeLocal === 'success'
                                    ? colors.greenAccent[600]
                                    : colors.redAccent[600],
                            color: 'white',
                        }}
                    >
                        <Typography variant="h6">{resetMessageLocal}</Typography>
                    </Alert>
                </Snackbar>

                <Box
                    sx={{
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        padding: theme.spacing(4),
                        width: '100%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4">
                        Sign In
                        <LoginIcon
                            style={{
                                verticalAlign: 'middle',
                                marginLeft: theme.spacing(1),
                            }}
                        />
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: theme.spacing(2), width: '100%' }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            {...personAttribs}
                            onClick={handleFieldClick}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={handlePasswordChange}
                            onClick={handleFieldClick}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: theme.spacing(2),
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={toggleCheck}
                                        checked={check}
                                    />
                                }
                                label={<Typography variant="h6">Remember me</Typography>}
                            />
                            <Typography variant="h6">
                                Forgot password?{' '}
                                <Link
                                    to="/password-reset"
                                    style={linkStyle}
                                    onMouseEnter={handleHover}
                                    onMouseLeave={handleLeave}
                                >
                                    Click here
                                </Link>
                            </Typography>
                        </Box>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: theme.spacing(2),
                                mb: theme.spacing(2),
                                bgcolor: colors.blueAccent[700],
                                '&:hover': {
                                    bgcolor: colors.blueAccent[400],
                                },
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
