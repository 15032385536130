import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    List,
    ListItem,
    Checkbox,
    useTheme,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import AvatarManager from '../people/AvatarManager';

const ViewPeopleModal = ({
    isEventPeopleModalOpen,
    setEventPeopleModalOpen,
    selectedEvent,
    setSelectedEvent,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => {
        setEventPeopleModalOpen(false);
    };

    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal open={isEventPeopleModalOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '40%',
                    height: isMobile ? '70%' : '60%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* Close Icon */}
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton edge="end" color="inherit" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Title */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        sx={{
                            textDecoration: 'underline',
                            marginBottom: '8px',
                        }}
                    >
                        View People
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box
                    sx={{
                        height: '70%',
                        width: '90%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'auto',
                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                        margin: '0 auto',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                    }}
                >
                    <List>
                        {selectedEvent?.persons.map((person) => (
                            <ListItem
                                key={person.id}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <AvatarManager
                                    personId={person.id}
                                    username={person.username}
                                />
                                <Typography
                                    variant={isMobile ? 'body1' : 'h6'}
                                    color={colors.grey[100]}
                                    ml={1}
                                >
                                    {person.username}: {person.first_name} {person.last_name}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>

                {/* Buttons / Select Action */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 'auto',
                        py: 2,
                        gap: isMobile ? 1 : 2,
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    {isMobile ? (
                        <FormControl sx={{ width: '90%', borderRadius: '8px' }}>
                            <Select
                                value={selectedAction}
                                onChange={handleSelectAction}
                                displayEmpty
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Close
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default ViewPeopleModal;
