import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, useTheme, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const DeleteTeamModal = ({
    isDeleteModalOpen,
    setDeleteModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
    checkedTeams,
    setCheckedTeams,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDeleteTeams = async () => {
        const controller = new AbortController();
        try {
            for (const teamIndex of checkedTeams) {
                await axiosPrivate.delete(`/team/team/${teamIndex}`, {
                    signal: controller.signal,
                });
            }

            setSnackbarMessage(`Deleted ${checkedTeams.length} team(s) successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);

            setCheckedTeams([]);
            setDeleteModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(error.response?.data.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    return (
        <Modal
            open={isDeleteModalOpen}
            onClose={() => {
                setDeleteModalOpen(false);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '15%' : '20%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Close Icon */}
                <Box
                    sx={{
                        height: '15%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setDeleteModalOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Title */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        Are you sure you want to delete {checkedTeams.length} team(s)?
                    </Typography>
                </Box>

                {/* Scrollable Content (Buttons) */}
                <Box
                    sx={{
                        overflowY: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                        marginTop: 'auto',
                        paddingBottom: theme.spacing(2), // Add padding to move buttons closer to the bottom
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 2,
                        }}
                    >
                        <Button
                            onClick={handleDeleteTeams}
                            variant="contained"
                            sx={{
                                color: 'white',
                                backgroundColor: colors.redAccent[500],
                                '&:hover': {
                                    backgroundColor: colors.redAccent[700],
                                },
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={() => setDeleteModalOpen(false)}
                            variant="contained"
                            sx={{
                                color: 'white',
                                backgroundColor: colors.greenAccent[600],
                                '&:hover': {
                                    backgroundColor: colors.greenAccent[700],
                                },
                                whiteSpace: 'nowrap',
                            }}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteTeamModal;
