import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import * as yup from 'yup';
import { Formik } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const ScheduleFixModal = ({
    isFixModalOpen,
    setFixModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
    checkedInspections,
    setCheckedInspections,
    selectedInspection,
    setSelectedInspection,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSelectAction = async (action, submitForm) => {
        setSelectedAction(action);

        switch (action) {
            case 'Set Date':
                await submitForm();
                break;
            case 'Close':
                setFixModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const handleFixInspections = async (values, { resetForm }) => {
        const formattedDate = dayjs(values.scheduled_fix_date).format(
            'YYYY-MM-DD HH:mm:ss'
        );
        const controller = new AbortController();
        try {
            if (selectedInspection) {
                await axiosPrivate.patch(
                    `/inspection/inspection/${selectedInspection.id}`,
                    {
                        scheduled_fix_date: formattedDate,
                    },
                    {
                        signal: controller.signal,
                    }
                );
                setSnackbarMessage(
                    `Updated fix date for the inspection successfully`
                );
            } else {
                for (const inspectionIndex of checkedInspections) {
                    await axiosPrivate.patch(
                        `/inspection/inspection/${inspectionIndex}`,
                        {
                            scheduled_fix_date: formattedDate,
                        },
                        {
                            signal: controller.signal,
                        }
                    );
                }
                setSnackbarMessage(
                    `Updated fix date for ${checkedInspections.length} inspection(s) successfully`
                );
            }

            setSnackbarType('success');
            setSnackbarOpen(true);

            setCheckedInspections([]);
            setSelectedInspection(null);
            resetForm();
            setFixModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const getValidationSchema = yup.object().shape({
        scheduled_fix_date: yup
            .date()
            .nullable()
            .required('Fix date is required'),
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal
                open={isFixModalOpen}
                onClose={() => {
                    setFixModalOpen(false);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        width: isMobile ? '60%' : '30%',
                        height: isMobile ? '40%' : '35%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            height: '10%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            px: 2,
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                setFixModalOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h4' : 'h2'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            Set Fix Date:
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            p: isMobile ? 2 : 3,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Formik
                            onSubmit={handleFixInspections}
                            initialValues={{
                                scheduled_fix_date: dayjs(new Date()),
                            }}
                            validationSchema={getValidationSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                submitForm,
                                resetForm,
                                setFieldValue,
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Box
                                        display="grid"
                                        gap="20px"
                                        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                        sx={{
                                            '& > div': {
                                                gridColumn: isMobile
                                                    ? 'span 1'
                                                    : undefined,
                                            },
                                        }}
                                    >
                                        <DateTimePicker
                                            fullWidth
                                            label="Fix Date"
                                            onBlur={handleBlur}
                                            onChange={(date) =>
                                                setFieldValue(
                                                    'scheduled_fix_date',
                                                    date
                                                )
                                            }
                                            value={
                                                values.scheduled_fix_date
                                            }
                                            name="scheduled_fix_date"
                                            sx={{ gridColumn: 'span 2' }}
                                            slotProps={{
                                                textField: {
                                                    error: Boolean(
                                                        touched.scheduled_fix_date &&
                                                        errors.scheduled_fix_date
                                                    ),
                                                    helperText:
                                                        touched.scheduled_fix_date &&
                                                        errors.scheduled_fix_date
                                                            ? errors.scheduled_fix_date
                                                            : '',
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gap: 2,
                                            mt: 'auto',
                                            mb: 2,
                                        }}
                                    >
                                        {isMobile ? (
                                            <FormControl
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                <Select
                                                    value={selectedAction}
                                                    onChange={(e) =>
                                                        handleSelectAction(
                                                            e.target.value,
                                                            submitForm
                                                        )
                                                    }
                                                    displayEmpty
                                                    inputProps={{
                                                        'aria-label':
                                                            'Select action',
                                                    }}
                                                    sx={{
                                                        color: colors.grey[100],
                                                        backgroundColor:
                                                            colors.primary[400],
                                                        borderRadius: '8px',
                                                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor:
                                                                colors.grey[300],
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline':
                                                            {
                                                                borderColor:
                                                                    colors.grey[100],
                                                            },
                                                        '& .MuiSvgIcon-root': {
                                                            color: colors.grey[100],
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Select an action
                                                    </MenuItem>
                                                    <MenuItem value="Set Date">
                                                        Set Date
                                                    </MenuItem>
                                                    <MenuItem value="Close">
                                                        Close
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.greenAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.greenAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    Set Date
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        setFixModalOpen(
                                                            false
                                                        );
                                                    }}
                                                    sx={{
                                                    color: 'white',
                                                    backgroundColor:
                                                        colors.redAccent[500],
                                                    '&:hover': {
                                                        backgroundColor:
                                                            colors.redAccent[700],
                                                    },
                                                    whiteSpace: 'nowrap',
                                                }}
                                                variant="contained"
                                            >
                                                Close
                                            </Button>
                                            </>
                                        )}
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </LocalizationProvider>
    );
};

export default ScheduleFixModal;
