import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    TextField,
    useTheme,
    useMediaQuery,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import EditProjectDescModal from './EditProjectDescModal';
import { calculateShowAddToTeamButton } from '../people/RoleManager';
import useAuth from '../../hooks/useAuth';

const ViewProjectDescModal = ({
    isViewDescriptionModalOpen,
    setViewDescriptionModalOpen,
    selectedProject,
    setSelectedProject,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');
    const [isDescriptionEditModalOpen, setDescriptionEditModalOpen] =
        useState(false);
    const { auth } = useAuth();
    const showAddToTeamButton = calculateShowAddToTeamButton(auth.roles);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSelectAction = async (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Edit Description':
                setDescriptionEditModalOpen(true);
                break;
            case 'Close':
                setViewDescriptionModalOpen(false);
                setSelectedProject(null);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal
            open={isViewDescriptionModalOpen}
            onClose={() => {
                setViewDescriptionModalOpen(false);
                setSelectedProject(null);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '50%' : '50%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setViewDescriptionModalOpen(false);
                            setSelectedProject(null);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        {`${selectedProject?.title}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: isMobile ? 2 : 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6" color={colors.grey[100]} mb={1}>
                        Description
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={8}
                        variant="filled"
                        value={selectedProject?.description || ''}
                        readOnly
                        disabled
                        sx={{
                            width: '100%',
                            mb: 2,
                        }}
                    />
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                alignSelf: 'center',
                                mt: 2,
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) =>
                                    handleSelectAction(e.target.value)
                                }
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select action' }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                {showAddToTeamButton && (
                                    <MenuItem value="Edit Description">
                                        Edit Description
                                    </MenuItem>
                                )}
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            {showAddToTeamButton && (
                                <Button
                                    type="button"
                                    onClick={() =>
                                        setDescriptionEditModalOpen(true)
                                    }
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.greenAccent[700],
                                        },
                                        whiteSpace: 'nowrap',
                                    }}
                                    variant="contained"
                                >
                                    Edit Description
                                </Button>
                            )}
                            <Button
                                type="button"
                                onClick={() => {
                                    setViewDescriptionModalOpen(false);
                                    setSelectedProject(null);
                                }}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor:
                                            colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
                <EditProjectDescModal
                    isDescriptionEditModalOpen={isDescriptionEditModalOpen}
                    setDescriptionEditModalOpen={setDescriptionEditModalOpen}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarType={setSnackbarType}
                    setSnackbarOpen={setSnackbarOpen}
                />
            </Box>
        </Modal>
    );
};

export default ViewProjectDescModal;
