// useProjectEventData.js
import { useEffect, useState, useCallback, useRef } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const useProjectEventData = () => {
    const axiosPrivate = useAxiosPrivate();
    const [dropdownData, setDropdownData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const controllerRef = useRef(new AbortController());
    const [loading, setLoading] = useState(true);

    const fetchProjectsEvents = useCallback(async () => {
        try {
            const controller = new AbortController();
            controllerRef.current = controller;
            const response = await axiosPrivate.get(
                '/project/projects/events',
                {
                    signal: controller.signal,
                }
            );
            setDropdownData(response.data);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setLoading(false);
            }
        }
    }, [axiosPrivate, location, navigate]);

    useEffect(() => {
        fetchProjectsEvents();
    }, [fetchProjectsEvents]);

    return { loading, dropdownData, fetchProjectsEvents };
};

export default useProjectEventData;
