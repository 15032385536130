import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    Checkbox,
    IconButton,
    useMediaQuery,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const DeleteDocumentModal = ({
    isDeleteDocumentModalOpen,
    setDeleteDocumentModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
    selectedRowsDocument,
    setSelectedRowsDocument,
    selectedWarranty,
    setSelectedWarranty,
    selectedInvoice,
    setSelectedInvoice,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDeleteDocument = async () => {
        const controller = new AbortController();
        try {
            const payload = { delete_document: isChecked };
            for (const documentIndex of selectedRowsDocument[selectedWarranty.id]?.[selectedInvoice.id] || []) {
                await axiosPrivate.delete(
                    `/warranty/warranty/${selectedWarranty.id}/invoice/${selectedInvoice.id}/document/${documentIndex}`,
                    {
                        signal: controller.signal,
                        data: payload,
                    }
                );
            }

            setSnackbarMessage(
                `${isChecked ? 'Deleted' : 'Detached'} ${selectedRowsDocument[selectedWarranty.id]?.[selectedInvoice.id].length} document(s) successfully`
            );
            setSnackbarType('success');
            setSnackbarOpen(true);
            setIsChecked(false);

            const selectedDocumentsForWarranty = selectedRowsDocument[selectedWarranty.id] || {};
            setSelectedRowsDocument({
                ...selectedRowsDocument,
                [selectedWarranty.id]: {
                    ...selectedDocumentsForWarranty,
                    [selectedInvoice.id]: [],
                },
            });

            setSelectedWarranty(null);
            setSelectedInvoice(null);
            setDeleteDocumentModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Yes':
                handleDeleteDocument();
                break;
            case 'No':
                setDeleteDocumentModalOpen(false);
                setIsChecked(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal
            open={isDeleteDocumentModalOpen}
            onClose={() => {
                setDeleteDocumentModalOpen(false);
                setIsChecked(false);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '30%' : '25%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '15%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setDeleteDocumentModalOpen(false);
                            setIsChecked(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        {`Are you sure you want to ${isChecked ? 'delete' : 'detach'} ${selectedRowsDocument[selectedWarranty?.id]?.[selectedInvoice.id]?.length} document(s)?`}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '2%',
                        }}
                    >
                        <Checkbox
                            color="primary"
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            Detach and Delete
                        </Typography>
                    </Box>
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                mt: 2,
                                alignSelf: 'center',
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) =>
                                    handleSelectAction(e.target.value)
                                }
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select action' }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                                gap: 2,
                            }}
                        >
                            <Button
                                onClick={handleDeleteDocument}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    mr: 1,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => {
                                    setDeleteDocumentModalOpen(false);
                                    setIsChecked(false);
                                }}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[600],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                No
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteDocumentModal;
