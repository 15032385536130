import React, { useRef, useState, useEffect } from 'react';
import {
    Snackbar,
    Alert,
    Box,
    Typography,
    useTheme,
    Button,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { tokens } from '../../theme';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Header from '../../components/Header';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import MuiPagination from '@mui/material/Pagination';
import AvatarManager from './AvatarManager';
import EditPersonModal from './EditPersonModal';
import TeamModal from './TeamModal';
import {
    getHighestPrecedenceRole,
    calculateShowAddToTeamButton,
} from './RoleManager';
import usePeopleData from '../../hooks/usePeopleData';
import CircularLoading from '../global/CircularLoading';

const People = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [checkedPersons, setCheckedPersons] = useState([]);
    const { loading, personData, fetchPeople } = usePeopleData();
    const [avatarKey, setAvatarKey] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('newTeam');
    const [checkedUsernames, setCheckedUsernames] = useState([]);
    const [isEditPersonModalOpen, setEditPersonModalOpen] = useState(false);
    const [selectedPersonForEdit, setSelectedPersonForEdit] = useState(null);
    const controllerRef = useRef(new AbortController());
    const [editData, setEditData] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1050);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1050);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleOpenEditPersonModal = async (personId) => {
        setSelectedPersonForEdit(personId);
        try {
            const controller = new AbortController();
            controllerRef.current = controller;

            const response = await axiosPrivate.get(`/person/person/${personId}`, {
                signal: controller.signal,
            });
            setEditData(response.data);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            }
        }
        setEditPersonModalOpen(true);
    };

    const handleResetButtonClick = () => {
        setCheckedPersons([]);
        setCheckedUsernames([]);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (snackbarOpen) {
            fetchPeople();
            setAvatarKey((prevKey) => prevKey + 1);
        }
        const closeSnackbarOnOutsideClick = (event) => {
            if (snackbarOpen && !event.target.closest('.MuiAlert-root')) {
                setSnackbarOpen(false);
            }
        };

        document.addEventListener('click', closeSnackbarOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeSnackbarOnOutsideClick);
        };
    }, [snackbarOpen, fetchPeople]);

    const showAddToTeamButton = calculateShowAddToTeamButton(auth.roles);

    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Add to Team':
                setModalOpen(true);
                break;
            case 'Reset':
                handleResetButtonClick();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const personDataMapped = personData.map((person) => {
        const highestPrecedenceRole = getHighestPrecedenceRole(person.roles);

        return {
            id: person.id,
            name: `${person.first_name} ${person.last_name}`,
            username: person.username,
            jobTitle: person.job_title,
            email: person.email,
            phone: `(${person.contact.slice(0, 3)}) ${person.contact.slice(3, 6)}-${person.contact.slice(6)}`,
            accessLevel: highestPrecedenceRole,
        };
    });

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        {
            field: 'avatar',
            headerName: 'Avatar',
            width: isMobile ? 50 : 100,
            renderCell: ({ row }) => (
                <AvatarManager
                    key={avatarKey}
                    personId={row.id}
                    username={row.username}
                />
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            width: isMobile ? 150 : 200,
            cellClassName: 'name-column--cell',
        },
        { field: 'username', headerName: 'Username', width: isMobile ? 150 : 200, },
        { field: 'jobTitle', headerName: 'Job Title', width: isMobile ? 150 : 200, },
        { field: 'email', headerName: 'Email', width: isMobile ? 150 : 200, },
        { field: 'phone', headerName: 'Phone Number', width: isMobile ? 150 : 200, },
        {
            field: 'accessLevel',
            headerName: 'Access Level',
            width: isMobile ? 150 : 200,
            renderCell: ({ row: { accessLevel } }) => (
                <Box
                    width="60%"
                    m="0"
                    p="5px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor={
                        accessLevel === 'god'
                            ? colors.greenAccent[600]
                            : accessLevel === 'admin'
                                ? colors.greenAccent[700]
                                : accessLevel === 'inactive'
                                    ? colors.redAccent[600]
                                    : colors.greenAccent[700]
                    }
                    borderRadius="4px"
                >
                    {accessLevel === 'admin' && (
                        <AdminPanelSettingsOutlinedIcon />
                    )}
                    {accessLevel === 'god' && <SecurityOutlinedIcon />}
                    {accessLevel === 'active' && <LockOpenOutlinedIcon />}
                    {accessLevel === 'inactive' && <LockOutlinedIcon />}
                    <Typography
                        variant="h6"
                        color={colors.grey[100]}
                        sx={{ ml: '5px' }}
                    >
                        {accessLevel}
                    </Typography>
                </Box>
            ),
        },
    ];

    const CustomFooter = () => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
        const currentPage = useGridSelector(apiRef, gridPageSelector);
        const pageSize = apiRef.current.state.pagination.pageSize;
        const [selectedPageSize, setSelectedPageSize] = useState(
            pageSize || 10
        );

        const handleChangePageSize = (e) => {
            const newSize = parseInt(e.target.value, 10);
            setSelectedPageSize(newSize);
            apiRef.current.setPageSize(newSize);
        };

        const firstRowIndex = currentPage * selectedPageSize + 1;
        const lastRowIndex =
            (currentPage + 1) * selectedPageSize > apiRef.current.getRowsCount()
                ? apiRef.current.getRowsCount()
                : (currentPage + 1) * selectedPageSize;

        return (
            <Box
                display="flex"
                justifyContent={isMobile ? 'center' : 'space-between'}
                alignItems="center"
                p={isMobile ? 1 : 2}
                backgroundColor={colors.blueAccent[700]}
                height={isMobile ? 'auto' : '56px'}
                borderRadius="0 0 3px 3px"
                flexDirection={isMobile ? 'column' : 'row'}
            >
                {isMobile ? (
                    <>
                        {showAddToTeamButton && (
                            <FormControl
                                fullWidth
                                sx={{ mb: 2 }}
                                size="small"
                            >
                                <Select
                                    value={selectedAction}
                                    onChange={handleSelectAction}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select action' }}
                                    style={{
                                        border: '1px solid rgba(255, 255, 255, 0.23)',
                                        color: 'inherit',
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(0.5),
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select an action
                                    </MenuItem>
                                    <MenuItem value="Add to Team">Add to Team</MenuItem>
                                    <MenuItem value="Reset">Reset</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                        >
                            <Typography variant="h6" color={colors.grey[100]}>
                                Rows per page:
                            </Typography>
                            <FormControl
                                sx={{
                                    m: 1,
                                    minWidth: '80px',
                                }}
                                size="small"
                            >
                                <Select
                                    value={selectedPageSize}
                                    onChange={handleChangePageSize}
                                    style={{
                                        backgroundColor:
                                            selectedPageSize === ''
                                                ? colors.grey[500]
                                                : 'inherit',
                                        border: '1px solid rgba(255, 255, 255, 0.23)',
                                        color: 'inherit',
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(0.5),
                                    }}
                                >
                                    {[10, 25, 50].map((pageSizeOption) => (
                                        <MenuItem
                                            key={pageSizeOption}
                                            value={pageSizeOption}
                                        >
                                            {pageSizeOption}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography variant="h6" color={colors.grey[100]} ml={1}>
                                {`${firstRowIndex}-${lastRowIndex} of ${apiRef.current.getRowsCount()}`}
                            </Typography>
                            <MuiPagination
                                color="primary"
                                count={pageCount}
                                page={currentPage + 1}
                                onChange={(event, newPage) => {
                                    apiRef.current.setPage(newPage - 1);
                                }}
                                sx={{
                                    '.Mui-selected': {
                                        backgroundColor: colors.blueAccent[900],
                                        color: colors.grey[100],
                                    },
                                    '& .MuiPaginationItem-root:hover': {
                                        backgroundColor: colors.blueAccent[800],
                                    },
                                }}
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        {showAddToTeamButton && (
                            <Box display="flex" alignItems="center">
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => setModalOpen(true)}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        mb: isMobile ? 1 : 0,
                                        mr: isMobile ? 0 : 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Typography variant="h6">Add to Team</Typography>
                                </Button>
                                <Button
                                    onClick={handleResetButtonClick}
                                    sx={{
                                        display: 'block',
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.redAccent[700],
                                        },
                                        mb: isMobile ? 1 : 0,
                                        whiteSpace: 'nowrap',
                                    }}
                                    variant="contained"
                                    color="primary"
                                >
                                    <Typography variant="h6">Reset</Typography>
                                </Button>
                            </Box>
                        )}
                        <Box
                            display="flex"
                            justifyContent="right"
                            alignItems="center"
                            mt={isMobile ? 2 : 0}
                        >
                            <Typography variant="h6" color={colors.grey[100]}>
                                Rows per page:
                            </Typography>
                            <FormControl
                                sx={{
                                    m: 1,
                                    minWidth: '80px',
                                }}
                                size="small"
                            >
                                <Select
                                    value={selectedPageSize}
                                    onChange={handleChangePageSize}
                                    style={{
                                        backgroundColor:
                                            selectedPageSize === ''
                                                ? colors.grey[500]
                                                : 'inherit',
                                        border: '1px solid rgba(255, 255, 255, 0.23)',
                                        color: 'inherit',
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(0.5),
                                    }}
                                >
                                    {[10, 25, 50].map((pageSizeOption) => (
                                        <MenuItem
                                            key={pageSizeOption}
                                            value={pageSizeOption}
                                        >
                                            {pageSizeOption}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography variant="h6" color={colors.grey[100]} ml={1}>
                                {`${firstRowIndex}-${lastRowIndex} of ${apiRef.current.getRowsCount()}`}
                            </Typography>
                            <MuiPagination
                                color="primary"
                                count={pageCount}
                                page={currentPage + 1}
                                onChange={(event, newPage) => {
                                    apiRef.current.setPage(newPage - 1);
                                }}
                                sx={{
                                    '.Mui-selected': {
                                        backgroundColor: colors.blueAccent[900],
                                        color: colors.grey[100],
                                    },
                                    '& .MuiPaginationItem-root:hover': {
                                        backgroundColor: colors.blueAccent[800],
                                    },
                                }}
                            />
                        </Box>
                    </>
                )}
            </Box>
        );
    };

    return (
        <Box m="20px">
            <Header title="People" subtitle="View All Users" />
            {loading || !personData.length ? (
                <CircularLoading isOpen={loading} />
            ) : (
                <>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarType}
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    snackbarType === 'success'
                                        ? colors.greenAccent[600]
                                        : colors.redAccent[600],
                                color: 'white',
                            }}
                        >
                            <Typography variant="h6" color={colors.grey[100]}>
                                {snackbarMessage}
                            </Typography>
                        </Alert>
                    </Snackbar>
                    <Box
                        mt="40px"
                        height="75vh"
                        sx={{
                            '& .MuiDataGrid-root': {
                                border: 'none',
                                fontSize: isMobile ? '0.8rem' : '1rem',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                            '& .name-column--cell': {
                                color: colors.greenAccent[300],
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: 'none',
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                backgroundColor: colors.primary[400],
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderTop: 'none',
                                backgroundColor: colors.blueAccent[700],
                            },
                            '& .MuiCheckbox-root': {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        <DataGrid
                            checkboxSelection={showAddToTeamButton}
                            pagination
                            disableRowSelectionOnClick
                            pageSizeOptions={[10, 25, 50]}
                            slots={{ footer: CustomFooter }}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10 },
                                },
                            }}
                            rows={personDataMapped}
                            columns={columns}
                            onRowSelectionModelChange={(newSelection) => {
                                const selectedPersons = newSelection.map(
                                    (index) =>
                                        personDataMapped[index - 1].username
                                );
                                setCheckedUsernames(selectedPersons);
                                setCheckedPersons(newSelection);
                            }}
                            onRowClick={(params, event) => {
                                const isCheckboxClick =
                                    event.target.tagName === 'INPUT' &&
                                    event.target.type === 'checkbox';

                                if (!isCheckboxClick && showAddToTeamButton) {
                                    if (params.row) {
                                        handleOpenEditPersonModal(params.row.id);
                                    }
                                }
                            }}
                            rowSelectionModel={checkedPersons}
                        />
                    </Box>
                </>
            )}
            <TeamModal
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                checkedUsernames={checkedUsernames}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarType={setSnackbarType}
                setSnackbarOpen={setSnackbarOpen}
            />
            <EditPersonModal
                isEditPersonModalOpen={isEditPersonModalOpen}
                setEditPersonModalOpen={setEditPersonModalOpen}
                selectedPersonForEdit={selectedPersonForEdit}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarType={setSnackbarType}
                setSnackbarOpen={setSnackbarOpen}
                setSelectedPersonForEdit={setSelectedPersonForEdit}
                editData={editData}
                setEditData={setEditData}
            />
        </Box>
    );
};

export default People;
