import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    TextField,
    useMediaQuery,
    IconButton,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const PurposeEditModal = ({
    isPurposeEditModalOpen,
    setPurposeEditModalOpen,
    selectedInvoice,
    setSelectedInvoice,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [purpose, setPurpose] = useState(selectedInvoice?.purpose || '');
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setPurpose(selectedInvoice?.purpose || '');
    }, [selectedInvoice]);

    const handlePurposeChange = (event) => {
        setPurpose(event.target.value);
    };

    const handleClose = () => {
        setPurposeEditModalOpen(false);
        setPurpose('');
    };

    const handleSubmit = async () => {
        const controller = new AbortController();
        try {
            const updatedInvoice = {
                ...selectedInvoice,
                purpose: purpose,
            };
            await axiosPrivate.patch(
                `/invoice/invoice/${selectedInvoice.id}`,
                { purpose: purpose },
                { signal: controller.signal }
            );
            setSnackbarMessage(`Updated purpose for the Invoice successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            setSelectedInvoice(updatedInvoice);
            setPurposeEditModalOpen(false);
            setPurpose('');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setSnackbarMessage(error.response?.data.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                handleSubmit();
                break;
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal open={isPurposeEditModalOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '60%' : '50%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton edge="end" color="inherit" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ marginBottom: 2 }}
                    >
                        {`${selectedInvoice?.company}`}
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={8}
                        variant="filled"
                        value={purpose}
                        name="purpose"
                        onChange={handlePurposeChange}
                        sx={{
                            width: '100%',
                            mb: 2,
                        }}
                    />
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                mt: 2,
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) => handleSelectAction(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select action' }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Submit">Submit</MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                type="button"
                                onClick={handleSubmit}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default PurposeEditModal;
