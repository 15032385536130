import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import IconButton from '@mui/material/IconButton';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const DeleteInspectionModal = ({
    isDeleteModalOpen,
    setDeleteModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
    checkedInspections,
    setCheckedInspections,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000); // Updated condition

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDeleteInspections = async () => {
        const controller = new AbortController();
        try {
            for (const inspectionIndex of checkedInspections) {
                await axiosPrivate.delete(
                    `/inspection/inspection/${inspectionIndex}`,
                    {
                        signal: controller.signal,
                    }
                );
            }

            setSnackbarMessage(
                `Deleted ${checkedInspections.length} inspection(s) successfully`
            );
            setSnackbarType('success');
            setSnackbarOpen(true);

            setCheckedInspections([]);
            setDeleteModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    return (
        <Modal
            open={isDeleteModalOpen}
            onClose={() => {
                setDeleteModalOpen(false);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '70%' : '30%', // Adjust width based on isMobile
                    height: isMobile ? '30%' : '30%', // Adjust height based on isMobile
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '13%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setDeleteModalOpen(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        height: '60%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: isMobile ? '0 8%' : '0 16%', // Adjust padding based on isMobile
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        fontSize={isMobile ? '1.25rem' : '1.5rem'} // Adjust font size based on isMobile
                    >
                        {`Are you sure you want to delete ${checkedInspections.length} inspection(s)?`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        height: '20%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: isMobile ? '0 8%' : '0 16%', // Adjust padding based on isMobile
                    }}
                >
                    <Button
                        onClick={handleDeleteInspections}
                        variant="contained"
                        sx={{
                            color: 'white',
                            backgroundColor: colors.redAccent[500],
                            '&:hover': {
                                backgroundColor: colors.redAccent[700],
                            },
                            whiteSpace: 'nowrap', // Prevent text wrapping
                            mr: 1,
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => {
                            setDeleteModalOpen(false);
                        }}
                        variant="contained"
                        sx={{
                            color: 'white',
                            backgroundColor: colors.greenAccent[600],
                            '&:hover': {
                                backgroundColor: colors.greenAccent[700],
                            },
                            whiteSpace: 'nowrap', // Prevent text wrapping
                        }}
                    >
                        No
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteInspectionModal;
