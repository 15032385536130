import {
    InputLabel,
    Box,
    Typography,
    useTheme,
    Button,
    Select,
    MenuItem,
    Modal,
    TextField,
    FormControl,
    IconButton,
} from '@mui/material';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Formik } from 'formik';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as yup from 'yup';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DetailedViewEventDescModal from './DetailedViewEventDescModal';
import useProjectData from '../../hooks/useProjectData';
// Import the people and teams modals
import EditPeopleModal from './EditPeopleModal'; // To be created later
import EditTeamsModal from './EditTeamsModal';   // To be created later

const AddEventModal = (props) => {
    const {
        isAddModalOpen,
        setAddModalOpen,
        setSnackbarMessage,
        setSnackbarType,
        setSnackbarOpen,
        selectedDateRange
    } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000); // Updated condition
    const { dropdownData } = useProjectData();
    const [isDetailedViewOpen, setDetailedViewOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [selectedAction, setSelectedAction] = useState('');
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [isEditPeopleModalOpen, setEditPeopleModalOpen] = useState(false);
    const [isEditTeamsModalOpen, setEditTeamsModalOpen] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [isAddressSelectEnabled, setAddressSelectEnabled] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const checkoutSchema = yup.object().shape({
        title: yup.string().required('required'),
        description: yup.string().required('required'),
        start_date: yup.date().nullable().required('required'),
        end_date: yup.date().nullable().required('required'),
        work_type: yup.string().required('required'),
        status: yup.string().required('required'),
        address_id: yup.string().required('required'),
        project_id: yup.string().required('required'),
    });

    const handleProjectChange = async (event, setFieldValue) => {
        const selectedProjectId = event.target.value;
        if (selectedProjectId) {
            try {
                const response = await axiosPrivate.get(
                    `project/project/${selectedProjectId}/addresses`
                );
                setAddresses(response.data);
                setAddressSelectEnabled(true);
                setFieldValue('address_id', '');
                setFieldValue('project_id', selectedProjectId);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login', {
                        state: { from: location },
                        replace: true,
                    });
                } else {
                    setSnackbarMessage(
                        error.response?.data.message || 'Error occurred'
                    );
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                }
            }
        } else {
            setAddresses([]);
            setAddressSelectEnabled(false);
            setFieldValue('address_id', '');
            setFieldValue('project_id', '');
        }
    };

    const handleAddressChange = async (event, setFieldValue) => {
        const selectedAddressId = event.target.value;
        setFieldValue('address_id', selectedAddressId);
    };

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const data = {
                ...values,
                description: description,
                persons: selectedPeople.map(person => person.id),
                teams: selectedTeams.map(team => team.id),
            };
            if (values.start_date) {
                data.start_date = dayjs(values.start_date).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
            }
            if (values.end_date) {
                data.end_date = dayjs(values.end_date).format('YYYY-MM-DD HH:mm:ss');
            }

            const controller = new AbortController();

            await axiosPrivate.post(`/event/events`, data, {
                signal: controller.signal,
            });

            setSnackbarMessage(`Added event successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            resetForm();
            setDescription('');
            setAddressSelectEnabled(false);
            setAddresses([]);
            setSelectedPeople([]);
            setSelectedTeams([]);
            setAddModalOpen(false);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    err.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleReset = (resetForm, setFieldValue) => {
        setFieldValue('address_id', '');
        setFieldValue('project_id', '');
        resetForm();
        setSelectedPeople([]);
        setSelectedTeams([]);
        setDescription('');
        setAddressSelectEnabled(false);
        setAddresses([]);
    };

    const handleSelectAction = (event, handleSubmit, resetForm, setFieldValue) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                handleSubmit();
                break;
            case 'Reset':
                handleReset(resetForm, setFieldValue);
                break;
            case 'Close':
                setSelectedPeople([]);
                setSelectedTeams([]);
                setDescription('');
                setAddModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal
                open={isAddModalOpen}
                onClose={() => {
                    setDescription('');
                    setSelectedPeople([]);
                    setSelectedTeams([]);
                    setAddModalOpen(false);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        width: isMobile ? '70%' : '40%', // Adjust width based on isMobile
                        height: isMobile ? '70%' : '55%', // Adjust height based on isMobile
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            height: '7%',
                            width: '98%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                setAddModalOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            height: '10%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h4' : 'h2'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            Add Event:
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                            overflowY: 'auto',
                            p: isMobile ? 2 : 3, // Adjust padding for mobile
                        }}
                    >
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={{
                                title: '',
                                start_date: selectedDateRange ? dayjs(selectedDateRange.start) : null,
                                end_date: selectedDateRange ? dayjs(selectedDateRange.end) : null,
                                work_type: '',
                                status: '',
                                description: '',
                                address_id: '',
                                project_id: '',
                            }}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                resetForm,
                                setFieldValue,
                            }) => (
                                <form
                                    style={{ height: '100%' }}
                                    onSubmit={handleSubmit}
                                >
                                    <Box
                                        display="grid"
                                        gap={isMobile ? '16px' : '30px'}
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            '& > div': {
                                                gridColumn: isNonMobile
                                                    ? undefined
                                                    : 'span 4',
                                            },
                                        }}
                                    >
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 2' }}
                                        >
                                            <InputLabel id="project-label">
                                                Project
                                            </InputLabel>
                                            <Select
                                                labelId="project"
                                                id="project-select"
                                                fullWidth
                                                value={values.project_id}
                                                name="project_id"
                                                onChange={(event) => {
                                                    handleProjectChange(
                                                        event,
                                                        setFieldValue
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                                label="Project"
                                                error={
                                                    !!touched.project_id &&
                                                    !!errors.project_id
                                                }
                                            >
                                                {dropdownData.map(
                                                    (project) => (
                                                        <MenuItem
                                                            key={project.id}
                                                            value={project.id}
                                                        >
                                                            {project.title}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 2' }}
                                        >
                                            <InputLabel id="address-label">
                                                Address
                                            </InputLabel>
                                            <Select
                                                labelId="address"
                                                id="address-select"
                                                fullWidth
                                                value={values.address_id}
                                                onChange={(event) => {
                                                    handleAddressChange(
                                                        event,
                                                        setFieldValue
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                                label="Address"
                                                name="address_id"
                                                disabled={
                                                    !isAddressSelectEnabled
                                                }
                                                error={
                                                    !!touched.address_id &&
                                                    !!errors.address_id
                                                }
                                            >
                                                {addresses.map((address) => (
                                                    <MenuItem
                                                        key={address.id}
                                                        value={address.id}
                                                    >
                                                        {address.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            label="Title"
                                            value={values.title}
                                            name="title"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={
                                                !values.address_id
                                            }
                                            error={
                                                touched.title &&
                                                Boolean(errors.title)
                                            }
                                            helperText={
                                                touched.title && errors.title
                                            }
                                            sx={{ gridColumn: 'span 2' }}
                                        />
                                        <TextField
                                            fullWidth
                                            value={
                                                description.length > 30
                                                    ? `${description.substring(
                                                          0,
                                                          30
                                                      )}...`
                                                    : `${description}`
                                            }
                                            onClick={(event) => {
                                                if (
                                                    !event.target.tagName
                                                        .toLowerCase()
                                                        .includes('label')
                                                ) {
                                                    setDetailedViewOpen(true);
                                                }
                                            }}
                                            variant="filled"
                                            type="text"
                                            label="Description"
                                            error={touched.description && Boolean(errors.description)}
                                            helperText={touched.description && errors.description}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="description"
                                            disabled={
                                                !values.address_id
                                            }
                                            sx={{ gridColumn: 'span 2' }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        {isDetailedViewOpen && (
                                            <DetailedViewEventDescModal
                                                isDetailedViewOpen={
                                                    isDetailedViewOpen
                                                }
                                                setDetailedViewOpen={
                                                    setDetailedViewOpen
                                                }
                                                description={description}
                                                setDescription={(value) => {
                                                    setDescription(value);
                                                    setFieldValue('description', value); // Update Formik description value
                                                }}
                                            />
                                        )}
                                        <DateTimePicker
                                            fullWidth
                                            variant="filled"
                                            label="Start Date"
                                            onBlur={handleBlur}
                                            disabled={
                                                !values.address_id
                                            }
                                            onChange={(date) =>
                                                handleChange({
                                                    target: {
                                                        name: 'start_date',
                                                        value: date,
                                                    },
                                                })
                                            }
                                            value={values.start_date}
                                            name="start_date"
                                            sx={{ gridColumn: 'span 2' }}
                                        />
                                        <DateTimePicker
                                            fullWidth
                                            variant="filled"
                                            label="End Date"
                                            onBlur={handleBlur}
                                            disabled={
                                                !values.address_id
                                            }
                                            onChange={(date) =>
                                                handleChange({
                                                    target: {
                                                        name: 'end_date',
                                                        value: date,
                                                    },
                                                })
                                            }
                                            value={values.end_date}
                                            name="end_date"
                                            sx={{ gridColumn: 'span 2' }}
                                        />
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 2' }}
                                        >
                                            <InputLabel id="work-type-label">
                                                Work Type
                                            </InputLabel>
                                            <Select
                                                labelId="work-type"
                                                id="work-type-select"
                                                fullWidth
                                                value={values.work_type}
                                                onChange={(event) => {
                                                    setFieldValue('work_type', event.target.value);
                                                }}
                                                onBlur={handleBlur}
                                                disabled={
                                                    !values.address_id
                                                }
                                                label="Work Type"
                                                name="work_type"
                                                error={touched.work_type && Boolean(errors.work_type)}  // Add error display logic
                                                helperText={touched.work_type && errors.work_type}
                                            >
                                                <MenuItem value="inspection">Inspection</MenuItem>
                                                <MenuItem value="warranty">Warranty</MenuItem>
                                                <MenuItem value="trim">Trim</MenuItem>
                                                <MenuItem value="slab">Slab</MenuItem>
                                                <MenuItem value="rough">Rough</MenuItem>
                                                <MenuItem value="misc">Misc</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 2' }}
                                        >
                                            <InputLabel id="status-label">
                                                Status
                                            </InputLabel>
                                            <Select
                                                labelId="status"
                                                id="status-select"
                                                fullWidth
                                                value={values.status}
                                                onChange={(event) => {
                                                    setFieldValue('status', event.target.value);
                                                }}
                                                onBlur={handleBlur}
                                                disabled={
                                                    !values.address_id
                                                }
                                                label="Status"
                                                name="status"
                                                error={touched.status && Boolean(errors.status)}  // Add error display logic
                                                helperText={touched.status && errors.status}
                                            >
                                                <MenuItem value="in progress">In Progress</MenuItem>
                                                <MenuItem value="complete">Complete</MenuItem>
                                                <MenuItem value="postponed">Postponed</MenuItem>
                                                <MenuItem value="reschedule">Reschedule</MenuItem>
                                                <MenuItem value="canceled">Canceled</MenuItem>
                                                <MenuItem value="other">Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box
                                        display="grid"
                                        gap="20px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        mt={3}
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                setEditPeopleModalOpen(true)
                                            }
                                            sx={{
                                                gridColumn: 'span 2',
                                                backgroundColor:
                                                    colors.blueAccent[500],
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor:
                                                        colors.blueAccent[700],
                                                },
                                            }}
                                        >
                                            People
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                setEditTeamsModalOpen(true)
                                            }
                                            sx={{
                                                gridColumn: 'span 2',
                                                backgroundColor:
                                                    colors.blueAccent[500],
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor:
                                                        colors.blueAccent[700],
                                                },
                                            }}
                                        >
                                            Teams
                                        </Button>
                                    </Box>
                                    {/* People and Teams Modals */}
                                    {isEditPeopleModalOpen && (
                                        <EditPeopleModal
                                            isEditPeopleModalOpen={isEditPeopleModalOpen}
                                            setEditPeopleModalOpen={setEditPeopleModalOpen}
                                            selectedPeople={selectedPeople}
                                            setSelectedPeople={setSelectedPeople}
                                        />
                                    )}
                                    {isEditTeamsModalOpen && (
                                        <EditTeamsModal
                                            isEditTeamsModalOpen={isEditTeamsModalOpen}
                                            setEditTeamsModalOpen={setEditTeamsModalOpen}
                                            selectedTeams={selectedTeams}
                                            setSelectedTeams={setSelectedTeams}
                                        />
                                    )}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gap: 2,
                                            mt: 2,
                                            mb: 3,
                                        }}
                                    >
                                        {isMobile ? (
                                            <FormControl sx={{ width: '100%', borderRadius: '8px' }}>
                                                <Select
                                                    value={selectedAction}
                                                    onChange={(event) => handleSelectAction(event, handleSubmit, resetForm, setFieldValue)}
                                                    displayEmpty
                                                    sx={{
                                                        color: colors.grey[100],
                                                        backgroundColor: colors.primary[400],
                                                        borderRadius: '8px',
                                                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor: colors.grey[300],
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: colors.grey[100],
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: colors.grey[100],
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Select an action
                                                    </MenuItem>
                                                    <MenuItem value="Submit">
                                                        Submit
                                                    </MenuItem>
                                                    <MenuItem value="Reset">
                                                        Reset
                                                    </MenuItem>
                                                    <MenuItem value="Close">
                                                        Close
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.greenAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.greenAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    onClick={() => {
                                                        handleReset(
                                                            resetForm,
                                                            setFieldValue
                                                        );
                                                    }}
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.redAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.redAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        setDescription('');
                                                        setSelectedPeople([]);
                                                        setSelectedTeams([]);
                                                        setAddModalOpen(false);
                                                    }}
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.redAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.redAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    variant="contained"
                                                >
                                                    Close
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </LocalizationProvider>
    );
};

export default AddEventModal;
