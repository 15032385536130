import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    TextField,
    useMediaQuery,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import DocDescriptionEditModal from './DocDescriptionEditModal';
import { calculateShowAddToTeamButton } from '../../people/RoleManager';
import useAuth from '../../../hooks/useAuth';

const ViewDocDescriptionModal = ({
    isViewDocDescriptionModalOpen,
    setViewDocDescriptionModalOpen,
    selectedDocument,
    setSelectedDocument,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [isDocDescriptionEditModalOpen, setDocDescriptionEditModalOpen] =
        useState(false);
    const { auth } = useAuth();
    const showAddToTeamButton = calculateShowAddToTeamButton(auth.roles);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Edit Description':
                setDocDescriptionEditModalOpen(true);
                break;
            case 'Close':
                setViewDocDescriptionModalOpen(false);
                setSelectedDocument(null);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal
            open={isViewDocDescriptionModalOpen}
            onClose={() => {
                setViewDocDescriptionModalOpen(false);
                setSelectedDocument(null);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%', // Adjust width for different screen sizes
                    height: isMobile ? '55%' : '50%', // Adjust height for different screen sizes
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setViewDocDescriptionModalOpen(false);
                            setSelectedDocument(null);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        px: 3, // Padding for better spacing
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h6' : 'h5'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            {selectedDocument?.title}
                        </Typography>

                    </Box>
                    <Box
                        display="grid"
                        gap="10px"
                        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                        sx={{
                            width: '100%',
                            '& > div': {
                                gridColumn: 'span 1',
                            },
                        }}
                    >
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            textAlign="center"
                        >
                            {'Description'}
                        </Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={10}
                            variant="filled"
                            value={selectedDocument?.description || ''}
                            name="description"
                            readOnly
                            disabled
                            sx={{ marginBottom: theme.spacing(2) }}
                        />
                        {isMobile && (
                            <FormControl
                                fullWidth
                                sx={{
                                    borderRadius: '8px',
                                    alignSelf: 'center',
                                }}
                            >
                                <Select
                                    value={selectedAction}
                                    onChange={(e) =>
                                        handleSelectAction(e.target.value)
                                    }
                                    displayEmpty
                                    inputProps={{
                                        'aria-label': 'Select action',
                                    }}
                                    sx={{
                                        color: colors.grey[100],
                                        backgroundColor: colors.primary[400],
                                        borderRadius: '8px',
                                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: colors.grey[300],
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: colors.grey[100],
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: colors.grey[100],
                                        },
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select an action
                                    </MenuItem>
                                    {showAddToTeamButton && (
                                        <MenuItem value="Edit Description">
                                            Edit Description
                                        </MenuItem>
                                    )}
                                    <MenuItem value="Close">Close</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    {!isMobile && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: theme.spacing(1),
                                mt: 2,
                            }}
                        >
                            {showAddToTeamButton && (
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setDocDescriptionEditModalOpen(true);
                                    }}
                                    sx={{
                                        color: 'white',
                                        backgroundColor:
                                            colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.greenAccent[700],
                                        },
                                        whiteSpace: 'nowrap',
                                    }}
                                    variant="contained"
                                >
                                    Edit Description
                                </Button>
                            )}
                            <Button
                                type="button"
                                onClick={() => {
                                    setViewDocDescriptionModalOpen(false);
                                    setSelectedDocument(null);
                                }}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor:
                                            colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
                <DocDescriptionEditModal
                    isDocDescriptionEditModalOpen={
                        isDocDescriptionEditModalOpen
                    }
                    setDocDescriptionEditModalOpen={
                        setDocDescriptionEditModalOpen
                    }
                    selectedDocument={selectedDocument}
                    setSelectedDocument={setSelectedDocument}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarType={setSnackbarType}
                    setSnackbarOpen={setSnackbarOpen}
                />
            </Box>
        </Modal>
    );
};

export default ViewDocDescriptionModal;
