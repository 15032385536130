import {
    InputLabel,
    Box,
    Typography,
    useTheme,
    Button,
    Select,
    MenuItem,
    Modal,
    TextField,
    FormControl,
    IconButton,
} from '@mui/material';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Formik } from 'formik';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as yup from 'yup';
import useProjectData from '../../hooks/useProjectData';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DetailedViewModal from './DetailedViewModal';

const AddInspectionModal = (props) => {
    const {
        isAddModalOpen,
        setAddModalOpen,
        setSnackbarMessage,
        setSnackbarType,
        setSnackbarOpen,
    } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000); // Updated condition
    const { dropdownData } = useProjectData();
    const [addresses, setAddresses] = useState([]);
    const [isAddressSelectEnabled, setAddressSelectEnabled] = useState(false);
    const [isDetailedViewOpen, setDetailedViewOpen] = useState(false);
    const [reason, setReason] = useState('');
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleProjectChange = async (event, setFieldValue) => {
        const selectedProjectId = event.target.value;
        if (selectedProjectId) {
            try {
                const response = await axiosPrivate.get(
                    `project/project/${selectedProjectId}/addresses`
                );
                setAddresses(response.data);
                setAddressSelectEnabled(true);
                setFieldValue('address_id', '');
                setFieldValue('project_id', selectedProjectId);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login', {
                        state: { from: location },
                        replace: true,
                    });
                } else {
                    setSnackbarMessage(
                        error.response?.data.message || 'Error occurred'
                    );
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                }
            }
        } else {
            setAddresses([]);
            setAddressSelectEnabled(false);
            setFieldValue('address_id', '');
            setFieldValue('project_id', '');
        }
    };

    const handleAddressChange = async (event, setFieldValue) => {
        const selectedAddressId = event.target.value;
        setFieldValue('address_id', selectedAddressId);
    };

    const checkoutSchema = yup.object().shape({
        inspection_type: yup.string().required('required'),
        call_in_date: yup.date().nullable().notRequired(),
        reason: yup.string().nullable().notRequired(),
        scheduled_fix_date: yup.date().nullable().notRequired(),
        scheduled_follow_up_date: yup.date().nullable().notRequired(),
        pass_fail: yup.string().nullable().notRequired(),
        address_id: yup.string().required('required'),
        project_id: yup.string().required('required'),
    });

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const data = {
                ...values,
                reason: reason,
            };
            // Convert pass_fail values
            if (data.pass_fail === 'pass') {
                data.pass_fail = true;
            } else if (data.pass_fail === 'fail') {
                data.pass_fail = false;
            } else {
                data.pass_fail = null;
            }
            // Format dates only if they exist
            if (values.call_in_date) {
                data.call_in_date = dayjs(values.call_in_date).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
            }
            if (values.scheduled_fix_date) {
                data.scheduled_fix_date = dayjs(
                    values.scheduled_fix_date
                ).format('YYYY-MM-DD HH:mm:ss');
            }
            if (values.scheduled_follow_up_date) {
                data.scheduled_follow_up_date = dayjs(
                    values.scheduled_follow_up_date
                ).format('YYYY-MM-DD HH:mm:ss');
            }

            const controller = new AbortController();

            await axiosPrivate.post(`/inspection/inspections`, data, {
                signal: controller.signal,
            });

            setSnackbarMessage(`Created inspection successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            resetForm();
            setReason('');
            setAddressSelectEnabled(false);
            setAddresses([]);
            setAddModalOpen(false);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    err.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleReset = (resetForm, setFieldValue) => {
        setFieldValue('address_id', '');
        setFieldValue('project_id', '');
        resetForm();
        setReason('');
        setAddressSelectEnabled(false);
        setAddresses([]);
    };

    const handleSelectAction = (event, handleSubmit, resetForm, setFieldValue) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                handleSubmit(); // Let Formik handle the submit
                break;
            case 'Reset':
                handleReset(resetForm, setFieldValue);
                break;
            case 'Close':
                setAddModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal
                open={isAddModalOpen}
                onClose={() => {
                    setAddModalOpen(false);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        width: isMobile ? '70%' : '40%', // Adjust width based on isMobile
                        height: isMobile ? '70%' : '70%', // Adjust height based on isMobile
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            height: '7%',
                            width: '98%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                setAddModalOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            height: '10%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h4' : 'h2'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            Add Inspection:
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                            overflowY: 'auto',
                            p: isMobile ? 2 : 3, // Adjust padding for mobile
                        }}
                    >
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={{
                                inspection_type: '',
                                call_in_date: null,
                                scheduled_fix_date: null,
                                scheduled_follow_up_date: null,
                                pass_fail: '',
                                address_id: '',
                                project_id: '',
                            }}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                resetForm,
                                setFieldValue,
                            }) => (
                                <form
                                    style={{ height: '100%' }}
                                    onSubmit={handleSubmit}
                                >
                                    <Box
                                        display="grid"
                                        gap={isMobile ? '16px' : '30px'}
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            '& > div': {
                                                gridColumn: isNonMobile
                                                    ? undefined
                                                    : 'span 4',
                                            },
                                        }}
                                    >
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 4' }}
                                        >
                                            <InputLabel id="project-label">
                                                Project
                                            </InputLabel>
                                            <Select
                                                labelId="project"
                                                id="project-select"
                                                fullWidth
                                                value={values.project_id}
                                                name="project_id"
                                                onChange={(event) => {
                                                    handleProjectChange(
                                                        event,
                                                        setFieldValue
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                                label="Project"
                                                error={
                                                    !!touched.project_id &&
                                                    !!errors.project_id
                                                }
                                            >
                                                {dropdownData.map(
                                                    (project) => (
                                                        <MenuItem
                                                            key={project.id}
                                                            value={project.id}
                                                        >
                                                            {project.title}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 4' }}
                                        >
                                            <InputLabel id="address-label">
                                                Address
                                            </InputLabel>
                                            <Select
                                                labelId="address"
                                                id="address-select"
                                                fullWidth
                                                value={values.address_id}
                                                onChange={(event) => {
                                                    handleAddressChange(
                                                        event,
                                                        setFieldValue
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                                label="Address"
                                                name="address_id"
                                                disabled={
                                                    !isAddressSelectEnabled
                                                }
                                                error={
                                                    !!touched.address_id &&
                                                    !!errors.address_id
                                                }
                                            >
                                                {addresses.map((address) => (
                                                    <MenuItem
                                                        key={address.id}
                                                        value={address.id}
                                                    >
                                                        {address.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 2' }}
                                        >
                                            <InputLabel id="inspection-type-label">
                                                Inspection Type
                                            </InputLabel>
                                            <Select
                                                labelId="inspection-type"
                                                id="inspection-type-select"
                                                fullWidth
                                                value={
                                                    values.inspection_type
                                                }
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'inspection_type',
                                                        event.target.value
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                                label="Inspection Type"
                                                name="inspection_type"
                                                disabled={
                                                    !values.address_id
                                                }
                                                error={
                                                    !!touched.inspection_type &&
                                                    !!errors.inspection_type
                                                }
                                            >
                                                <MenuItem value="slab">
                                                    Slab
                                                </MenuItem>
                                                <MenuItem value="rough">
                                                    Rough
                                                </MenuItem>
                                                <MenuItem value="final">
                                                    Final
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <DateTimePicker
                                            fullWidth
                                            variant="filled"
                                            label="Call in date"
                                            onBlur={handleBlur}
                                            onChange={(date) =>
                                                handleChange({
                                                    target: {
                                                        name: 'call_in_date',
                                                        value: date,
                                                    },
                                                })
                                            }
                                            value={values.call_in_date}
                                            name="call_in_date"
                                            sx={{ gridColumn: 'span 2' }}
                                            disabled={
                                                !values.address_id ||
                                                !values.inspection_type
                                            }
                                        />
                                        <DateTimePicker
                                            fullWidth
                                            variant="filled"
                                            label="Fix date"
                                            onBlur={handleBlur}
                                            onChange={(date) =>
                                                handleChange({
                                                    target: {
                                                        name: 'scheduled_fix_date',
                                                        value: date,
                                                    },
                                                })
                                            }
                                            value={
                                                values.scheduled_fix_date
                                            }
                                            name="scheduled_fix_date"
                                            sx={{ gridColumn: 'span 2' }}
                                            disabled={
                                                !values.address_id ||
                                                !values.inspection_type
                                            }
                                        />
                                        <DateTimePicker
                                            fullWidth
                                            variant="filled"
                                            label="Follow up date"
                                            onBlur={handleBlur}
                                            onChange={(date) =>
                                                handleChange({
                                                    target: {
                                                        name: 'scheduled_follow_up_date',
                                                        value: date,
                                                    },
                                                })
                                            }
                                            value={
                                                values.scheduled_follow_up_date
                                            }
                                            name="scheduled_follow_up_date"
                                            sx={{ gridColumn: 'span 2' }}
                                            disabled={
                                                !values.address_id ||
                                                !values.inspection_type
                                            }
                                        />
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 2' }}
                                        >
                                            <InputLabel id="inspection-status-label">
                                                Inspection Status
                                            </InputLabel>
                                            <Select
                                                labelId="inspection-status"
                                                id="inspection-status-select"
                                                fullWidth
                                                value={values.pass_fail}
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'pass_fail',
                                                        event.target.value
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                                label="Inspection Status"
                                                disabled={
                                                    !values.address_id ||
                                                    !values.inspection_type
                                                }
                                                name="pass_fail"
                                            >
                                                <MenuItem value="">
                                                    {' '}
                                                </MenuItem>
                                                <MenuItem value="pass">
                                                    Pass
                                                </MenuItem>
                                                <MenuItem value="fail">
                                                    Fail
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            value={`${reason.substring(0, 30)}...`}
                                            onClick={(event) => {
                                                if (
                                                    !event.target.tagName
                                                        .toLowerCase()
                                                        .includes('label')
                                                ) {
                                                    setDetailedViewOpen(
                                                        true
                                                    );
                                                }
                                            }}
                                            variant="filled"
                                            type="text"
                                            label="Reason"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="reason"
                                            sx={{ gridColumn: 'span 2' }}
                                            disabled={
                                                !values.address_id ||
                                                !values.inspection_type
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        {isDetailedViewOpen && (
                                            <DetailedViewModal
                                                isDetailedViewOpen={
                                                    isDetailedViewOpen
                                                }
                                                setDetailedViewOpen={
                                                    setDetailedViewOpen
                                                }
                                                reason={reason}
                                                setReason={setReason}
                                            />
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gap: 2,
                                            mt: 2,
                                            mb: 3,
                                        }}
                                    >
                                        {isMobile ? (
                                            <FormControl sx={{ width: '100%', borderRadius: '8px' }}>
                                                <Select
                                                    value={selectedAction}
                                                    onChange={(event) => handleSelectAction(event, handleSubmit, resetForm, setFieldValue)}
                                                    displayEmpty
                                                    sx={{
                                                        color: colors.grey[100],
                                                        backgroundColor: colors.primary[400],
                                                        borderRadius: '8px',
                                                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor: colors.grey[300],
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: colors.grey[100],
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: colors.grey[100],
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Select an action
                                                    </MenuItem>
                                                    <MenuItem value="Submit">
                                                        Submit
                                                    </MenuItem>
                                                    <MenuItem value="Reset">
                                                        Reset
                                                    </MenuItem>
                                                    <MenuItem value="Close">
                                                        Close
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.greenAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.greenAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    onClick={() => {
                                                        handleReset(
                                                            resetForm,
                                                            setFieldValue
                                                        );
                                                    }}
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.redAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.redAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        setAddModalOpen(false);
                                                    }}
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.redAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.redAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    variant="contained"
                                                >
                                                    Close
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </LocalizationProvider>
    );
};

export default AddInspectionModal;
