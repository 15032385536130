import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001/api/v1'; // Include /api/v1
// const BASE_URL = 'http://172.29.80.1:5000';

export default axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
});
