import { Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { tokens } from '../theme';
import { useState, useEffect } from 'react';

const Header = ({ title, subtitle }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)'); // Assuming non-mobile is screens wider than 600px
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1050);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1050);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box mb={isMobile ? "20px" : "30px"}>
            <Typography
                variant={isMobile ? "h4" : "h2"}
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ mb: '5px' }}
            >
                {title}
            </Typography>
            <Typography
                variant={isMobile ? "h6" : "h5"}
                color={colors.greenAccent[400]}
            >
                {subtitle}
            </Typography>
        </Box>
    );
};

export default Header;
