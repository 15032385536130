import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    IconButton,
    useTheme,
    Select,
    MenuItem,
    useMediaQuery,
    FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import * as yup from 'yup';
import { Formik } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const InvoiceDateModal = ({
    isInvoiceDateModalOpen,
    setInvoiceDateModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
    selectedInvoicesPerWarranty,
    setSelectedInvoicesPerWarranty,
    selectedInvoice,
    setSelectedInvoice,
    selectedWarranty,
    setSelectedWarranty,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleInvoiceDateInvoices = async (values) => {
        const formattedDate = dayjs(values.invoice_date).format('YYYY-MM-DD HH:mm:ss');
        const controller = new AbortController();
        try {
            if (selectedInvoice) {
                await axiosPrivate.patch(
                    `/warranty/warranty/${selectedWarranty.id}/invoice/${selectedInvoice.id}`,
                    { invoice_date: formattedDate },
                    { signal: controller.signal }
                );
                setSnackbarMessage('Updated invoice date for the invoice successfully');
            } else {
                for (const invoiceIndex of selectedInvoicesPerWarranty[selectedWarranty.id]) {
                    await axiosPrivate.patch(
                        `/warranty/warranty/${selectedWarranty.id}/invoice/${invoiceIndex}`,
                        { invoice_date: formattedDate },
                        { signal: controller.signal }
                    );
                }
                setSnackbarMessage(
                    `Updated invoice date for ${selectedInvoicesPerWarranty[selectedWarranty.id].length} invoice(s) successfully`
                );
            }

            setSnackbarType('success');
            setSnackbarOpen(true);

            setSelectedInvoicesPerWarranty({ ...selectedInvoicesPerWarranty, [selectedWarranty.id]: [] });
            setSelectedWarranty(null);
            setSelectedInvoice(null);

            setInvoiceDateModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setSnackbarMessage(error.response?.data.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = (action, handleSubmit) => {
        setSelectedAction(action);

        switch (action) {
            case 'Set Date':
                handleSubmit();
                break;
            case 'Close':
                setInvoiceDateModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const validationSchema = yup.object().shape({
        invoice_date: yup.date().required('Invoice date is required'),
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal
                open={isInvoiceDateModalOpen}
                onClose={() => setInvoiceDateModalOpen(false)}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        width: isMobile ? '80%' : '30%',
                        height: isMobile ? '50%' : '30%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            height: '7%',
                            width: '98%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setInvoiceDateModalOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h6' : 'h5'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                            textAlign="center"
                            sx={{ marginBottom: 2 }}
                        >
                            Set Invoice Date
                        </Typography>
                        <Formik
                            onSubmit={handleInvoiceDateInvoices}
                            initialValues={{ invoice_date: dayjs(new Date()) }}
                            validationSchema={validationSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                            }) => (
                                <form style={{ height: '100%', width: '100%' }} onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                        sx={{
                                            margin: '10% 15% 10% 15%',
                                            '& > div': {
                                                gridColumn: 'span 1',
                                            },
                                        }}
                                    >
                                        <DateTimePicker
                                            fullWidth
                                            variant="filled"
                                            label="Invoice Date"
                                            onBlur={handleBlur}
                                            onChange={(date) =>
                                                handleChange({
                                                    target: { name: 'invoice_date', value: date },
                                                })
                                            }
                                            value={values.invoice_date}
                                            name="invoice_date"
                                            error={!!touched.invoice_date && !!errors.invoice_date}
                                            helperText={touched.invoice_date && errors.invoice_date}
                                            sx={{ gridColumn: 'span 1' }}
                                        />
                                    </Box>
                                    {isMobile ? (
                                        <FormControl
                                            sx={{
                                                width: '100%',
                                                borderRadius: '8px',
                                                mt: 2,
                                                alignSelf: 'center',
                                            }}
                                        >
                                            <Select
                                                value={selectedAction}
                                                onChange={(e) =>
                                                    handleSelectAction(e.target.value, handleSubmit)
                                                }
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Select action' }}
                                                sx={{
                                                    color: colors.grey[100],
                                                    backgroundColor: colors.primary[400],
                                                    borderRadius: '8px',
                                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors.grey[300],
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors.grey[100],
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        color: colors.grey[100],
                                                    },
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Select an action
                                                </MenuItem>
                                                <MenuItem value="Set Date">Set Date</MenuItem>
                                                <MenuItem value="Close">Close</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Box
                                            sx={{
                                                bottom: '5%',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                gap: theme.spacing(1),
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: colors.greenAccent[500],
                                                    '&:hover': {
                                                        backgroundColor: colors.greenAccent[700],
                                                    },
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Set Date
                                            </Button>
                                            <Button
                                                type="button"
                                                onClick={() => setInvoiceDateModalOpen(false)}
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: colors.redAccent[500],
                                                    '&:hover': {
                                                        backgroundColor: colors.redAccent[700],
                                                    },
                                                    whiteSpace: 'nowrap',
                                                }}
                                                variant="contained"
                                            >
                                                Close
                                            </Button>
                                        </Box>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </LocalizationProvider>
    );
};

export default InvoiceDateModal;
