import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    TextField,
    useMediaQuery,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAuth from '../../hooks/useAuth';

const ChangePasswordModal = ({
    isChangePasswordModalOpen,
    setChangePasswordModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedAction, setSelectedAction] = useState('');
    const { auth } = useAuth();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => {
        setChangePasswordModalOpen(false);
        formik.resetForm();
    };

    const handleSubmit = async (values) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.patch(
                `/auth/person/${auth.user_id}/change_password`,
                {
                    current: values.current,
                    new: values.new,
                },
                {
                    signal: controller.signal,
                }
            );

            setSnackbarMessage(`Password updated successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            setChangePasswordModalOpen(false);
            formik.resetForm();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                formik.handleSubmit();
                break;
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const validationSchema = Yup.object().shape({
        current: Yup.string().required('Current password is required'),
        new: Yup.string().required('New password is required'),
        confirm: Yup.string()
            .oneOf([Yup.ref('new'), null], 'Passwords must match')
            .required('Confirm new password is required'),
    });

    const formik = useFormik({
        initialValues: {
            current: '',
            new: '',
            confirm: '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <Modal open={isChangePasswordModalOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '55%' : '45%',
                    maxHeight: '90%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: isMobile ? 2 : 3,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                        mb={2}
                    >
                        Change Password
                    </Typography>
                    <TextField
                        fullWidth
                        variant="filled"
                        label="Current Password"
                        type="password"
                        value={formik.values.current}
                        name="current"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.current && Boolean(formik.errors.current)}
                        helperText={formik.touched.current && formik.errors.current}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        label="New Password"
                        type="password"
                        value={formik.values.new}
                        name="new"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.new && Boolean(formik.errors.new)}
                        helperText={formik.touched.new && formik.errors.new}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        label="Confirm New Password"
                        type="password"
                        value={formik.values.confirm}
                        name="confirm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.confirm && Boolean(formik.errors.confirm)}
                        helperText={formik.touched.confirm && formik.errors.confirm}
                        sx={{ mb: 2 }}
                    />
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                mt: 2,
                                alignSelf: 'center',
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) => handleSelectAction(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select action' }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Submit">Submit</MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                onClick={formik.handleSubmit}
                                type="submit"
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default ChangePasswordModal;
