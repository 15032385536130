import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    Checkbox,
    IconButton,
    useMediaQuery,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const DeleteWarrantyModal = ({
    isDeleteWarrantyModalOpen,
    setDeleteWarrantyModalOpen,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
    selectedRows,
    setSelectedRows,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');
    const [isDocDelChecked, setIsDocDelChecked] = useState(false);
    const [isPictureDelChecked, setIsPictureDelChecked] = useState(false);
    const [isInvoiceDelChecked, setIsInvoiceDelChecked] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDeleteWarranties = async () => {
        const controller = new AbortController();
        let failures = false;
        try {
            const payload = {
                delete_pictures: isPictureDelChecked,
                invoices: {
                    delete_invoices: isInvoiceDelChecked,
                    delete_documents: isDocDelChecked,
                },
            };
            for (const warrantyIndex of selectedRows || []) {
                const response = await axiosPrivate.delete(
                    `/warranty/warranty/${warrantyIndex}`,
                    {
                        signal: controller.signal,
                        data: payload,
                    }
                );
                if (response.status === 207) {
                    failures = true;
                }
            }
            if (failures) {
                setSnackbarMessage(
                    `Deleted ${selectedRows.length} warranties(s) but 1 or more child resources couldn't be deleted`
                );
                setSnackbarType('warning');
            } else {
                setSnackbarMessage(
                    `Deleted ${selectedRows.length} warranties(s) successfully`
                );
                setSnackbarType('success');
            }
            setSnackbarOpen(true);
            setSelectedRows([]);
            resetState();
            setDeleteWarrantyModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const resetState = () => {
        setIsDocDelChecked(false);
        setIsPictureDelChecked(false);
        setIsInvoiceDelChecked(false);
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Yes':
                handleDeleteWarranties();
                break;
            case 'No':
                setDeleteWarrantyModalOpen(false);
                resetState();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal
            open={isDeleteWarrantyModalOpen}
            onClose={() => {
                setDeleteWarrantyModalOpen(false);
                resetState();
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '50%' : '40%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '10%',
                        width: '97%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setDeleteWarrantyModalOpen(false);
                            resetState();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        {`Are you sure you want to delete ${selectedRows?.length} warranties(s)?`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: isMobile ? 2 : 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 1,
                        }}
                    >
                        <Checkbox
                            color="primary"
                            checked={isPictureDelChecked}
                            onChange={() =>
                                setIsPictureDelChecked(!isPictureDelChecked)
                            }
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            Delete attached pictures
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 1,
                        }}
                    >
                        <Checkbox
                            color="primary"
                            checked={isInvoiceDelChecked}
                            onChange={() => {
                                setIsInvoiceDelChecked(!isInvoiceDelChecked);
                                if (!isInvoiceDelChecked && isDocDelChecked) {
                                    setIsDocDelChecked(false);
                                }
                            }}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            Delete attached invoices
                        </Typography>
                    </Box>
                    {isInvoiceDelChecked && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                color="primary"
                                disabled={!isInvoiceDelChecked}
                                checked={isDocDelChecked && isInvoiceDelChecked}
                                onChange={() =>
                                    setIsDocDelChecked(!isDocDelChecked)
                                }
                            />
                            <Typography
                                variant="h6"
                                color={colors.grey[100]}
                                fontWeight="bold"
                            >
                                Delete attached documents
                            </Typography>
                        </Box>
                    )}
                    {isMobile ? (
                        <FormControl
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                mt: 2,
                                alignSelf: 'center',
                            }}
                        >
                            <Select
                                value={selectedAction}
                                onChange={(e) =>
                                    handleSelectAction(e.target.value)
                                }
                                displayEmpty
                                inputProps={{
                                    'aria-label': 'Select action',
                                }}
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                onClick={handleDeleteWarranties}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    mr: 1,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => {
                                    setDeleteWarrantyModalOpen(false);
                                    resetState();
                                }}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[600],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                No
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteWarrantyModal;
