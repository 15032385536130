// src/components/ProfilePage.jsx

import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    Button,
    Snackbar,
    Alert,
    useTheme,
    Grid,
    useMediaQuery,
    IconButton,
    Stack,
} from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import useAvatarUrl from '../../hooks/useAvatarUrl';
import { useNavigate } from 'react-router-dom';
import EditableField from '../../components/EditableField';
import PasswordConfirmationModal from './PasswordConfirmationModal';
import * as Yup from 'yup';
import useUserProfile from '../../hooks/useUserProfile';
import CircularLoading from '../global/CircularLoading';
import EditIcon from '@mui/icons-material/Edit';
import { tokens } from '../../theme';
import ImageUploadModal from '../people/ImageUploadModal'; // Import ImageUploadModal

/**
 * ProfilePage Component
 */
const ProfilePage = () => {
    // Existing state variables
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [pendingUpdates, setPendingUpdates] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [editingFields, setEditingFields] = useState({});
    const controllerRef = useRef(new AbortController());
    const { loading, profileData, fetchProfile } = useUserProfile();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { auth, setAuth } = useAuth();
    const fetchAvatarUrl = useAvatarUrl();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    // New state variables for avatar management
    const [isAvatarModalOpen, setAvatarModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageSelected, setImageSelected] = useState(false);
    const [avatarKey, setAvatarKey] = useState(0); // To force re-render AvatarManager

    // New state variables for previewing the selected avatar
    const [newAvatar, setNewAvatar] = useState(null);
    const [newAvatarUrl, setNewAvatarUrl] = useState(null);
    const [isUploading, setIsUploading] = useState(false); // New state variable for upload status

    /**
     * Effect to create and clean up object URL for the new avatar preview
     */
    useEffect(() => {
        let objectUrl = null;

        if (newAvatar instanceof Blob) {
            objectUrl = URL.createObjectURL(newAvatar);
            setNewAvatarUrl(objectUrl);

            // Clean up the object URL when newAvatar changes or component unmounts
            return () => {
                URL.revokeObjectURL(objectUrl);
                setNewAvatarUrl(null);
            };
        } else {
            setNewAvatarUrl(null);
        }
    }, [newAvatar]);


    /**
     * Fetch avatar URL if not already present
     */
    useEffect(() => {
        if (auth.user_id && !auth.avatarUrl) {
            fetchAvatarUrl(auth.user_id);
        }
    }, [auth.user_id, auth.avatarUrl, fetchAvatarUrl]);

    /**
     * Check if avatar URL has expired and refresh if necessary
     */
    const checkAvatarExpiration = useCallback(() => {
        if (auth.avatarUrl) {
            try {
                const url = new URL(auth.avatarUrl);
                const amzDate = url.searchParams.get('X-Amz-Date');
                const amzExpires = parseInt(url.searchParams.get('X-Amz-Expires'), 10);

                if (amzDate && !isNaN(amzExpires)) {
                    const urlTimestamp = new Date(
                        Date.UTC(
                            parseInt(amzDate.slice(0, 4)),
                            parseInt(amzDate.slice(4, 6)) - 1,
                            parseInt(amzDate.slice(6, 8)),
                            parseInt(amzDate.slice(9, 11)),
                            parseInt(amzDate.slice(11, 13)),
                            parseInt(amzDate.slice(13, 15))
                        )
                    );

                    const expirationTime = new Date(urlTimestamp.getTime() + amzExpires * 1000);
                    const currentTime = new Date();
                    if (currentTime >= expirationTime) {
                        // Fetch a new avatar URL
                        fetchAvatarUrl(auth.user_id);
                    }
                }
            } catch (error) {
                console.error('Error checking avatar URL expiration:', error);
            }
        }
    }, [auth.avatarUrl, auth.user_id, fetchAvatarUrl]);

    /**
     * Handle avatar expiration checks
     */
    const handleAvatarExpiration = useCallback(() => {
        if (auth.avatarUrl) {
            checkAvatarExpiration();
            // Set an interval to check periodically
            const interval = setInterval(checkAvatarExpiration, 60000); // Check every minute
            return () => clearInterval(interval);
        }
    }, [auth.avatarUrl, checkAvatarExpiration]);

    // Invoke avatar expiration handler
    useEffect(() => {
        const cleanup = handleAvatarExpiration();
        return () => {
            if (cleanup) cleanup();
        };
    }, [handleAvatarExpiration]);

    /**
     * Helper function to get the display value for a field.
     * Prioritizes pendingUpdates over profileData.
     * @param {string} fieldKey - The key of the field.
     * @returns {string} - The value to display.
     */
    const getDisplayValue = (fieldKey) => {
        if (pendingUpdates.hasOwnProperty(fieldKey)) {
            if (Array.isArray(pendingUpdates[fieldKey])) {
                return pendingUpdates[fieldKey].length > 0
                    ? pendingUpdates[fieldKey].join(', ')
                    : 'N/A';
            }
            return pendingUpdates[fieldKey] || 'N/A';
        }
        if (Array.isArray(profileData[fieldKey])) {
            return profileData[fieldKey].length > 0
                ? profileData[fieldKey].join(', ')
                : 'N/A';
        }
        return profileData[fieldKey] || 'N/A';
    };

    /**
     * Handle changes to editable fields
     * @param {string} fieldKey - The key of the field being edited
     * @param {string} newValue - The new value of the field
     */
    const handleFieldChange = (fieldKey, newValue) => {
        setPendingUpdates((prev) => ({
            ...prev,
            [fieldKey]: newValue,
        }));
    };

    /**
     * Handle submission of profile changes by opening the password modal
     */
    const handleSubmitChanges = () => {
        if (Object.keys(pendingUpdates).length === 0 && !selectedFile) {
            setSnackbar({ open: true, message: 'No changes to submit.', severity: 'info' });
            return;
        }
        setOpenPasswordModal(true);
    };

    /**
     * Handle password confirmation and submit changes
     * @param {string} password - The current password entered by the user
     */
    const handlePasswordConfirm = async (password) => {
        try {
            setIsUploading(true); // Start upload indicator
            const controller = new AbortController();
            controllerRef.current = controller;

            // Initialize FormData for avatar upload
            const avatarFormData = new FormData();
            if (selectedFile) {
                avatarFormData.append('avatar', selectedFile, 'avatar.png'); // Append the Blob directly
            }

            // Upload avatar if a new image is selected
            if (selectedFile) {
                await axiosPrivate.put('/person/person/avatar', avatarFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    signal: controller.signal,
                });
                fetchAvatarUrl(auth.user_id);
            }

            // Prepare profile update payload
            const profilePayload = {
                ...pendingUpdates,
                current_password: password,
            };

            // Update profile data
            await axiosPrivate.put('/person/person/profile', profilePayload, {
                signal: controller.signal,
            });

            // Refresh profile data and avatar
            await fetchProfile();
            setAvatarKey((prevKey) => prevKey + 1); // Force re-render AvatarManager

            // Reset states
            setPendingUpdates({});
            setOpenPasswordModal(false);
            setSelectedFile(null);
            setImageSelected(false);
            setAvatarModalOpen(false);
            setNewAvatar(null); // Clear the preview
            setSnackbar({ open: true, message: 'Profile updated successfully.', severity: 'success' });
        } catch (error) {
            console.error(error);
            const message = error.response?.data?.message || 'Failed to update profile.';
            setSnackbar({ open: true, message, severity: 'error' });
            setAvatarModalOpen(false);
            setOpenPasswordModal(false);
        } finally {
            setIsUploading(false); // Stop upload indicator
        }
    };

    /**
     * Handle closing of the password modal without confirmation
     */
    const handlePasswordDialogClose = () => {
        setOpenPasswordModal(false);
    };

    /**
     * Handle resetting changes by re-fetching the profile data
     */
    const handleReset = () => {
        setPendingUpdates({});
        setSelectedFile(null);
        setImageSelected(false);
        setNewAvatar(null); // Clear the preview
        fetchProfile();
        setSnackbar({ open: true, message: 'Changes have been reset.', severity: 'info' });
    };

    /**
     * Handle canceling and navigating back to the previous page
     */
    const handleCancel = () => {
        navigate(-1); // Navigate to the previous page
    };

    /**
     * Handle closing of the snackbar
     */
    const handleSnackbarClose = () => {
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    /**
     * Handle image selection from ImageUploadModal
     * @param {Blob | HTMLCanvasElement} file - The selected image file blob or canvas element
     */
    const handleImageSelect = (file) => {
        if (file instanceof HTMLCanvasElement) {
            // Convert canvas to Blob
            file.toBlob((blob) => {
                if (blob) {
                    setSelectedFile(blob);
                    setNewAvatar(blob);
                } else {
                    console.error('Failed to convert canvas to blob.');
                    setSnackbar({ open: true, message: 'Failed to process the selected image.', severity: 'error' });
                }
            }, 'image/png');
        } else if (file instanceof Blob) {
            setSelectedFile(file);
            setNewAvatar(file);
        } else {
            console.error('Unsupported file type:', file);
            setSnackbar({ open: true, message: 'Unsupported image format.', severity: 'error' });
        }
    };


    // If profile failed to load
    if (!profileData) {
        return (
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Typography variant="h6">Failed to load profile.</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm" sx={{ padding: { xs: 2, sm: 3, md: 4 } }}>
            {loading ? (
                <CircularLoading isOpen={loading} />
            ) : (
                <>
                    <Box
                        sx={{
                            border: `1px solid ${colors.grey[300]}`,
                            borderRadius: '8px',
                            padding: theme.spacing(4),
                            boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                            backgroundColor: colors.primary[400],
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {/* Snackbar Notifications */}
                        <Snackbar
                            open={snackbar.open}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <Alert
                                onClose={handleSnackbarClose}
                                severity={snackbar.severity}
                                sx={{
                                    width: '100%',
                                    backgroundColor:
                                        snackbar.severity === 'success'
                                            ? colors.greenAccent[600]
                                            : snackbar.severity === 'error'
                                                ? colors.redAccent[600]
                                                : colors.blueAccent[500],
                                    color: 'white',
                                }}
                            >
                                <Typography variant="h6">{snackbar.message}</Typography>
                            </Alert>
                        </Snackbar>

                        {/* Profile Picture with Edit Icon */}
                        <Box
                            display="relative"
                            // justifyContent="center"
                            mb={2}
                            position="relative"
                            sx={{
                                '&:hover .edit-icon': {
                                    opacity: 1,
                                },
                            }}
                        >
                            <img
                                alt="profile-user"
                                width="100px"
                                height="100px"
                                src={newAvatarUrl || auth.avatarUrl}
                                style={{
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    display: 'block',
                                }}
                            />
                            {/* Updated IconButton Styling */}
                            <IconButton
                                onClick={() => setAvatarModalOpen(true)}
                                size="small"
                                className="edit-icon"
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    bgcolor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
                                    color: 'white',
                                    opacity: isMobile ? 1 : 0, // Hidden by default
                                    transition: isMobile ? 'none' : 'opacity 0.3s ease', // Smooth transition
                                    '&:hover': {
                                        bgcolor: 'rgba(0, 0, 0, 0.7)', // Darker on hover
                                    },
                                }}
                                aria-label="Edit Avatar"
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Box>

                        {/* User Name */}
                        <Box textAlign="center" mb={1}>
                            <Typography variant="h5" fontWeight="bold">
                                {profileData.first_name} {profileData.last_name}
                            </Typography>
                        </Box>

                        {/* Job Title */}
                        <Box textAlign="center" mb={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                {profileData.job_title}
                            </Typography>
                        </Box>

                        {/* Editable Fields */}
                        {isMobile ? (
                            // Mobile Layout
                            <Stack spacing={3}>
                                {/* Username (Non-Editable) */}
                                <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Username:
                                    </Typography>
                                    <Box display="flex" alignItems="center" mt={1}>
                                        <Typography variant="body2">
                                            {getDisplayValue('username')}
                                        </Typography>
                                    </Box>
                                </Box>

                                {/* Phone Number */}
                                <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Phone Number:
                                    </Typography>
                                    <Box display="flex" alignItems="center" mt={1}>
                                        {editingFields.contact ? (
                                            <EditableField
                                                value={pendingUpdates.contact ?? profileData.contact}
                                                fieldKey="contact"
                                                onFieldChange={handleFieldChange}
                                                validationSchema={Yup.string()
                                                    .matches(/^\+?[0-9]{7,15}$/, 'Phone number is not valid')
                                                    .required('Phone number is required')}
                                                onSave={() => setEditingFields((prev) => ({ ...prev, contact: false }))}
                                                onCancel={() => setEditingFields((prev) => ({ ...prev, contact: false }))}
                                            />
                                        ) : (
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                                    {getDisplayValue('contact')}
                                                </Typography>
                                                <IconButton
                                                    aria-label="Edit Phone Number"
                                                    onClick={() => setEditingFields((prev) => ({ ...prev, contact: true }))}
                                                    size="small"
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                {/* Email */}
                                <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Email:
                                    </Typography>
                                    <Box display="flex" alignItems="center" mt={1}>
                                        {editingFields.email ? (
                                            <EditableField
                                                value={pendingUpdates.email ?? profileData.email}
                                                fieldKey="email"
                                                onFieldChange={handleFieldChange}
                                                validationSchema={Yup.string()
                                                    .email('Invalid email format')
                                                    .required('Email is required')}
                                                onSave={() => setEditingFields((prev) => ({ ...prev, email: false }))}
                                                onCancel={() => setEditingFields((prev) => ({ ...prev, email: false }))}
                                            />
                                        ) : (
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                                    {getDisplayValue('email')}
                                                </Typography>
                                                <IconButton
                                                    aria-label="Edit Email"
                                                    onClick={() => setEditingFields((prev) => ({ ...prev, email: true }))}
                                                    size="small"
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                {/* Address 1 */}
                                <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Address 1:
                                    </Typography>
                                    <Box display="flex" alignItems="center" mt={1}>
                                        {editingFields.address1 ? (
                                            <EditableField
                                                value={pendingUpdates.address1 ?? profileData.address1}
                                                fieldKey="address1"
                                                onFieldChange={handleFieldChange}
                                                validationSchema={Yup.string().required('Address Line 1 is required')}
                                                onSave={() => setEditingFields((prev) => ({ ...prev, address1: false }))}
                                                onCancel={() => setEditingFields((prev) => ({ ...prev, address1: false }))}
                                            />
                                        ) : (
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body2">
                                                    {getDisplayValue('address1')}
                                                </Typography>
                                                <IconButton
                                                    aria-label="Edit Address 1"
                                                    onClick={() => setEditingFields((prev) => ({ ...prev, address1: true }))}
                                                    size="small"
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                {/* Address 2 */}
                                <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Address 2:
                                    </Typography>
                                    <Box display="flex" alignItems="center" mt={1}>
                                        {editingFields.address2 ? (
                                            <EditableField
                                                value={pendingUpdates.address2 ?? profileData.address2}
                                                fieldKey="address2"
                                                onFieldChange={handleFieldChange}
                                                validationSchema={Yup.string().nullable()}
                                                onSave={() => setEditingFields((prev) => ({ ...prev, address2: false }))}
                                                onCancel={() => setEditingFields((prev) => ({ ...prev, address2: false }))}
                                            />
                                        ) : (
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body2">
                                                    {getDisplayValue('address2')}
                                                </Typography>
                                                <IconButton
                                                    aria-label="Edit Address 2"
                                                    onClick={() => setEditingFields((prev) => ({ ...prev, address2: true }))}
                                                    size="small"
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                {/* Roles (Non-Editable) */}
                                <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Roles:
                                    </Typography>
                                    <Box display="flex" alignItems="center" mt={1}>
                                        <Typography variant="body2">
                                            {getDisplayValue('roles')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Stack>
                        ) : (
                            // Desktop Layout
                            <>
                                {/* Username (Non-Editable) */}
                                <Grid container spacing={2} alignItems="center" mb={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            Username:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Typography variant="body1">
                                                {getDisplayValue('username')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                                        {/* Invisible IconButton to reserve space */}
                                        <IconButton
                                            aria-label="Reserved space"
                                            sx={{ visibility: 'hidden' }}
                                            size="small"
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                {/* Phone Number */}
                                <Grid container spacing={2} alignItems="center" mb={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            Phone Number:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" justifyContent="flex-end">
                                            {editingFields.contact ? (
                                                <EditableField
                                                    value={pendingUpdates.contact ?? profileData.contact}
                                                    fieldKey="contact"
                                                    onFieldChange={handleFieldChange}
                                                    validationSchema={Yup.string()
                                                        .matches(/^\+?[0-9]{7,15}$/, 'Phone number is not valid')
                                                        .required('Phone number is required')}
                                                    onSave={() => setEditingFields((prev) => ({ ...prev, contact: false }))}
                                                    onCancel={() => setEditingFields((prev) => ({ ...prev, contact: false }))}
                                                />
                                            ) : (
                                                <Typography variant="body1">
                                                    {getDisplayValue('contact')}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                                        {!editingFields.contact && (
                                            <IconButton
                                                aria-label="Edit Phone Number"
                                                onClick={() => setEditingFields((prev) => ({ ...prev, contact: true }))}
                                                size="small"
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* Email */}
                                <Grid container spacing={2} alignItems="center" mb={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            Email:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" justifyContent="flex-end">
                                            {editingFields.email ? (
                                                <EditableField
                                                    value={pendingUpdates.email ?? profileData.email}
                                                    fieldKey="email"
                                                    onFieldChange={handleFieldChange}
                                                    validationSchema={Yup.string()
                                                        .email('Invalid email format')
                                                        .required('Email is required')}
                                                    onSave={() => setEditingFields((prev) => ({ ...prev, email: false }))}
                                                    onCancel={() => setEditingFields((prev) => ({ ...prev, email: false }))}
                                                />
                                            ) : (
                                                <Typography variant="body1">
                                                    {getDisplayValue('email')}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                                        {!editingFields.email && (
                                            <IconButton
                                                aria-label="Edit Email"
                                                onClick={() => setEditingFields((prev) => ({ ...prev, email: true }))}
                                                size="small"
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* Address 1 */}
                                <Grid container spacing={2} alignItems="center" mb={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            Address 1:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" justifyContent="flex-end">
                                            {editingFields.address1 ? (
                                                <EditableField
                                                    value={pendingUpdates.address1 ?? profileData.address1}
                                                    fieldKey="address1"
                                                    onFieldChange={handleFieldChange}
                                                    validationSchema={Yup.string().required('Address Line 1 is required')}
                                                    onSave={() => setEditingFields((prev) => ({ ...prev, address1: false }))}
                                                    onCancel={() => setEditingFields((prev) => ({ ...prev, address1: false }))}
                                                />
                                            ) : (
                                                <Typography variant="body1">
                                                    {getDisplayValue('address1')}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                                        {!editingFields.address1 && (
                                            <IconButton
                                                aria-label="Edit Address 1"
                                                onClick={() => setEditingFields((prev) => ({ ...prev, address1: true }))}
                                                size="small"
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* Address 2 */}
                                <Grid container spacing={2} alignItems="center" mb={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            Address 2:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" justifyContent="flex-end">
                                            {editingFields.address2 ? (
                                                <EditableField
                                                    value={pendingUpdates.address2 ?? profileData.address2}
                                                    fieldKey="address2"
                                                    onFieldChange={handleFieldChange}
                                                    validationSchema={Yup.string().nullable()}
                                                    onSave={() => setEditingFields((prev) => ({ ...prev, address2: false }))}
                                                    onCancel={() => setEditingFields((prev) => ({ ...prev, address2: false }))}
                                                />
                                            ) : (
                                                <Typography variant="body1">
                                                    {getDisplayValue('address2')}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                                        {!editingFields.address2 && (
                                            <IconButton
                                                aria-label="Edit Address 2"
                                                onClick={() => setEditingFields((prev) => ({ ...prev, address2: true }))}
                                                size="small"
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* Roles (Non-Editable) */}
                                <Grid container spacing={2} alignItems="center" mb={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            Roles:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Typography variant="body1">
                                                {getDisplayValue('roles')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                                        {/* Invisible IconButton to reserve space */}
                                        <IconButton
                                            aria-label="Reserved space"
                                            sx={{ visibility: 'hidden' }}
                                            size="small"
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        {/* Action Buttons */}
                        <Box
                            display="flex"
                            flexDirection={isMobile ? 'column' : 'row'}
                            justifyContent="center"
                            alignItems="center"
                            mt={4}
                            gap={2}
                        >
                            <Button
                                type="button"
                                onClick={handleSubmitChanges}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                    minWidth: '100px',
                                    width: isMobile ? '100%' : 'auto',
                                }}
                                variant="contained"
                            >
                                Submit
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleReset}
                                sx={{
                                    minWidth: '100px',
                                    width: isMobile ? '100%' : 'auto',
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                onClick={handleCancel}
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                    minWidth: '100px',
                                    width: isMobile ? '100%' : 'auto',
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </>
            )}
            {/* Image Upload Modal */}
            <ImageUploadModal
                isAvatarModalOpen={isAvatarModalOpen}
                onClose={() => setAvatarModalOpen(false)}
                onImageSelect={handleImageSelect} // Updated to handle both selectedFile and newAvatar
                setImageSelected={setImageSelected}
            />
            {/* Password Confirmation Modal */}
            <PasswordConfirmationModal
                open={openPasswordModal}
                handleClose={handlePasswordDialogClose}
                handleConfirm={handlePasswordConfirm}
                isUploading={isUploading} // Pass the uploading state
            />
        </Container>
    );

};

export default ProfilePage;
