// src/components/PasswordConfirmationModal.jsx

import React from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    IconButton,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme'; // Ensure this path is correct based on your project structure
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMediaQuery } from '@mui/material';

/**
 * PasswordConfirmationModal Component
 *
 * Props:
 * - open: Boolean to control modal visibility
 * - handleClose: Function to close the modal
 * - handleConfirm: Function to handle password confirmation
 */
const PasswordConfirmationModal = ({ open, handleClose, handleConfirm }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect screens <600px

    const formik = useFormik({
        initialValues: {
            current_password: '',
        },
        validationSchema: Yup.object({
            current_password: Yup.string().required('Password is required'),
        }),
        onSubmit: (values) => {
            handleConfirm(values.current_password);
            formik.resetForm();
        },
    });

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="password-confirmation-modal-title"
            aria-describedby="password-confirmation-modal-description"
        >
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: { xs: '80%', sm: '60%', md: '40%' },
                    bgcolor: colors.primary[400],
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    p: { xs: 3, sm: 4 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* Close Button */}
                <IconButton
                    onClick={handleClose}
                    aria-label="Close Password Confirmation Modal"
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: colors.grey[100],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {/* Modal Title */}
                <Typography
                    id="password-confirmation-modal-title"
                    variant={isMobile ? 'h6' : 'h5'}
                    component="h2"
                    mb={3}
                    sx={{ color: colors.grey[100], fontWeight: 'bold', textAlign: 'center' }}
                >
                    Confirm Your Password
                </Typography>

                {/* Password Input */}
                <TextField
                    id="current_password"
                    name="current_password"
                    label="Current Password"
                    type="password"
                    fullWidth
                    variant="filled"
                    required
                    autoFocus
                    value={formik.values.current_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.current_password &&
                        Boolean(formik.errors.current_password)
                    }
                    helperText={
                        formik.touched.current_password &&
                        formik.errors.current_password
                    }
                    sx={{
                        mb: 4,
                        '& .MuiOutlinedInput-root': {
                            // Remove backgroundColor to inherit from modal
                            borderRadius: '4px',
                            backgroundColor: 'transparent',
                        },
                        '& label': {
                            color: colors.grey[100],
                        },
                        '& .MuiFormHelperText-root': {
                            color: colors.redAccent[500],
                        },
                        '& .MuiOutlinedInput-input': {
                            color: colors.grey[900], // Text color inside input
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors.grey[500],
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors.grey[700],
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors.blueAccent[500],
                        },
                    }}
                />

                {/* Modal Action Buttons */}
                <Box
                    display="flex"
                    flexDirection={isMobile ? 'column' : 'row'}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    gap={2}
                >
                    <Button
                        variant="contained"
                        color="success"
                        type="submit"
                        sx={{
                            color: 'white',
                            backgroundColor: colors.greenAccent[500],
                            '&:hover': {
                                backgroundColor: colors.greenAccent[700],
                            },
                            textTransform: 'none',
                            fontWeight: 'bold',
                            py: 1.2, // Reduced padding
                            minWidth: '100px', // Ensures buttons don't stretch excessively
                            width: isMobile ? '100%' : 'auto',
                        }}
                        disabled={!formik.isValid || !formik.dirty}
                    >
                        Confirm
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                        sx={{
                            borderColor: colors.redAccent[500],
                            color: 'white',
                            backgroundColor: colors.redAccent[500],
                            '&:hover': {
                                backgroundColor: colors.redAccent[700],
                            },
                            textTransform: 'none',
                            fontWeight: 'bold',
                            py: 1.2, // Reduced padding
                            whiteSpace: 'nowrap',
                            minWidth: '100px',
                            width: isMobile ? '100%' : 'auto',
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );

};

export default PasswordConfirmationModal;
