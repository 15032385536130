import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    useTheme,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../../theme';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';

const EditStatusModal = ({
    isEditEventStatusModalOpen,
    setEditEventStatusModalOpen,
    selectedProject,
    setSelectedProject,
    selectedAddress,
    setSelectedAddress,
    selectedEvent,
    setSelectedEvent,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSelectAction = async (action, submitForm) => {
        setSelectedAction(action);

        switch (action) {
            case 'Set Status':
                await submitForm();
                break;
            case 'Close':
                setEditEventStatusModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const handleStatusEvents = async (values, { resetForm }) => {
        const controller = new AbortController();
        try {
            await axiosPrivate.patch(
                `/project/project/${selectedProject.id}/address/${selectedAddress.id}/event/${selectedEvent.id}`,
                {
                    status: values.status,
                },
                {
                    signal: controller.signal,
                }
            );
            setSnackbarMessage(
                `Updated status for the event successfully`
            );

            setSnackbarType('success');
            setSnackbarOpen(true);
            setSelectedProject(null);
            setSelectedAddress(null);
            setSelectedEvent(null);
            resetForm(); // Reset form state after submission
            setEditEventStatusModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const getValidationSchema = yup.object().shape({
        status: yup.string().required('Status Required'),
    });

    return (
        <Modal
            open={isEditEventStatusModalOpen}
            onClose={() => {
                setEditEventStatusModalOpen(false);
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '40%' : '35%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        height: '10%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            setEditEventStatusModalOpen(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h4' : 'h2'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        Set Status:
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: isMobile ? 2 : 3,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Formik
                        onSubmit={handleStatusEvents}
                        initialValues={{
                            status: selectedEvent?.status || 'Pending',
                        }}
                        validationSchema={getValidationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            submitForm,
                            resetForm,
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                }}
                            >
                                <Box
                                    display="grid"
                                    gap="20px"
                                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                    sx={{
                                        '& > div': {
                                            gridColumn: isMobile
                                                ? 'span 1'
                                                : undefined,
                                        },
                                    }}
                                >
                                    <FormControl
                                        variant="filled"
                                        sx={{ gridColumn: 'span 1' }}
                                    >
                                        <Select
                                            fullWidth
                                            label="Status"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.status}
                                            name="status"
                                            error={
                                                !!touched.status &&
                                                !!errors.status
                                            }
                                            sx={{
                                                gridColumn: 'span 1',
                                                color: colors.grey[100],
                                                borderRadius: '8px',
                                                '.MuiFilledInput-root': {
                                                    backgroundColor:
                                                        colors.primary[600],
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor:
                                                        colors.grey[300],
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline':
                                                    {
                                                        borderColor:
                                                            colors.grey[100],
                                                    },
                                                '& .MuiSvgIcon-root': {
                                                    color: colors.grey[100],
                                                },
                                            }}
                                        >
                                            <MenuItem value="in progress">
                                                    In Progress
                                            </MenuItem>
                                            <MenuItem value="complete">
                                                Complete
                                            </MenuItem>
                                            <MenuItem value="postponed">
                                                Postponed
                                            </MenuItem>
                                            <MenuItem value="reschedule">
                                                Reschedule
                                            </MenuItem>
                                            <MenuItem value="canceled">
                                                Canceled
                                            </MenuItem>
                                            <MenuItem value="other">
                                                Other
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: 2,
                                        mt: 'auto',
                                        mb: 2,
                                    }}
                                >
                                    {isMobile ? (
                                        <FormControl
                                            sx={{
                                                width: '100%',
                                                borderRadius: '8px',
                                                alignSelf: 'center',
                                            }}
                                        >
                                            <Select
                                                value={selectedAction}
                                                onChange={(e) =>
                                                    handleSelectAction(
                                                        e.target.value,
                                                        submitForm
                                                    )
                                                }
                                                displayEmpty
                                                inputProps={{
                                                    'aria-label':
                                                        'Select action',
                                                }}
                                                sx={{
                                                    color: colors.grey[100],
                                                    backgroundColor:
                                                        colors.primary[400],
                                                    borderRadius: '8px',
                                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                    '.MuiFilledInput-root': {
                                                        backgroundColor:
                                                            colors.primary[600],
                                                    },
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor:
                                                            colors.grey[300],
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline':
                                                        {
                                                            borderColor:
                                                                colors.grey[100],
                                                        },
                                                    '& .MuiSvgIcon-root': {
                                                        color: colors.grey[100],
                                                    },
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Select an action
                                                </MenuItem>
                                                <MenuItem value="Set Status">
                                                    Set Status
                                                </MenuItem>
                                                <MenuItem value="Close">
                                                    Close
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor:
                                                        colors.greenAccent[500],
                                                    '&:hover': {
                                                        backgroundColor:
                                                            colors.greenAccent[700],
                                                    },
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Set Status
                                            </Button>
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    setEditEventStatusModalOpen(
                                                        false
                                                    );
                                                }}
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor:
                                                        colors.redAccent[500],
                                                    '&:hover': {
                                                        backgroundColor:
                                                            colors.redAccent[700],
                                                    },
                                                    whiteSpace: 'nowrap',
                                                }}
                                                variant="contained"
                                            >
                                                Close
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditStatusModal;
