import { createContext, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        username: null,
        accessToken: null,
        roles: [], // Set roles as an empty array by default
        name: null,
        user_id: null,
        job_title: null,
        avatarUrl: null,
    });
    // const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);

    const updateAvatarUrl = (url) => {
        setAuth(prevAuth => ({
            ...prevAuth,
            avatarUrl: url,
        }));
    };

    return (
        <AuthContext.Provider value={{ auth, setAuth, updateAvatarUrl }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
