import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    useTheme,
    Button,
    Modal,
    TextField,
    IconButton,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import { tokens } from '../../theme';
import CloseIcon from '@mui/icons-material/CloseRounded';

const DetailedViewEventDescModal = (props) => {
    const {
        isDetailedViewOpen,
        setDetailedViewOpen,
        description: initialDescription,
        setDescription,
    } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [description, setLocalDescription] = useState(initialDescription);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        setLocalDescription(initialDescription);
    }, [initialDescription]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => {
        setDetailedViewOpen(false);
    };

    const handleSubmit = () => {
        setDescription(description);
        setDetailedViewOpen(false);
    };

    const handleDescriptionChange = (event) => {
        setLocalDescription(event.target.value);
    };

    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Submit':
                handleSubmit();
                break;
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal open={isDetailedViewOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '30%',
                    height: isMobile ? '50%' : '50%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton edge="end" color="inherit" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h5' : 'h4'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        Description of Work
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        width: '100%',
                        overflowY: 'auto',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        variant="filled"
                        value={description}
                        name="description"
                        onChange={handleDescriptionChange}
                        sx={{
                            mb: 2,
                        }}
                    />
                    {isMobile ? (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Select
                                value={selectedAction}
                                onChange={handleSelectAction}
                                displayEmpty
                                sx={{
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400],
                                    borderRadius: '8px',
                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[300],
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.grey[100],
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: colors.grey[100],
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select an action
                                </MenuItem>
                                <MenuItem value="Submit">Submit</MenuItem>
                                <MenuItem value="Close">Close</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: theme.spacing(2),
                                mt: 2,
                            }}
                        >
                            <Button
                                type="button"
                                onClick={handleSubmit}
                                sx={{
                                    color: 'white',
                                    backgroundColor: colors.greenAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.greenAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: colors.redAccent[500],
                                    '&:hover': {
                                        backgroundColor: colors.redAccent[700],
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default DetailedViewEventDescModal;
