import {
    InputLabel,
    Box,
    Typography,
    useTheme,
    Button,
    Select,
    MenuItem,
    Modal,
    TextField,
} from '@mui/material';
import { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Formik } from 'formik';
import { tokens } from '../../../theme';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as yup from 'yup';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DetailedViewModal from './DetailedViewModal';

const EditInvoiceModal = (props) => {
    const {
        isEditInvoiceModalOpen,
        setEditInvoiceModalOpen,
        setSnackbarMessage,
        setSnackbarType,
        setSnackbarOpen,
        selectedInvoice,
        setSelectedInvoice,
        selectedWarranty,
        setSelectedWarranty,
    } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [purpose, setPurpose] = useState(selectedInvoice?.purpose ?? '');
    const [isDetailedViewOpen, setDetailedViewOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');
    const usdFormatRegex = /^\d+\.\d{2}$/;

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const checkoutSchema = yup.object().shape({
        company: yup.string().nullable().notRequired(),
        purpose: yup.string().nullable().notRequired(),
        in_number: yup
            .string()
            .matches(usdFormatRegex, 'Amount must be in the format x.xx')
            .nullable()
            .notRequired(),
        out_number: yup
            .string()
            .matches(usdFormatRegex, 'Amount must be in the format x.xx')
            .nullable()
            .notRequired(),
        invoice_date: yup.date().nullable().notRequired(),
        paid_date: yup.date().nullable().notRequired(),
        paid: yup.string().required('required'),
    });

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const data = {
                ...values,
                purpose: purpose,
            };
            data.paid = data.paid === 'yes';

            if (values.date_paid) {
                data.date_paid = dayjs(values.date_paid).format('YYYY-MM-DD HH:mm:ss');
            }
            if (values.invoice_date) {
                data.invoice_date = dayjs(values.invoice_date).format('YYYY-MM-DD HH:mm:ss');
            }

            const controller = new AbortController();

            await axiosPrivate.put(
                `/warranty/warranty/${selectedWarranty.id}/invoice/${selectedInvoice.id}`,
                data,
                {
                    signal: controller.signal,
                }
            );

            setSnackbarMessage(`Updated invoice successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            resetForm();
            setPurpose('');
            setEditInvoiceModalOpen(false);
            setSelectedInvoice(null);
            setSelectedWarranty(null);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    err.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleReset = (resetForm) => {
        resetForm();
        setPurpose(selectedInvoice?.purpose);
    };

    const handleSelectAction = (action, handleSubmit, resetForm) => {
        setSelectedAction(action);

        switch (action) {
            case 'Update Invoice':
                handleSubmit();
                break;
            case 'Reset':
                handleReset(resetForm);
                break;
            case 'Close':
                setEditInvoiceModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    useEffect(() => {
        if (selectedInvoice) {
            setPurpose(selectedInvoice.purpose || '');
        }
    }, [selectedInvoice]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal
                open={isEditInvoiceModalOpen}
                onClose={() => {
                    setEditInvoiceModalOpen(false);
                    setSelectedInvoice(null);
                    setSelectedWarranty(null);
                    setPurpose('');
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        width: isMobile ? '80%' : '40%',
                        height: isMobile ? '70%' : '60%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            height: '7%',
                            width: '98%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                setEditInvoiceModalOpen(false);
                                setSelectedInvoice(null);
                                setSelectedWarranty(null);
                                setPurpose('');
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h5' : 'h4'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                        >
                            Edit Invoice
                        </Typography>
                        <Typography
                            variant={isMobile ? 'subtitle1' : 'h6'}
                            color={colors.grey[100]}
                        >
                            Address: {selectedWarranty?.address.title}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            p: 2,
                        }}
                    >
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={{
                                company: selectedInvoice?.company ?? '',
                                in_number: selectedInvoice?.in_number ?? '',
                                out_number: selectedInvoice?.out_number ?? '',
                                invoice_date: selectedInvoice?.invoice_date
                                    ? dayjs(
                                          dayjs(
                                              selectedInvoice.invoice_date
                                          ).format('MM/DD/YYYY hh:mm A')
                                      )
                                    : null,
                                date_paid: selectedInvoice?.date_paid
                                    ? dayjs(
                                          dayjs(
                                              selectedInvoice.date_paid
                                          ).format('MM/DD/YYYY hh:mm A')
                                      )
                                    : null,
                                paid:
                                    selectedInvoice?.paid !== null
                                        ? selectedInvoice?.paid
                                            ? 'yes'
                                            : 'no'
                                        : '',
                            }}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                resetForm,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            margin: '1% 3% 3% 3%',
                                            '& > div': {
                                                gridColumn: isMobile
                                                    ? 'span 4'
                                                    : undefined,
                                            },
                                        }}
                                    >
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 4' }}
                                        >
                                            <TextField
                                                fullWidth
                                                value={values.company}
                                                variant="filled"
                                                type="text"
                                                label="Company"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name="company"
                                            />
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            value={values.in_number}
                                            variant="filled"
                                            type="text"
                                            label="In"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="in_number"
                                            sx={{ gridColumn: 'span 2' }}
                                            error={
                                                !!touched.in_number &&
                                                !!errors.in_number
                                            }
                                            helperText={
                                                touched.in_number &&
                                                errors.in_number
                                            }
                                        />
                                        <TextField
                                            fullWidth
                                            value={values.out_number}
                                            variant="filled"
                                            type="text"
                                            label="Out"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="out_number"
                                            sx={{ gridColumn: 'span 2' }}
                                            error={
                                                !!touched.out_number &&
                                                !!errors.out_number
                                            }
                                            helperText={
                                                touched.out_number &&
                                                errors.out_number
                                            }
                                        />
                                        <DateTimePicker
                                            fullWidth
                                            variant="filled"
                                            label="Invoice Date"
                                            onBlur={handleBlur}
                                            onChange={(date) =>
                                                handleChange({
                                                    target: {
                                                        name: 'invoice_date',
                                                        value: date,
                                                    },
                                                })
                                            }
                                            value={values.invoice_date}
                                            name="invoice_date"
                                            sx={{ gridColumn: 'span 2' }}
                                        />
                                        <DateTimePicker
                                            fullWidth
                                            variant="filled"
                                            label="Date Paid"
                                            onBlur={handleBlur}
                                            onChange={(date) =>
                                                handleChange({
                                                    target: {
                                                        name: 'date_paid',
                                                        value: date,
                                                    },
                                                })
                                            }
                                            value={values.date_paid}
                                            name="date_paid"
                                            sx={{ gridColumn: 'span 2' }}
                                        />
                                        <FormControl
                                            variant="filled"
                                            sx={{ gridColumn: 'span 2' }}
                                        >
                                            <InputLabel id="paid-label">
                                                Paid
                                            </InputLabel>
                                            <Select
                                                labelId="paid"
                                                id="paid-select"
                                                fullWidth
                                                value={values.paid}
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'paid',
                                                        event.target.value
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                                label="Paid"
                                                name="paid"
                                                error={
                                                    !!touched.paid &&
                                                    !!errors.paid
                                                }
                                            >
                                                <MenuItem value="yes">
                                                    Yes
                                                </MenuItem>
                                                <MenuItem value="no">
                                                    No
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            value={
                                                purpose.length > 30
                                                    ? `${purpose.substring(
                                                          0,
                                                          30
                                                      )}...`
                                                    : `${purpose}`
                                            }
                                            onClick={() =>
                                                setDetailedViewOpen(true)
                                            }
                                            variant="filled"
                                            type="text"
                                            label="Purpose"
                                            onBlur={handleBlur}
                                            name="purpose"
                                            sx={{ gridColumn: 'span 2' }}
                                            InputProps={{ readOnly: true }}
                                        />
                                        {isDetailedViewOpen && (
                                            <DetailedViewModal
                                                isDetailedViewOpen={
                                                    isDetailedViewOpen
                                                }
                                                setDetailedViewOpen={
                                                    setDetailedViewOpen
                                                }
                                                purpose={purpose}
                                                setPurpose={setPurpose}
                                            />
                                        )}
                                        {isMobile ? (
                                            <FormControl
                                                sx={{
                                                    gridColumn: 'span 4',
                                                    borderRadius: '8px',
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                <Select
                                                    value={selectedAction}
                                                    onChange={(e) =>
                                                        handleSelectAction(
                                                            e.target.value,
                                                            handleSubmit,
                                                            resetForm
                                                        )
                                                    }
                                                    displayEmpty
                                                    inputProps={{
                                                        'aria-label':
                                                            'Select action',
                                                    }}
                                                    sx={{
                                                        color: colors.grey[100],
                                                        backgroundColor:
                                                            colors.primary[400],
                                                        borderRadius: '8px',
                                                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                        '.MuiOutlinedInput-notchedOutline':
                                                            {
                                                                borderColor:
                                                                    colors.grey[300],
                                                            },
                                                        '&:hover .MuiOutlinedInput-notchedOutline':
                                                            {
                                                                borderColor:
                                                                    colors.grey[100],
                                                            },
                                                        '& .MuiSvgIcon-root': {
                                                            color: colors.grey[100],
                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        value=""
                                                        disabled
                                                    >
                                                        Select an action
                                                    </MenuItem>
                                                    <MenuItem value="Update Invoice">
                                                        Update Invoice
                                                    </MenuItem>
                                                    <MenuItem value="Reset">
                                                        Reset
                                                    </MenuItem>
                                                    <MenuItem value="Close">
                                                        Close
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <Box
                                                sx={{
                                                    gridColumn: 'span 4',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.greenAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.greenAccent[700],
                                                        },
                                                        mr: 1,
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    Update Invoice
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    onClick={() =>
                                                        handleReset(resetForm)
                                                    }
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.redAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.redAccent[700],
                                                        },
                                                        mr: 1,
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={() =>
                                                        setEditInvoiceModalOpen(
                                                            false
                                                        )
                                                    }
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            colors.redAccent[500],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors.redAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    variant="contained"
                                                >
                                                    Close
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </LocalizationProvider>
    );
};

export default EditInvoiceModal;
