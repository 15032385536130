import React, { useState, useEffect } from 'react';
import {
    Snackbar,
    Alert,
    Box,
    Typography,
    useTheme,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { tokens } from '../../theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Header from '../../components/Header';
import useAuth from '../../hooks/useAuth';
import useProjectData from '../../hooks/useProjectData';
import CircularLoading from '../global/CircularLoading';
import CustomFooter from './CustomFooter';
import { styled } from '@mui/material/styles';
import { calculateShowAddToTeamButton } from '../people/RoleManager';
import ViewProjectDescModal from './ViewProjectDescModal';
import EditProjectNameModal from './EditProjectNameModal';
import DeleteProjectModal from './DeleteProjectModal';
import AddProjectModal from './AddProjectModal';
import AddAddressModal from './address/AddAddressModal';
import EditAddressNameModal from './address/EditAddressNameModal';
import DeleteAddressModal from './address/DeleteAddressModal';
import ViewAddressDescriptionModal from './address/ViewAddressDescriptionModal';
import TableHeaderCell from '../../components/TableHeaderCell';

const Property = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { auth } = useAuth();
    const showAddToTeamButton = calculateShowAddToTeamButton(auth.roles);
    const { loading, dropdownData, fetchProjects } = useProjectData();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [pageAddress, setPageAddress] = useState({});
    const [rowsPerPageAddress, setRowsPerPageAddress] = useState({});
    const defaultRowsPerPageAddress = 10; // Define a default value
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [expandedProjectRows, setExpandedProjectRows] = useState({});
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedAddressesPerProject, setSelectedAddressesPerProject] =
        useState({});

    // Modals
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isDeleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
    const [isEditNameModalOpen, setEditNameModalOpen] = useState(false);
    const [isViewDescriptionModalOpen, setViewDescriptionModalOpen] = useState(false);

    const [isAddAddressModalOpen, setAddAddressModalOpen] =
        useState(false);
    const [isDeleteAddressModalOpen, setDeleteAddressModalOpen] =
        useState(false);
    const [isEditAddressNameModalOpen, setEditAddressNameModalOpen] = useState(false);
    const [isViewAddDescModalOpen, setViewAddDescModalOpen] = useState(false);


    ////////////////////////////FILTER DATA///////////////////
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [filterOptions, setFilterOptions] = useState({
        column: '',     // Column to filter on
        value: '',      // Filter value
        operator: 'contains' // Default filter operator (e.g., 'contains', 'equals', etc.)
    });

    const [addressOrder, setAddressOrder] = useState('asc');
    const [addressOrderBy, setAddressOrderBy] = useState('id');
    const [addressFilterOptions, setAddressFilterOptions] = useState({
        column: '',     // Column to filter on
        value: '',      // Filter value
        operator: 'contains' // Default filter operator (e.g., 'contains', 'equals', etc.)
    });

    const handleSelectAction = (action) => {
        switch (action) {
            case 'Add':
                setAddModalOpen(true);
                break;
            case 'Delete':
                setDeleteProjectModalOpen(true);
                break;
            case 'Reset':
                resetValues();
                break;
            default:
                break;
        }
    };

    const handleSelectActionAddress = (action, row) => {
        setSelectedProject(row); // Set the selected project
        switch (action) {
            case 'Add':
                setAddAddressModalOpen(true);
                break;
            case 'Delete':
                setDeleteAddressModalOpen(true);
                break;
            case 'Reset':
                resetAddresses();
                break;
            default:
                break;
        }
    };

    const handleFilter = (filterOptions) => {
        // Implement filter logic based on filterOptions
        setFilterOptions(filterOptions);
    };

    // Inside your table component
    const handleRequestSort = (direction, column) => {
        setOrder(direction);
        setOrderBy(column);
    };

    const handleAddressFilter = (filterOptions) => {
        // Implement filter logic based on filterOptions
        setAddressFilterOptions(filterOptions);
    };

    // Inside your table component
    const handleAddressRequestSort = (direction, column) => {
        setAddressOrder(direction);
        setAddressOrderBy(column);
    };

    const filteredData = () => {
        let data = dropdownData;

        // Apply filter based on filterOptions
        if (filterOptions.column) {
            data = data.filter(row => {
                const value = getNestedValue(row, filterOptions.column);
                const filterValue = filterOptions.value ? filterOptions.value.toLowerCase() : '';

                switch (filterOptions.operator) {
                    case 'contains':
                        return value != null && value.toString().toLowerCase().includes(filterValue);
                    case 'equals':
                        return value != null && value.toString().toLowerCase() === filterValue;
                    case 'startsWith':
                        return value != null && value.toString().toLowerCase().startsWith(filterValue);
                    case 'endsWith':
                        return value != null && value.toString().toLowerCase().endsWith(filterValue);
                    case 'isEmpty':
                        return value === '' || value == null;
                    case 'isNotEmpty':
                        return value !== '' && value != null;
                    case 'isAnyOf':
                        return value != null && filterValue.split(',').map(v => v.trim()).includes(value.toString());
                    default:
                        return true;
                }
            });
        }

        // Sorting
        return data.sort(getComparator(order, orderBy));
    };

    const filteredRow = (rowData, filterOptions, orderData, orderDataBy) => {
        let data = rowData;

        // Apply filter based on filterOptions
        if (filterOptions.column) {
            data = data.filter(row => {
                const value = getNestedValue(row, filterOptions.column);
                const filterValue = filterOptions.value ? filterOptions.value.toLowerCase() : '';

                switch (filterOptions.operator) {
                    case 'contains':
                        return value != null && value.toString().toLowerCase().includes(filterValue);
                    case 'equals':
                        return value != null && value.toString().toLowerCase() === filterValue;
                    case 'startsWith':
                        return value != null && value.toString().toLowerCase().startsWith(filterValue);
                    case 'endsWith':
                        return value != null && value.toString().toLowerCase().endsWith(filterValue);
                    case 'isEmpty':
                        return value === '' || value == null;
                    case 'isNotEmpty':
                        return value !== '' && value != null;
                    case 'isAnyOf':
                        return value != null && filterValue.split(',').map(v => v.trim()).includes(value.toString());
                    default:
                        return true;
                }
            });
        }

        // Sorting
        return data.sort(getComparator(orderData, orderDataBy));
    };


    // Utility function to access nested properties
    function getNestedValue(obj, path) {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    function descendingComparator(a, b, orderBy) {
        const aValue = getNestedValue(a, orderBy);
        const bValue = getNestedValue(b, orderBy);

        // Handle null or undefined values
        if (aValue === null || aValue === undefined) {
            return (bValue === null || bValue === undefined) ? 0 : -1;
        }
        if (bValue === null || bValue === undefined) {
            return 1;
        }

        if (bValue < aValue) {
            return -1;
        }
        if (bValue > aValue) {
            return 1;
        }
        return 0;
    }


    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    //////////////////////////////////////////////////////////

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (snackbarOpen) {
            fetchProjects();
        }

        const closeSnackbarOnOutsideClick = (event) => {
            if (snackbarOpen && !event.target.closest('.MuiAlert-root')) {
                setSnackbarOpen(false);
            }
        };

        document.addEventListener('click', closeSnackbarOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeSnackbarOnOutsideClick);
        };
    }, [snackbarOpen, fetchProjects]);

    const handleCheckboxChange = (event, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [...selectedRows];

        if (selectedIndex === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(selectedIndex, 1);
        }

        setSelectedRows(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = dropdownData.map((row) => row.id);
            setSelectedRows(newSelected);
        } else {
            setSelectedRows([]);
        }
    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    ////////////////////////////////////////////////////////////////////////////////////
    // Modify checkbox handling functions
    const handleCheckboxChangeAddress = (event, addressId, projectId) => {
        const selectedAddressesForProject =
            selectedAddressesPerProject[projectId] || [];
        const index = selectedAddressesForProject.indexOf(addressId);

        if (index === -1) {
            // Add the address ID to the selected addresses for this project row
            setSelectedAddressesPerProject({
                ...selectedAddressesPerProject,
                [projectId]: [...selectedAddressesForProject, addressId],
            });
        } else {
            // Remove the address ID from the selected addresses for this project row
            setSelectedAddressesPerProject({
                ...selectedAddressesPerProject,
                [projectId]: selectedAddressesForProject.filter(
                    (id) => id !== addressId
                ),
            });
        }
    };

    const handleSelectAllClickAddress = (event, row) => {
        if (event.target.checked) {
            const addressIds = row.addresses.map((address) => address.id);
            setSelectedAddressesPerProject({
                ...selectedAddressesPerProject,
                [row.id]: addressIds,
            });
        } else {
            setSelectedAddressesPerProject({
                ...selectedAddressesPerProject,
                [row.id]: [],
            });
        }
    };

    const isAddressSelected = (id, projectId) => {
        const selectedAddressesForProject =
            selectedAddressesPerProject[projectId] || [];
        return selectedAddressesForProject.indexOf(id) !== -1;
    };

    ////////////////////////////////////////////////////////////////////////////////////
    // Function to toggle expansion of project rows
    const toggleProjectRowExpansion = (projectId) => {
        setExpandedProjectRows((prevExpandedProjectRows) => {
            const newState = !prevExpandedProjectRows[projectId];
            const updatedProjectRows = {
                ...prevExpandedProjectRows,
                [projectId]: newState,
            };
            return updatedProjectRows;
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangePageAddress = (projectId) => (event, newPage) => {
        setPageAddress((prev) => ({ ...prev, [projectId]: newPage }));
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeRowsPerPageAddress = (projectId) => (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPageAddress((prev) => ({
          ...prev,
          [projectId]: newRowsPerPage,
        }));
        setPageAddress((prev) => ({ ...prev, [projectId]: 0 }));
    };

    // Define a styled TableCell component
    const StyledTableCell = styled(TableCell)({
        color: colors.greenAccent[500], // Set the text color to greenAccent[500]
        transition: 'color 0.3s', // Add a transition effect for color change
        '&:hover': {
            color: colors.greenAccent[700], // Change the text color to greenAccent[700] on hover
            textDecoration: 'underline', // Underline the text on hover
        },
    });

    // Then use StyledTableCell in your component

    const handleEditProjectNameClick = (id) => {
        const selectedReportProject = dropdownData.find(
            (project) => project.id === id
        );
        setSelectedProject(selectedReportProject);
        setEditNameModalOpen(true);
    };

    const handleViewProjectDescClick = (id) => {
        const selectedReportProject = dropdownData.find(
            (project) => project.id === id
        );
        setSelectedProject(selectedReportProject);
        setViewDescriptionModalOpen(true);
    };

    const handleEditAddNameClick = (id) => {
        const selectedReportProject = dropdownData.find(
            (project) => project.id === id
        );
        setSelectedProject(selectedReportProject);
        setEditAddressNameModalOpen(true);
    };

    const handleViewAddDescClick = (id) => {
        const selectedReportProject = dropdownData.find(
            (project) => project.id === id
        );
        setSelectedProject(selectedReportProject);
        setViewAddDescModalOpen(true);
    };

    const resetValues = (event) => {
        setExpandedProjectRows({});
        setPage(0);
        setPageAddress({});
        setSelectedRows([]);
        setSelectedAddressesPerProject({});
    };

    const resetAddresses = (event) => {
        setPageAddress({});
        setSelectedAddressesPerProject({});
    };

    const someAddressesSelectedInCurrentProject = (row) => {
        const selectedAddressesForProject =
            selectedAddressesPerProject[row.id] || [];
        return selectedAddressesForProject.length > 0;
    };

    const allAddressesSelectedInCurrentProject = (row) => {
        const selectedAddressesForProject =
            selectedAddressesPerProject[row.id] || [];
        return selectedAddressesForProject.length === row.addresses.length;
    };

    const columns = [
        { columnHeaderName: 'ID', column: 'id', sortable: true, center: false },
        { columnHeaderName: 'Title', column: 'title', sortable: true, center: false },
        { columnHeaderName: 'Description', column: 'description', sortable: true, center: false },
    ];

    const addColumns = [
        { columnHeaderName: 'ID', column: 'id', sortable: true, center: false },
        { columnHeaderName: 'Title', column: 'title', sortable: true, center: false },
        { columnHeaderName: 'Description', column: 'description', sortable: true, center: false },
    ];

    return (
        <Box m="20px">
            <Header title="Properties" subtitle="Managing the Properties" />
            {loading ? (
                <CircularLoading isOpen={loading} />
            ) : (
                <>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{
                            vertical: 'top', // Set the vertical position to 'top'
                            horizontal: 'center', // Center horizontally
                        }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={
                                snackbarType
                                    ? snackbarType === 'success'
                                        ? 'success'
                                        : snackbarType === 'warning'
                                          ? 'warning'
                                          : 'error'
                                    : 'info'
                            }
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    snackbarType === 'success'
                                        ? colors.greenAccent[600]
                                        : snackbarType === 'warning'
                                          ? '#FFFF8F'
                                          : colors.redAccent[600],
                                color:
                                    snackbarType === 'warning'
                                        ? colors.primary[300]
                                        : colors.grey[100],
                            }}
                        >
                            <Typography
                                variant="h6"
                                color={
                                    snackbarType === 'warning'
                                        ? colors.primary[300]
                                        : colors.grey[100]
                                }
                            >
                                {snackbarMessage}
                            </Typography>
                        </Alert>
                    </Snackbar>
                    <Box
                        m="40px 0 0 0"
                        height="65vh"
                        sx={{
                            '& .MuiTableHead-root': {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: 'none',
                            },
                            '& .MuiTablePagination-root': {
                                borderTop: 'none',
                                backgroundColor: colors.blueAccent[700],
                            },
                            '& .MuiCheckbox-root': {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        <TableContainer
                            sx={{
                                height: '100%',
                                backgroundColor: colors.primary[400],
                                borderRadius: '5px',
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>{' '}
                                        {/* New cell for the arrow icon */}
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={
                                                    selectedRows.length > 0 &&
                                                    selectedRows.length <
                                                        dropdownData.length
                                                }
                                                checked={
                                                    dropdownData.length > 0 &&
                                                    selectedRows.length ===
                                                        dropdownData.length
                                                }
                                                onChange={handleSelectAllClick}
                                                inputProps={{
                                                    'aria-label':
                                                        'select all inspections',
                                                }}
                                            />
                                        </TableCell>
                                        {columns.map((column) => (
                                            <TableHeaderCell
                                                key={column.column}
                                                columns={columns}
                                                column={column.column}
                                                columnHeaderName={column.columnHeaderName}
                                                order={order}
                                                orderBy={orderBy}
                                                handleRequestSort={handleRequestSort}
                                                handleFilter={handleFilter}
                                                sortable={column.sortable}
                                                center={column.center}
                                            />
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData()
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => {
                                            const isItemSelected = isSelected(
                                                row.id
                                            );
                                            const isExpandedProject =
                                                expandedProjectRows[row.id]; // Check project expansion
                                            return (
                                                <React.Fragment key={row.id}>
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={
                                                            isItemSelected
                                                        }
                                                        tabIndex={-1}
                                                        selected={
                                                            isItemSelected
                                                        }
                                                        sx={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <TableCell
                                                            onClick={() =>
                                                                toggleProjectRowExpansion(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {isExpandedProject ? (
                                                                <ExpandLessIcon />
                                                            ) : (
                                                                <ExpandMoreIcon />
                                                            )}
                                                        </TableCell>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={
                                                                    isItemSelected
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleCheckboxChange(
                                                                        event,
                                                                        row.id
                                                                    )
                                                                }
                                                                inputProps={{
                                                                    'aria-labelledby': `checkbox-${row.id}`,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                        >
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={() =>
                                                                showAddToTeamButton && handleEditProjectNameClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {row.title}
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={() =>
                                                                handleViewProjectDescClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {row
                                                                .description
                                                                .length >
                                                            30
                                                                ? `${row.description.substring(0, 30)}...`
                                                                : row.description}
                                                        </TableCell>
                                                    </TableRow>
                                                    {isExpandedProject && (
                                                        <>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                        paddingTop: 0,
                                                                    }}
                                                                    colSpan={10}
                                                                >
                                                                    <Accordion>
                                                                        <AccordionSummary
                                                                            expandIcon={
                                                                                <ExpandMoreIcon />
                                                                            }
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    colors
                                                                                        .primary[400],
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                Addresses
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    colors
                                                                                        .primary[400],
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                // height="70vh"
                                                                                sx={{
                                                                                    '& .MuiTableHead-root':
                                                                                        {
                                                                                            backgroundColor:
                                                                                                colors
                                                                                                    .blueAccent[500],
                                                                                            borderBottom:
                                                                                                'none',
                                                                                        },
                                                                                    '& .MuiTablePagination-root':
                                                                                        {
                                                                                            borderTop:
                                                                                                'none',
                                                                                            backgroundColor:
                                                                                                colors
                                                                                                    .blueAccent[500],
                                                                                        },
                                                                                    '& .MuiCheckbox-root':
                                                                                        {
                                                                                            color: `${colors.greenAccent[200]} !important`,
                                                                                        },
                                                                                }}
                                                                            >
                                                                                <TableContainer
                                                                                    sx={{
                                                                                        height: '100%',
                                                                                        backgroundColor:
                                                                                            colors
                                                                                                .primary[400],
                                                                                        borderRadius:
                                                                                            '5px',
                                                                                    }}
                                                                                >
                                                                                    <Table>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                {/* <TableCell></TableCell> New cell for the arrow icon */}
                                                                                                <TableCell padding="checkbox">
                                                                                                    <Checkbox
                                                                                                        color="primary"
                                                                                                        indeterminate={
                                                                                                            someAddressesSelectedInCurrentProject(
                                                                                                                row
                                                                                                            ) &&
                                                                                                            !allAddressesSelectedInCurrentProject(
                                                                                                                row
                                                                                                            )
                                                                                                        }
                                                                                                        checked={allAddressesSelectedInCurrentProject(
                                                                                                            row
                                                                                                        )}
                                                                                                        onChange={(
                                                                                                            event
                                                                                                        ) =>
                                                                                                            handleSelectAllClickAddress(
                                                                                                                event,
                                                                                                                row
                                                                                                            )
                                                                                                        }
                                                                                                        inputProps={{
                                                                                                            'aria-label':
                                                                                                                'select all addresses',
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                {addColumns.map((column) => (
                                                                                                    <TableHeaderCell
                                                                                                        key={column.column}
                                                                                                        columns={addColumns}
                                                                                                        column={column.column}
                                                                                                        columnHeaderName={column.columnHeaderName}
                                                                                                        order={addressOrder}
                                                                                                        orderBy={addressOrderBy}
                                                                                                        handleRequestSort={handleAddressRequestSort}
                                                                                                        handleFilter={handleAddressFilter}
                                                                                                        sortable={column.sortable}
                                                                                                        center={column.center}
                                                                                                    />
                                                                                                ))}
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {filteredRow(row.addresses, addressFilterOptions, addressOrder, addressOrderBy)
                                                                                                .slice(
                                                                                                    (pageAddress[row.id] || 0) * (rowsPerPageAddress[row.id] || defaultRowsPerPageAddress),
                                                                                                    ((pageAddress[row.id] || 0) + 1) * (rowsPerPageAddress[row.id] || defaultRowsPerPageAddress)
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        address
                                                                                                    ) => {
                                                                                                        const isItemSelected =
                                                                                                            isAddressSelected(
                                                                                                                address.id,
                                                                                                                row.id
                                                                                                            );
                                                                                                        return (
                                                                                                            <React.Fragment
                                                                                                                key={
                                                                                                                    address.id
                                                                                                                }
                                                                                                            >
                                                                                                                <TableRow
                                                                                                                    hover
                                                                                                                    role="checkbox"
                                                                                                                    aria-checked={
                                                                                                                        isItemSelected
                                                                                                                    }
                                                                                                                    tabIndex={
                                                                                                                        -1
                                                                                                                    }
                                                                                                                    selected={
                                                                                                                        isItemSelected
                                                                                                                    }
                                                                                                                    sx={{
                                                                                                                        cursor: 'pointer',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <TableCell padding="checkbox">
                                                                                                                        <Checkbox
                                                                                                                            color="primary"
                                                                                                                            checked={
                                                                                                                                isItemSelected
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                event
                                                                                                                            ) =>
                                                                                                                                handleCheckboxChangeAddress(
                                                                                                                                    event,
                                                                                                                                    address.id,
                                                                                                                                    row.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            inputProps={{
                                                                                                                                'aria-labelledby': `checkbox-${address.id}`,
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {
                                                                                                                            address.id
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                      onClick={() => {
                                                                                                                        showAddToTeamButton && setSelectedAddress(
                                                                                                                              address
                                                                                                                          );
                                                                                                                          showAddToTeamButton && handleEditAddNameClick(row.id);
                                                                                                                      }}>
                                                                                                                        {
                                                                                                                            address.title
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        onClick={() => {
                                                                                                                            setSelectedAddress(
                                                                                                                                address
                                                                                                                            );
                                                                                                                            handleViewAddDescClick(row.id);
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {address
                                                                                                                            .description
                                                                                                                            .length >
                                                                                                                        30
                                                                                                                            ? `${address.description.substring(0, 30)}...`
                                                                                                                            : address.description}
                                                                                                                    </TableCell>
                                                                                                                </TableRow>
                                                                                                            </React.Fragment>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                                <CustomFooter
                                                                                    rowCount={
                                                                                        row
                                                                                            .addresses
                                                                                            .length
                                                                                    }
                                                                                    page={pageAddress[row.id] || 0}
                                                                                    rowsPerPage={rowsPerPageAddress[row.id] || defaultRowsPerPageAddress}
                                                                                    onPageChange={handleChangePageAddress(row.id)}
                                                                                    onRowsPerPageChange={handleChangeRowsPerPageAddress(row.id)}
                                                                                    backgroundColor={
                                                                                        colors
                                                                                            .blueAccent[500]
                                                                                    }
                                                                                    onActionSelect={(action) => showAddToTeamButton && handleSelectActionAddress(action, row)}
                                                                                    row={row} // Pass row as prop if available
                                                                                    isOuterTable={false}
                                                                                    buttons={showAddToTeamButton ? [
                                                                                        {
                                                                                            label: 'Add',
                                                                                            onClick:
                                                                                                () => {
                                                                                                    setSelectedProject(
                                                                                                        row
                                                                                                    );
                                                                                                    setAddAddressModalOpen(
                                                                                                        true
                                                                                                    );
                                                                                                },
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor:
                                                                                                    colors
                                                                                                        .greenAccent[500],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            colors
                                                                                                                .greenAccent[700],
                                                                                                    },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            label: 'Delete',
                                                                                            onClick:
                                                                                                () => {
                                                                                                    setSelectedProject(
                                                                                                        row
                                                                                                    );
                                                                                                    setDeleteAddressModalOpen(
                                                                                                        true
                                                                                                    );
                                                                                                },
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor:
                                                                                                    colors
                                                                                                        .redAccent[500],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            colors
                                                                                                                .redAccent[700],
                                                                                                    },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            label: 'Reset',
                                                                                            onClick: () => resetAddresses(),
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor: colors.redAccent[500],
                                                                                                '&:hover': {
                                                                                                    backgroundColor:
                                                                                                        colors.redAccent[700],
                                                                                                },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        // Add other buttons as needed
                                                                                    ]: []}
                                                                                />
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomFooter
                            rowCount={dropdownData.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            backgroundColor={colors.blueAccent[700]}
                            onActionSelect={showAddToTeamButton ? handleSelectAction : null}
                            isOuterTable={true}
                            buttons={showAddToTeamButton ? [
                                {
                                    label: 'Add',
                                    onClick: () =>
                                        setAddModalOpen(true),
                                    styles: {
                                        color: 'white',
                                        backgroundColor:
                                            colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.greenAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                                {
                                    label: 'Delete',
                                    onClick: () =>
                                        setDeleteProjectModalOpen(true),
                                    styles: {
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.redAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                                {
                                    label: 'Reset',
                                    onClick: () => resetValues(),
                                    styles: {
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.redAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                            ] : []}
                        />
                        <AddProjectModal
                            isAddModalOpen={isAddModalOpen}
                            setAddModalOpen={setAddModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <DeleteProjectModal
                            isDeleteProjectModalOpen={isDeleteProjectModalOpen}
                            setDeleteProjectModalOpen={setDeleteProjectModalOpen}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <EditProjectNameModal
                            isEditNameModalOpen={
                                isEditNameModalOpen
                            }
                            setEditNameModalOpen={
                                setEditNameModalOpen
                            }
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <ViewProjectDescModal
                            isViewDescriptionModalOpen={
                                isViewDescriptionModalOpen
                            }
                            setViewDescriptionModalOpen={
                                setViewDescriptionModalOpen
                            }
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <AddAddressModal
                            isAddAddressModalOpen={
                                isAddAddressModalOpen
                            }
                            setAddAddressModalOpen={
                                setAddAddressModalOpen
                            }
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <DeleteAddressModal
                            isDeleteAddressModalOpen={isDeleteAddressModalOpen}
                            setDeleteAddressModalOpen={setDeleteAddressModalOpen}
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            selectedRowsAddress={selectedAddressesPerProject}
                            setSelectedRowsAddress={
                                setSelectedAddressesPerProject
                            }
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <EditAddressNameModal
                            isEditAddressNameModalOpen={
                                isEditAddressNameModalOpen
                            }
                            setEditAddressNameModalOpen={
                                setEditAddressNameModalOpen
                            }
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            selectedAddress={selectedAddress}
                            setSelectedAddress={setSelectedAddress}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <ViewAddressDescriptionModal
                            isViewAddDescModalOpen={isViewAddDescModalOpen}
                            setViewAddDescModalOpen={setViewAddDescModalOpen}
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            selectedAddress={selectedAddress}
                            setSelectedAddress={setSelectedAddress}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Property;
