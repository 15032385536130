// src/components/EditableField.jsx

import React, { useEffect, useRef } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';

/**
 * EditableField Component
 *
 * Props:
 * - value: The current value of the field
 * - fieldKey: The key identifier for the field
 * - onFieldChange: Function to handle the change in field value
 * - validationSchema: Yup validation schema for the field
 * - onSave: Callback function to execute after saving
 * - onCancel: Callback function to execute after canceling
 */
const EditableField = ({ value, fieldKey, onFieldChange, validationSchema, onSave, onCancel }) => {
    const inputRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            [fieldKey]: value || '',
        },
        validationSchema: Yup.object({
            [fieldKey]: validationSchema,
        }),
        enableReinitialize: true, // Allows Formik to reset when initialValues change
        onSubmit: (values) => {
            onFieldChange(fieldKey, values[fieldKey]);
            onSave();
        },
    });

    useEffect(() => {
        if (formik.isSubmitting && inputRef.current) {
            inputRef.current.focus();
        }
    }, [formik.isSubmitting]);

    const handleCancel = () => {
        formik.resetForm();
        onCancel();
    };

    return (
        <Box display="flex" alignItems="center" width="100%">
            <TextField
                id={fieldKey}
                name={fieldKey}
                value={formik.values[fieldKey]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[fieldKey] && Boolean(formik.errors[fieldKey])}
                helperText={formik.touched[fieldKey] && formik.errors[fieldKey]}
                variant="standard"
                size="small"
                inputRef={inputRef}
                fullWidth
                sx={{
                    flexGrow: 1,
                    mr: 1,
                }}
            />
            <Box display="flex" gap={0.5}>
                <IconButton
                    aria-label="Save"
                    onClick={formik.handleSubmit}
                    disabled={!formik.isValid || !formik.dirty}
                    size="small"
                >
                    <CheckIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                    aria-label="Cancel"
                    onClick={handleCancel}
                    size="small"
                >
                    <CloseIcon fontSize="small" color="error" />
                </IconButton>
            </Box>
        </Box>
    );
};

export default EditableField;
