import {
    InputLabel,
    Box,
    Typography,
    useTheme,
    Button,
    Select,
    MenuItem,
    Modal,
    TextField,
} from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Formik } from 'formik';
import { tokens } from '../../theme';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as yup from 'yup';
import useProjectData from '../../hooks/useProjectData';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DetailedViewModal from './DetailedViewModal';

const EditWarrantyModal = (props) => {
    const {
        isEditModalOpen,
        setEditModalOpen,
        setSnackbarMessage,
        setSnackbarType,
        setSnackbarOpen,
        selectedWarranty,
        setSelectedWarranty,
    } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const { dropdownData } = useProjectData();
    const [addresses, setAddresses] = useState([]);
    const [isAddressSelectEnabled, setAddressSelectEnabled] = useState(true);
    const [isDetailedViewOpen, setDetailedViewOpen] = useState(false);
    const [issue, setIssue] = useState(selectedWarranty?.issue ?? '');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [selectedAction, setSelectedAction] = useState('');

    const updateAddresses = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(
                `project/project/${selectedWarranty?.address?.project.id}/addresses`
            );
            setAddresses(response.data);
            setAddressSelectEnabled(true);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    }, [
        axiosPrivate,
        navigate,
        location,
        selectedWarranty?.address?.project.id,
        setSnackbarMessage,
        setSnackbarType,
        setSnackbarOpen,
    ]);

    useEffect(() => {
        setAddresses([]);
        if (dropdownData && isEditModalOpen) {
            setIssue(selectedWarranty?.issue);
            updateAddresses();
        }
    }, [
        dropdownData,
        isEditModalOpen,
        selectedWarranty?.issue,
        updateAddresses,
    ]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleProjectChange = async (event, setFieldValue) => {
        const selectedProjectId = event.target.value;
        if (selectedProjectId) {
            try {
                const response = await axiosPrivate.get(
                    `project/project/${selectedProjectId}/addresses`
                );
                setAddresses(response.data);
                setAddressSelectEnabled(true);
                setFieldValue('address_id', '');
                setFieldValue('project_id', selectedProjectId);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login', {
                        state: { from: location },
                        replace: true,
                    });
                } else {
                    setSnackbarMessage(
                        error.response?.data.message || 'Error occurred'
                    );
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                }
            }
        } else {
            setAddresses([]);
            setAddressSelectEnabled(false);
            setFieldValue('address_id', '');
            setFieldValue('project_id', '');
        }
    };

    const handleAddressChange = async (event, setFieldValue) => {
        const selectedAddressId = event.target.value;
        setFieldValue('address_id', selectedAddressId);
    };

    const checkoutSchema = yup.object().shape({
        project_id: yup.string().required('required'),
        address_id: yup.string().required('required'),
        reported_date: yup
        .date()
        .nullable()
        .required('Reported date is required'),
        scheduled_fix_date: yup.date().nullable().notRequired(),
        completetion_date: yup.date().nullable().notRequired(),
        completed: yup.string().required('required'),
        issue: yup.string().nullable().notRequired(),
    });

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const data = {
                ...values,
                issue: issue,
            };
            if (data.completed === 'yes') {
                data.completed = true;
            } else {
                data.completed = false;
            }
            if (values.reported_date) {
                data.reported_date = dayjs(values.reported_date).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
            }
            if (values.scheduled_fix_date) {
                data.scheduled_fix_date = dayjs(
                    values.scheduled_fix_date
                ).format('YYYY-MM-DD HH:mm:ss');
            }
            if (values.completion_date) {
                data.completion_date = dayjs(values.completion_date).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
            }

            const controller = new AbortController();

            await axiosPrivate.put(
                `/warranty/warranty/${selectedWarranty.id}`,
                data,
                {
                    signal: controller.signal,
                }
            );

            setSnackbarMessage(`Updated warranty successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            resetForm();
            setIssue('');
            setAddressSelectEnabled(false);
            setAddresses([]);
            setEditModalOpen(false);
            setSelectedWarranty(null);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    err.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleReset = (resetForm) => {
        resetForm();
        setIssue(selectedWarranty?.issue);
        setAddresses([]);
        updateAddresses();
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Update Warranty':
                handleFormSubmit();
                break;
            case 'Reset':
                handleReset();
                break;
            case 'Close':
                setEditModalOpen(false);
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal
                open={isEditModalOpen}
                onClose={() => {
                    setEditModalOpen(false);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        width: isMobile ? '80%' : '40%',
                        height: isMobile ? '60%' : '60%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            height: '7%',
                            width: '98%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                setIssue('');
                                setAddressSelectEnabled(false);
                                setAddresses([]);
                                setEditModalOpen(false);
                                setSelectedWarranty(null);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography
                            variant={isMobile ? 'h5' : 'h4'}
                            color={colors.grey[100]}
                            fontWeight="bold"
                            textAlign="center"
                        >
                            Edit Warranty:
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            p: isMobile ? 2 : 3,
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={{
                                reported_date: selectedWarranty?.reported_date
                                    ? dayjs(
                                          dayjs(
                                              selectedWarranty.reported_date
                                          ).format('MM/DD/YYYY hh:mm A')
                                      )
                                    : null,
                                scheduled_fix_date:
                                    selectedWarranty?.scheduled_fix_date
                                        ? dayjs(
                                              dayjs(
                                                  selectedWarranty.scheduled_fix_date
                                              ).format('MM/DD/YYYY hh:mm A')
                                          )
                                        : null,
                                completetion_date:
                                    selectedWarranty?.completetion_date
                                        ? dayjs(
                                              dayjs(
                                                  selectedWarranty.completetion_date
                                              ).format('MM/DD/YYYY hh:mm A')
                                          )
                                        : null,
                                completed:
                                    selectedWarranty?.completed !== null
                                        ? selectedWarranty?.completed
                                            ? 'yes'
                                            : 'no'
                                        : '',
                                address_id: selectedWarranty?.address?.id ?? '',
                                project_id:
                                    selectedWarranty?.address?.project.id ?? '',
                            }}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                resetForm,
                                setFieldValue,
                            }) => (
                                <form
                                    style={{ height: '98%' }}
                                    onSubmit={handleSubmit}
                                >
                                    <Box
                                        sx={{
                                            margin: '1% 3% 3% 3%', // top right bottom left
                                        }}
                                    >
                                        <Box
                                            display="grid"
                                            gap="30px"
                                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                            sx={{
                                                '& > div': {
                                                    gridColumn: isNonMobile
                                                        ? undefined
                                                        : 'span 4',
                                                },
                                            }}
                                        >
                                            <FormControl
                                                variant="filled"
                                                sx={{ gridColumn: 'span 4' }}
                                            >
                                                <InputLabel id="project-label">
                                                    Project
                                                </InputLabel>
                                                <Select
                                                    labelId="project"
                                                    id="project-select"
                                                    fullWidth
                                                    value={values.project_id}
                                                    name="project_id"
                                                    onChange={(event) => {
                                                        handleProjectChange(
                                                            event,
                                                            setFieldValue
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    label="Project"
                                                    error={
                                                        !!touched.project_id &&
                                                        !!errors.project_id
                                                    }
                                                >
                                                    {dropdownData.map(
                                                        (project) => (
                                                            <MenuItem
                                                                key={project.id}
                                                                value={
                                                                    project.id
                                                                }
                                                            >
                                                                {project.title}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <FormControl
                                                variant="filled"
                                                sx={{ gridColumn: 'span 2' }}
                                            >
                                                <InputLabel id="address-label">
                                                    Address
                                                </InputLabel>
                                                {addresses.length > 0 ? (
                                                    <Select
                                                        labelId="address"
                                                        id="address-select"
                                                        fullWidth
                                                        value={
                                                            values.address_id
                                                        }
                                                        onChange={(event) => {
                                                            handleAddressChange(
                                                                event,
                                                                setFieldValue
                                                            );
                                                        }}
                                                        onBlur={handleBlur}
                                                        label="Address"
                                                        name="address_id"
                                                        disabled={
                                                            !isAddressSelectEnabled
                                                        }
                                                        error={
                                                            !!touched.address_id &&
                                                            !!errors.address_id
                                                        }
                                                    >
                                                        {addresses.map(
                                                            (address) => (
                                                                <MenuItem
                                                                    key={
                                                                        address.id
                                                                    }
                                                                    value={
                                                                        address.id
                                                                    }
                                                                >
                                                                    {
                                                                        address.title
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                ) : (
                                                    <Typography variant="body1">
                                                        Loading addresses...
                                                    </Typography>
                                                )}
                                            </FormControl>
                                            <DateTimePicker
                                                fullWidth
                                                label="Reported date"
                                                onBlur={handleBlur}
                                                onChange={(date) =>
                                                    setFieldValue('reported_date', date)
                                                }
                                                value={values.reported_date}
                                                name="reported_date"
                                                sx={{ gridColumn: 'span 2' }}
                                                disabled={!values.address_id}
                                                slotProps={{
                                                    textField: {
                                                        error: Boolean(
                                                            touched.reported_date &&
                                                            errors.reported_date
                                                        ),
                                                        helperText:
                                                            touched.reported_date &&
                                                            errors.reported_date
                                                                ? errors.reported_date
                                                                : '',
                                                    },
                                                }}
                                            />
                                            <DateTimePicker
                                                fullWidth
                                                variant="filled"
                                                label="Fix date"
                                                onBlur={handleBlur}
                                                onChange={(date) =>
                                                    handleChange({
                                                        target: {
                                                            name: 'scheduled_fix_date',
                                                            value: date,
                                                        },
                                                    })
                                                }
                                                value={
                                                    values.scheduled_fix_date
                                                }
                                                name="scheduled_fix_date"
                                                sx={{ gridColumn: 'span 2' }}
                                                disabled={!values.address_id}
                                            />
                                            <DateTimePicker
                                                fullWidth
                                                variant="filled"
                                                label="Completion date"
                                                onBlur={handleBlur}
                                                onChange={(date) =>
                                                    handleChange({
                                                        target: {
                                                            name: 'completion_date',
                                                            value: date,
                                                        },
                                                    })
                                                }
                                                value={values.completion_date}
                                                name="completion_date"
                                                sx={{ gridColumn: 'span 2' }}
                                                disabled={!values.address_id}
                                            />
                                            <FormControl
                                                variant="filled"
                                                sx={{ gridColumn: 'span 2' }}
                                            >
                                                <InputLabel id="completed-label">
                                                    Completion Status
                                                </InputLabel>
                                                <Select
                                                    labelId="completed-status"
                                                    id="completed-status-select"
                                                    fullWidth
                                                    value={values.completed}
                                                    onChange={(event) => {
                                                        setFieldValue(
                                                            'completed',
                                                            event.target.value
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    label="Completed Status"
                                                    disabled={
                                                        !values.address_id
                                                    }
                                                    name="completed"
                                                >
                                                    <MenuItem value="yes">
                                                        Yes
                                                    </MenuItem>
                                                    <MenuItem value="no">
                                                        No
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                fullWidth
                                                value={
                                                    issue.length > 30
                                                        ? `${issue.substring(0, 30)}...`
                                                        : `${issue}`
                                                }
                                                onClick={(event) => {
                                                    if (
                                                        !event.target.tagName
                                                            .toLowerCase()
                                                            .includes('label')
                                                    ) {
                                                        setDetailedViewOpen(
                                                            true
                                                        );
                                                    }
                                                }}
                                                variant="filled"
                                                type="text"
                                                label="Issue"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name="issue"
                                                sx={{ gridColumn: 'span 2' }}
                                                disabled={!values.address_id}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                            {isDetailedViewOpen && (
                                                <DetailedViewModal
                                                    isDetailedViewOpen={
                                                        isDetailedViewOpen
                                                    }
                                                    setDetailedViewOpen={
                                                        setDetailedViewOpen
                                                    }
                                                    issue={issue}
                                                    setIssue={setIssue}
                                                />
                                            )}
                                        </Box>
                                        {isMobile && (
                                            <FormControl
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    mt: 2,
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                <Select
                                                    value={selectedAction}
                                                    onChange={(e) =>
                                                        handleSelectAction(
                                                            e.target.value
                                                        )
                                                    }
                                                    displayEmpty
                                                    inputProps={{
                                                        'aria-label':
                                                            'Select action',
                                                    }}
                                                    sx={{
                                                        color: colors.grey[100],
                                                        backgroundColor:
                                                            colors.primary[400],
                                                        borderRadius: '8px',
                                                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                        '.MuiOutlinedInput-notchedOutline':
                                                            {
                                                                borderColor:
                                                                    colors.grey[300],
                                                            },
                                                        '&:hover .MuiOutlinedInput-notchedOutline':
                                                            {
                                                                borderColor:
                                                                    colors.grey[100],
                                                            },
                                                        '& .MuiSvgIcon-root': {
                                                            color: colors.grey[100],
                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        value=""
                                                        disabled
                                                    >
                                                        Select an action
                                                    </MenuItem>
                                                    <MenuItem value="Update Warranty">
                                                        Update Warranty
                                                    </MenuItem>
                                                    <MenuItem value="Reset">
                                                        Reset
                                                    </MenuItem>
                                                    <MenuItem value="Close">
                                                        Close
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Box>
                                    {!isMobile && (
                                        <Box
                                            sx={{
                                                p: isMobile ? 2 : 3,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                gap: 2,
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor:
                                                        colors.greenAccent[500],
                                                    '&:hover': {
                                                        backgroundColor:
                                                            colors.greenAccent[700],
                                                    },
                                                    mr: 1,
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Update Warranty
                                            </Button>
                                            <Button
                                                type="button"
                                                variant="contained"
                                                onClick={() =>
                                                    handleReset(resetForm)
                                                }
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor:
                                                        colors.redAccent[500],
                                                    '&:hover': {
                                                        backgroundColor:
                                                            colors.redAccent[700],
                                                    },
                                                    mr: 1,
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    setEditModalOpen(false);
                                                }}
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor:
                                                        colors.redAccent[500],
                                                    '&:hover': {
                                                        backgroundColor:
                                                            colors.redAccent[700],
                                                    },
                                                    whiteSpace: 'nowrap',
                                                }}
                                                variant="contained"
                                            >
                                                Close
                                            </Button>
                                        </Box>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </LocalizationProvider>
    );
};

export default EditWarrantyModal;
