import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    List,
    ListItem,
    Checkbox,
    useTheme,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
    FormControl,
    Select,
    MenuItem,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tokens } from '../../../theme';
import useProjectDocumentData from '../../../hooks/useProjectDocumentData';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ViewAnyDocumentModal from './ViewAnyDocumentModal';

const AttachDocumentModal = ({
    isAttachDocumentModalOpen,
    setAttachDocumentModalOpen,
    setAddAttachDocumentModalOpen,
    selectedWarranty,
    setSelectedWarranty,
    selectedInvoice,
    setSelectedInvoice,
    setSnackbarMessage,
    setSnackbarType,
    setSnackbarOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const { dropdownData } = useProjectDocumentData();
    const [checkedDocuments, setCheckedDocuments] = useState([]);
    const [expandedProjects, setExpandedProjects] = useState([]);
    const [expandedAddresses, setExpandedAddresses] = useState({});
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [isViewAnyDocumentModalOpen, setViewAnyDocumentModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (selectedWarranty && selectedWarranty.invoices) {
            const documentsIds = selectedWarranty.invoices.reduce(
                (acc, invoice) => {
                    acc.push(
                        ...invoice.documents.map((document) => document.id)
                    );
                    return acc;
                },
                []
            );
            const newCheckedDocuments = dropdownData.reduce((acc, project) => {
                project.addresses.forEach((address) => {
                    address.documents.forEach((document) => {
                        if (documentsIds.includes(document.id)) {
                            acc.push(document.id);
                        }
                    });
                });
                return acc;
            }, []);
            setCheckedDocuments(newCheckedDocuments);
        }
    }, [selectedWarranty, dropdownData, isAttachDocumentModalOpen]);

    const handleReset = () => {
        if (selectedWarranty && selectedWarranty.invoices) {
            const documentsIds = selectedWarranty.invoices.reduce(
                (acc, invoice) => {
                    acc.push(
                        ...invoice.documents.map((document) => document.id)
                    );
                    return acc;
                },
                []
            );
            const initialCheckedDocuments = dropdownData.reduce(
                (acc, project) => {
                    project.addresses.forEach((address) => {
                        address.documents.forEach((document) => {
                            if (documentsIds.includes(document.id)) {
                                acc.push(document.id);
                            }
                        });
                    });
                    return acc;
                },
                []
            );
            setCheckedDocuments(initialCheckedDocuments);
        }
    };

    const handleClose = () => {
        setCheckedDocuments([]);
        setAttachDocumentModalOpen(false);
    };

    const handleCheckboxChange = (documentId) => {
        setCheckedDocuments((prevCheckedDocuments) => {
            const isChecked = prevCheckedDocuments.includes(documentId);
            if (isChecked) {
                return prevCheckedDocuments.filter((id) => id !== documentId);
            } else {
                return [...prevCheckedDocuments, documentId];
            }
        });
    };

    const handleSubmit = async () => {
        const controller = new AbortController();
        try {
            await axiosPrivate.post(
                `/warranty/warranty/${selectedWarranty.id}/invoice/${selectedInvoice.id}/documents`,
                {
                    document_ids: checkedDocuments,
                },
                {
                    signal: controller.signal,
                }
            );

            setSnackbarMessage(`Attached documents successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);

            setSelectedWarranty(null);
            setSelectedInvoice(null);
            setCheckedDocuments([]);
            setAddAttachDocumentModalOpen(false);
            setAttachDocumentModalOpen(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setSnackbarMessage(
                    error.response?.data.message || 'Error occurred'
                );
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSelectAction = (action) => {
        setSelectedAction(action);

        switch (action) {
            case 'Save Changes':
                handleSubmit();
                break;
            case 'Reset':
                handleReset();
                break;
            case 'Close':
                handleClose();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const handleProjectAccordionChange = (projectId) => {
        setExpandedProjects((prevExpandedProjects) => {
            if (prevExpandedProjects.includes(projectId)) {
                const newExpandedAddresses = { ...expandedAddresses };
                newExpandedAddresses[projectId] = {};
                setExpandedAddresses(newExpandedAddresses);
                return prevExpandedProjects.filter((id) => id !== projectId);
            } else {
                if (!expandedAddresses[projectId]) {
                    setExpandedAddresses((prevExpandedAddresses) => ({
                        ...prevExpandedAddresses,
                        [projectId]: {},
                    }));
                }
                return [...prevExpandedProjects, projectId];
            }
        });
    };

    const handleAddressAccordionChange = (projectId, addressId) => {
        setExpandedAddresses((prevExpandedAddresses) => ({
            ...prevExpandedAddresses,
            [projectId]: {
                ...prevExpandedAddresses[projectId],
                [addressId]: !prevExpandedAddresses[projectId][addressId],
            },
        }));
    };

    return (
        <Modal open={isAttachDocumentModalOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '50%',
                    height: isMobile ? '70%' : '75%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        color={colors.grey[100]}
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ marginBottom: 2 }}
                    >
                        {`${selectedWarranty?.address.title}`}
                    </Typography>
                    <Box
                        sx={{
                            width: '100%',
                            height: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            overflowY: 'auto',
                            boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                            margin: '0 auto',
                            border: `1px solid ${colors.grey[300]}`,
                            borderRadius: '8px',
                            p: 2,
                        }}
                    >
                        <List sx={{ width: '100%' }}>
                            {dropdownData.map((project) => (
                                <Accordion
                                    key={project.id}
                                    expanded={expandedProjects.includes(project.id)}
                                    onChange={() => handleProjectAccordionChange(project.id)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel-${project.id}-content`}
                                        id={`panel-${project.id}-header`}
                                        sx={{
                                            backgroundColor: colors.primary[400],
                                        }}
                                    >
                                        <Typography color={colors.grey[100]}>
                                            {project.title}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            backgroundColor: colors.primary[400],
                                        }}
                                    >
                                        <List sx={{ width: '100%' }}>
                                            {project.addresses.map((address) => (
                                                <Accordion
                                                    key={address.id}
                                                    expanded={
                                                        expandedAddresses[project.id]?.[address.id] ||
                                                        false
                                                    }
                                                    onChange={() => handleAddressAccordionChange(project.id, address.id)}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`panel-${address.id}-content`}
                                                        id={`panel-${address.id}-header`}
                                                        sx={{
                                                            backgroundColor: colors.primary[400],
                                                        }}
                                                    >
                                                        <Typography color={colors.grey[100]}>
                                                            {address.title}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails
                                                        sx={{
                                                            backgroundColor: colors.primary[400],
                                                        }}
                                                    >
                                                        <List sx={{ width: '100%' }}>
                                                            {address.documents.map((document) => (
                                                                <ListItem
                                                                    key={document.id}
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <Checkbox
                                                                            checked={checkedDocuments.includes(document.id)}
                                                                            onChange={() => handleCheckboxChange(document.id)}
                                                                            sx={{
                                                                                color: colors.grey[100],
                                                                            }}
                                                                        />
                                                                        <Typography
                                                                            variant="body1"
                                                                            color={colors.grey[100]}
                                                                            ml={1}
                                                                        >
                                                                            {document.title}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setSelectedDocument(document);
                                                                            setViewAnyDocumentModalOpen(true);
                                                                        }}
                                                                        variant="outlined"
                                                                        sx={{
                                                                            color: colors.grey[100],
                                                                            borderColor: colors.grey[100],
                                                                            ml: 1,
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >
                                                                        View
                                                                    </Button>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </List>
                    </Box>
                </Box>
                {isMobile ? (
                    <FormControl
                        sx={{
                            width: '100%',
                            borderRadius: '8px',
                            p: 2,
                            alignSelf: 'center',
                        }}
                    >
                        <Select
                            value={selectedAction}
                            onChange={(e) => handleSelectAction(e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Select action' }}
                            sx={{
                                width: '100%', // Match the width of the shadow box
                                color: colors.grey[100],
                                backgroundColor: colors.primary[400],
                                borderRadius: '8px',
                                boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: colors.grey[300],
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: colors.grey[100],
                                },
                                '& .MuiSvgIcon-root': {
                                    color: colors.grey[100],
                                },
                            }}
                        >
                            <MenuItem value="" disabled>
                                Select an action
                            </MenuItem>
                            <MenuItem value="Save Changes">Save Changes</MenuItem>
                            <MenuItem value="Reset">Reset</MenuItem>
                            <MenuItem value="Close">Close</MenuItem>
                        </Select>
                    </FormControl>
                ) : (
                    <Box
                        sx={{
                            height: '15%',
                            width: '100%',
                            padding: '0 5%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: theme.spacing(2),
                            mb: 2, // Add space between buttons and the bottom of the container
                        }}
                    >
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            sx={{
                                color: 'white',
                                backgroundColor: colors.greenAccent[500],
                                '&:hover': {
                                    backgroundColor: colors.greenAccent[700],
                                },
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Save Changes
                        </Button>
                        <Button
                            onClick={handleReset}
                            variant="contained"
                            sx={{
                                color: 'white',
                                backgroundColor: colors.redAccent[500],
                                '&:hover': {
                                    backgroundColor: colors.redAccent[700],
                                },
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                color: 'white',
                                backgroundColor: colors.redAccent[500],
                                '&:hover': {
                                    backgroundColor: colors.redAccent[700],
                                },
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                )}
                <ViewAnyDocumentModal
                    isViewAnyDocumentModalOpen={isViewAnyDocumentModalOpen}
                    setViewAnyDocumentModalOpen={setViewAnyDocumentModalOpen}
                    selectedDocument={selectedDocument}
                    setSelectedDocument={setSelectedDocument}
                />
            </Box>
        </Modal>
    );
};

export default AttachDocumentModal;
