// src/components/ResetPassword/index.jsx
import React, { useContext, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    useTheme,
    Snackbar,
    Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import { tokens } from '../../../theme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PasswordResetContext } from '../../../context/PasswordResetContext';

const PASSWORD_RESET_REQUEST_URL = '/auth/password-reset/request';
const COOLDOWN_SECONDS = 180; // Cooldown period in seconds

const ResetPassword = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const {
        setUsername,
        setResetMessage,
        setResetMessageType,
    } = useContext(PasswordResetContext);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // On component mount, check for existing cooldown
    useEffect(() => {
        const storedCooldownStart = localStorage.getItem('cooldownStartTime');
        if (storedCooldownStart) {
            const cooldownStartTime = parseInt(storedCooldownStart, 10);
            const timeElapsed = Math.floor(Date.now() / 1000) - cooldownStartTime;
            const timeLeft = COOLDOWN_SECONDS - timeElapsed;
            if (timeLeft > 0) {
                setCooldownTimeLeft(timeLeft);
            } else {
                // Cooldown has expired, remove from localStorage
                localStorage.removeItem('cooldownStartTime');
            }
        }
    }, []);

    // Effect to handle the cooldown timer countdown
    useEffect(() => {
        let timer;
        if (cooldownTimeLeft > 0) {
            timer = setInterval(() => {
                setCooldownTimeLeft((prev) => {
                    if (prev <= 1) {
                        // Cooldown has ended
                        localStorage.removeItem('cooldownStartTime');
                        clearInterval(timer);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [cooldownTimeLeft]);

    const formik = useFormik({
        initialValues: {
            username: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required('Username is required'),
        }),
        onSubmit: async (values) => {
            if (cooldownTimeLeft > 0) {
                // Should not happen as button is disabled, but just in case
                return;
            }

            setIsSubmitting(true);
            try {
                await axios.post(
                    PASSWORD_RESET_REQUEST_URL,
                    JSON.stringify({ username: values.username }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                );
                // Set the username in context
                setUsername(values.username);

                // Set the success message in context
                setResetMessage('If the username exists, a reset code has been sent to the associated email.');
                setResetMessageType('success');

                // Start the cooldown timer
                const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
                localStorage.setItem('cooldownStartTime', currentTime.toString());
                setCooldownTimeLeft(COOLDOWN_SECONDS);

                // Navigate to the verify code page
                navigate('/password-reset/verify');
            } catch (error) {
                if (error.response && error.response.status === 429) {
                    // Cooldown period is active
                    const retryAfter = error.response.headers['retry-after'];
                    const timeLeft = parseInt(retryAfter, 10) || COOLDOWN_SECONDS;
                    setCooldownTimeLeft(timeLeft);
                    setSnackbarMessage('Please wait before requesting a new code.');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);

                    // Start the cooldown timer
                    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
                    localStorage.setItem('cooldownStartTime', currentTime.toString());
                } else {
                    // Set the error message in context
                    setSnackbarMessage('An error occurred while processing your request.');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            } finally {
                setIsSubmitting(false);
            }
        },
    });

    const handleCancel = () => {
        navigate('/login');
    };

    const handleSnackbarClose = (event, reason) => {
        setSnackbarOpen(false);
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: theme.spacing(8),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {/* Snackbar for messages */}
                {snackbarOpen && (
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarSeverity}
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    snackbarSeverity === 'success'
                                        ? colors.greenAccent[600]
                                        : colors.redAccent[600],
                                color: 'white',
                            }}
                        >
                            <Typography variant="h6">{snackbarMessage}</Typography>
                        </Alert>
                    </Snackbar>
                )}
                <Box
                    sx={{
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        padding: theme.spacing(4),
                        width: '100%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4">
                        Enter Username
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        sx={{ mt: theme.spacing(2), width: '100%' }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                        />
                        {/* Countdown Timer */}
                        {cooldownTimeLeft > 0 && (
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ mt: theme.spacing(1) }}
                            >
                                You can request a new code in {cooldownTimeLeft} seconds
                            </Typography>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: theme.spacing(4),
                            }}
                        >
                                <Button
                                    variant="contained"
                                    onClick={handleCancel}
                                    sx={{
                                        bgcolor: colors.grey[700],
                                        '&:hover': {
                                            bgcolor: colors.grey[500],
                                        },
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        bgcolor: colors.blueAccent[700],
                                        '&:hover': {
                                            bgcolor: colors.blueAccent[400],
                                        },
                                    }}
                                    disabled={cooldownTimeLeft > 0 || isSubmitting}
                                >
                                    Submit
                                </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default ResetPassword;
